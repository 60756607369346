import {
  StatusBar,
  View,
  TouchableHighlight,
  Image,
  Text,
  StyleSheet,
  Platform,
  FlatList,
} from 'react-native';
import {Header, withBadge, ButtonGroup} from 'react-native-elements';
import {
  styles,
  headerHeight,
  statusBarHeight,
  MyContext,
  isDeskTopBrowser,
} from '../../styles/styles';
import * as React from 'react';
import {USER_STATE} from '../../utilities/Constants';
import {cloudFrontURL} from '../../utilities/profile';
import {MyIcon} from './icons';
import {MySearchBar, MySlider, MyButtonGroup, MyListMenu} from './elements';
import {isMobile} from 'react-device-detect';
import {MyStorage as AsyncStorage} from '../../utilities/MyStorage';
import {CART_SUFFIX} from '../../utilities/Constants';

export const MyStatusBar = (props) => {
  const {business, user, theme} = React.useContext(MyContext);
  const BadgedIcon = withBadge('0', {
    status: 'error',
    left: 0,
    top: 0,
    textStyle: styles.badgeText,
    value: AsyncStorage.getItem(business?.id + CART_SUFFIX)?.items
      ? AsyncStorage.getItem(business?.id + CART_SUFFIX)?.items?.length
      : 0,
    containerStyle: styles.badgeContainer,
    badgeStyle: styles.badge,
  })(MyIcon);
  const [showArrow, setShowArrow] = React.useState(true);
  const [selectedIndex, setSelectedIndex] = React.useState();
  const localStyles = StyleSheet.create({
    headerComponentStyle: {
      alignItems: 'center',
      paddingBottom: 0,
      paddingTop: 0,
      height: headerHeight + statusBarHeight,
      zIndex: 1,
      width: '100%',
    },
    headerComponentStyleHome: {
      alignItems: 'center',
      paddingBottom: 0,
      paddingTop: 0,
      paddingLeft: 0,
      paddingRight: 0,
      height: headerHeight,
    },
    statusBar: {
      height: headerHeight,
    },
    headerTitle: {
      color: theme?.colors?.primary,
      marginLeft: 5,
      marginRight: 5,
    },
    logo: {width: 90, height: 50, alignSelf: 'center'},
  });
  /*
  const goBack = () => {
    let finalGoto = null;
    props.setHistory((curHistory) => {
      let prevState = [...curHistory];
      while (prevState.length > 1) {
        let gotoState = prevState.splice(prevState.length - 1, 1)[0];
        if (gotoState === props.userState) {
          continue;
        } else {
          finalGoto = gotoState;
          break;
        }
      }
      if (prevState.length === 1 && !finalGoto) {
        if (props.userState !== prevState[0]) {
          finalGoto = prevState[0];
        }
      }
      if (finalGoto) {
        props.transitionState(finalGoto);
      }
      return prevState;
    });
    return !finalGoto;
  };
  */
  return (
    <>
      {props.page === 'mainHome' ? (
        <>
          {Platform.OS === 'web' ? null : (
            <View style={[localStyles.statusBar]}>
              <StatusBar
                translucent
                backgroundColor="transparent"
                barStyle="dark-content"
              />
            </View>
          )}
          {props.businesses && props.businesses.length > 1 && (
            <View
              style={[
                localStyles.headerComponentStyleHome,
                {flexDirection: 'row', marginLeft: 10},
              ]}>
              <>
                <Image
                  style={localStyles.logo}
                  resizeMode="contain"
                  source={
                    props.logoURL ? {uri: cloudFrontURL + props.logoURL} : null
                  }
                />
                <MySearchBar
                  placeholder="Search Shops..."
                  onChangeText={props.onChangeText}
                  value={props.tag}
                  showLoading={!props.pageState.searched}
                  onClear={props.clearSearch}
                  fuse={props.fuse}
                  pageState={props.pageState}
                  width="85%"
                />
              </>
            </View>
          )}
        </>
      ) : (
        <View style={{flexDirection: 'column'}}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            {!business?.profile?.logoOnTop && (
              <Image
                style={{
                  width: isDeskTopBrowser ? 180 : 63,
                  height: isDeskTopBrowser ? 100 : 35,
                  alignSelf: 'center',
                }}
                resizeMode="contain"
                source={
                  props.logoURL ? {uri: cloudFrontURL + props.logoURL} : null
                }
              />
            )}
            <Header
              containerStyle={localStyles.headerComponentStyle}
              backgroundColor={'transparent'}
              leftContainerStyle={{
                flexDirection: 'row',
                alignItems: 'center',
                padding: 0,
                margin: 0,
              }} /*
              leftComponent={
                <>
                  {showArrow ? (
                    <MyIcon
                      name="keyboard-backspace"
                      type="material-community"
                      onPress={() => {
                        if (!props.setHistory) {
                          props.navigation.goBack();
                        } else {
                          let result = goBack();
                          if (result) {
                            props.navigation.goBack();
                          }
                        }
                      }}
                    />
                  ) : null}
                </>
              }
              */
              centerContainerStyle={{
                flexDirection: 'row',
                alignItems: 'stretch',
                flex: isMobile ? 10 : 10,
              }}
              centerComponent={
                <>
                  {props.showSearchBar ? (
                    <MySearchBar
                      onPress={() => {
                        props.setIsFirstRequest(true);
                        props.setSearchText(props.tag);
                      }}
                      placeholder={'Search ...'}
                      onChangeText={props.updateTag}
                      value={props.tag}
                      onSubmitEditing={() => {
                        props.setIsFirstRequest(true);
                        props.transitionState(USER_STATE.SEARCH);
                        props.setSearchText(props.tag);
                      }}
                      showLoading={!props.pageState.searched}
                      onClear={() => {
                        props.setSearchResults(props.initialImages);
                        props.transitionState(USER_STATE.LANDING);
                        props.setPageState((prevState) => {
                          return {
                            ...prevState,
                            errorMessage: '',
                            searchResultMessage: '',
                          };
                        });
                      }}
                      fuse={props.fuse}
                      pageState={props.pageState}
                      setPageState={props.setPageState}
                    />
                  ) : null}
                  {(props.userState === USER_STATE.LANDING ||
                    props.userState === USER_STATE.SEARCH) &&
                  props.business?.profile?.priceRange &&
                  props.showSearchBar ? (
                    <View>
                      <MySlider
                        step={props.business?.profile?.priceRangeStep}
                        minValue={props.business?.profile?.priceRangeFloor}
                        maxValue={props.business?.profile?.priceRangeCeiling}
                        business={props.business}
                        onChange={(newval) =>
                          props.setRange((prevRange) => {
                            var setVal =
                              Math.floor(newval / 100) *
                              100 *
                              props.business?.profile?.priceRangeDenom;
                            console.log(
                              'numericip set range value, newVal:',
                              newval,
                              'setVal',
                              setVal,
                            );
                            props.setIsFirstRequest(true);
                            return {...prevRange, val: setVal};
                          })
                        }
                        editable={false}
                        value={props.range.val}
                        containerStyle={{
                          marginLeft: 5,
                          marginRight: 5,
                          maxWidth: 0.15 * theme?.screenWidth,
                        }}
                      />
                    </View>
                  ) : null}
                </>
              } /*
              rightContainerStyle={{
                alignItems: 'center',
                marginTop: 8,
                maxWidth: 50,
              }}
              rightComponent={
                props.rightComponent === 'cart' ? (
                  <View style={{flexDirection: 'row'}}>
                    <BadgedIcon
                      name="cart"
                      type="material-community"
                      iconStyle={{alignSelf: 'center'}}
                      color={theme?.colors?.primary}
                      size={35}
                      z-index={350}
                      Component={TouchableHighlight}
                      activeOpacity={0.6}
                      underlayColor={theme?.colors?.iconTouchHighlight}
                      onPress={() => {
                        if (props.route === 'Search') {
                          props.transitionState(USER_STATE.CART);
                        } else {
                          props.navigation.navigate('Search', {
                            userState: USER_STATE.CART,
                          });
                        }
                      }}
                    />
                  </View>
                ) : null
              }*/
            />
          </View>
          {props.page === 'search' && !isMobile ? (
            <MyButtonGroup
              onPress={(itemIndex) => {
                let st = props.categories[itemIndex].startsWith('Kid')
                  ? 'Kid'
                  : props.categories[itemIndex];
                props.setSearchText(st);
                props.transitionState(USER_STATE.SEARCH);
                props.updateTag('');
                setSelectedIndex(itemIndex);
              }}
              buttons={props.categories}
              width={'99%'}
              selectedIndex={
                props.searchText &&
                (props?.categories?.includes(props.searchText) ||
                  props.searchText === 'Kid')
                  ? selectedIndex
                  : null
              }
            />
          ) : (
            <MyListMenu
              data={props.categories}
              searchText={props.searchText}
              onPress={(index) => {
                let st = props.categories[index].startsWith('Kid')
                  ? 'Kid'
                  : props.categories[index];
                props.setSearchText(st);
                props.transitionState(USER_STATE.SEARCH);
                props.updateTag('');
              }}
            />
          )}
        </View>
      )}
    </>
  );
};
