import {View} from 'react-native';

import React from 'react';
import {useContext} from 'react';
import {
  TouchableOpacity,
  TextInput,
  FlatList,
  Image,
  StyleSheet,
} from 'react-native';
import {Text} from 'react-native-elements';
import {getFullPageURL, USER_STATE} from '../../utilities/Constants';

import {MyButton, ActionButton, MySeparator} from '../ui/elements';
import {isDeskTopBrowser, MyContext} from '../../styles/styles';
import {MyStorage as AsyncStorage} from '../../utilities/MyStorage';

export const ShoppingCart = ({
  setDetailItem,
  searchResults,
  selections,
  transitionState,
  removeSelectionFromPickup,
  setUserState,
  updateSelection,
  renderSeparator,
}) => {
  const context = useContext(MyContext);
  const theme = context?.theme;
  const business = context?.business;
  const countryDetail=context.countryDetail;
  const dynSearchScreenText = (index, fonticrement) => {
    return {
      fontSize: 14 + fonticrement,
      flexWrap: 'wrap',
      paddingTop: 10,
      color:
        index % 2 === 0
          ? theme?.colors?.colorItemEven
          : theme?.colors?.colorItemOdd,
    };
  };
  const localStyles = StyleSheet.create({
    cartItem: {
      flexDirection: 'row',
      alignContent: 'center',
      backgroundColor: 'transparent',
      padding: 0,
      margin: 5,
    },
    cartImage: {
      resizeMode: 'contain',
      width: theme?.screenWidth / 8,
      height: ((3 / 4) * theme?.screenWidth) / 8,
    },
    cartItemTouch: {
      position: 'absolute',
      height: ((3 / 4) * theme?.screenWidth) / 8,
      width: theme?.screenWidth / 8,
    },
    cartItemTitleText: {
      padding: 0,
      textAlignVertical: 'center',
      marginLeft: 2,
      fontWeight: 200,
      fontSize: isDeskTopBrowser ? 27 : 18,
      color: theme?.colors?.info,
      height: 50,
    },
    cartTitleTextWrapper: {
      flexDirection: 'column',
      marginLeft: '2%',
      flexWrap: 'wrap',
      width: '93%',
    },
    qtyInput: {
      padding: 5,
      width: 35,
      height: 22,
      textAlign: 'center',
      backgroundColor: theme?.colors?.whiteBackgroundColor,
      color: theme?.colors?.secondary,
      marginLeft: 10,
    },
  });
  const renderSelectedItem = ({item, index}) => {
    return (
      <View style={{flexDirection: 'column', margin: 10}}>
        <View style={[localStyles.cartItem]}>
          <View
            style={{
              width: isDeskTopBrowser ? '90%' : '80%',
              flexDirection: 'row',
            }}>
            <Image
              resizeMode="contain"
              source={{
                uri: getFullPageURL(
                  business?.id,
                  item.details[item.selectedIndex].imageKey,
                ),
              }}
              style={localStyles.cartImage}
            />
            <TouchableOpacity
              size={50}
              style={localStyles.cartItemTouch}
              onPress={() => {
                item.ShoppingCartArrayIndex = index;
                setDetailItem(item);
                if (item) {
                  AsyncStorage.setItem(
                    business.id + ':detailItem',
                    JSON.stringify(item),
                  );
                }
                transitionState(USER_STATE.DETAIL);
              }}
            />
            <View style={localStyles.cartTitleTextWrapper}>
              <View
                style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                <Text
                  adjustsFontSizeToFit
                  style={[
                    dynSearchScreenText(index, isDeskTopBrowser ? 12 : 2),
                  ]}>
                  {item.details[item.selectedIndex].title}
                </Text>
                {business && business?.profile?.addPrice ? (
                  <>
                    <Text
                      adjustsFontSizeToFit
                      style={[
                        dynSearchScreenText(index, isDeskTopBrowser ? 12 : 2),
                      ]}>
                      {countryDetail.currency === 'USD' ? <Text>&#36;</Text> : (
                        <Text>&#8377; </Text>
                      )}
                      {((countryDetail.isInternational? item.details[item.selectedIndex].internationalPrice:item.details[item.selectedIndex].price) *
                        item.selectedQuantity) /
                        (item.details[item.selectedIndex].unit
                          ? item.details[item.selectedIndex].unit
                          : 1)}
                    </Text>
                  </>
                ) : null}
              </View>
              {item.details[item.selectedIndex].displayDimensions ? (
                <Text style={dynSearchScreenText(index, 0)}>
                  {item.details[item.selectedIndex].displayDimensions}
                </Text>
              ) : null}
              {business?.profile?.addPrice ? (
                <Text style={dynSearchScreenText(index, 0)}>
                  {countryDetail.currency === 'USD' ? <Text>&#36;</Text> : (
                        <Text>&#8377; </Text>
                      )}
                  {countryDetail.isInternational? item.details[item.selectedIndex].internationalPrice:item.details[item.selectedIndex].price}
                  {business.profile.showUnitMeasure
                    ? '/' +
                      (!item?.unitMeasure
                        ? 'ea'
                        : item.details[item.selectedIndex].unit ||
                          item.details[item.selectedIndex].unit !== 1
                        ? item.details[item.selectedIndex].unit +
                          ' ' +
                          item.details[item.selectedIndex].unitMeasure
                        : 'ea')
                    : ''}
                </Text>
              ) : null}
              <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <Text style={[dynSearchScreenText(index, 0), {paddingTop: 0}]}>
                  Quantity: |
                </Text>
                <TextInput
                  editable
                  maxLength={3}
                  style={[
                    dynSearchScreenText(index, 0),
                    {
                      width: 35,
                      height: 34,
                      textAlign: 'center',
                      backgroundColor: theme?.colors?.whiteBackgroundColor,
                      marginLeft: 10,
                      borderWidth: 1,
                      paddingTop: 0,
                    },
                  ]}
                  value={item.selectedQuantity}
                  onChangeText={(newval) => {
                    if(!isNaN(newval)){
                      item.selectedQuantity = newval;
                      updateSelection(index, item);
                    }
                  }}
                />
                <MyButton
                  title="| Delete"
                  size={14}
                  onPress={() => {
                    console.log('removing item with index:' + index);
                    removeSelectionFromPickup(index);
                  }}
                  analyticsAction="RemoveItemFromCart"
                />
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  };

  const handleLoadMore = () => {};
  return (
    <>
      <Text
        style={[
          dynSearchScreenText(0, isDeskTopBrowser ? 18 : 6),
          {margin: 20},
        ]}>
        {selections?.items?.length > 0 ? 'Shopping Cart' : 'Your Cart is Empty'}
      </Text>
      {selections?.items?.length > 0 && (
        <Text
          style={[
            dynSearchScreenText(0),
            {
              alignSelf: 'flex-end',
              marginBottom: 5,
              flexWrap: 'nowrap',
              marginRight: '4%',
            },
          ]}>
          Value
        </Text>
      )}
      <MySeparator />
      <FlatList
        style={[{borderTopWidth: 0, borderBottomWidth: 0}]}
        contentContainerStyle={[{alignContent: 'flex-start'}]}
        data={selections.items}
        renderItem={renderSelectedItem}
        bounces={false}
        keyExtractor={(item) => item.details[item.selectedIndex].id}
        numColumns={1}
        horizontal={false}
        onEndReached={handleLoadMore}
        onEndReachedThreshold={0.5}
        ItemSeparatorComponent={renderSeparator}
      />
      <MySeparator />
      <Text
        style={[
          dynSearchScreenText(0, 2),
          {alignSelf: 'flex-end', marginRight: '2%', marginBottom: 5},
        ]}>
        SubTotal ({selections.items.length} items):
        {selections?.items.reduce(function (res, item) {
          return (
            res +
            ((countryDetail.isInternational? item.details[item.selectedIndex]?.internationalPrice: item.details[item.selectedIndex]?.price) * item?.selectedQuantity) /
              (item.details[item.selectedIndex].unit
                ? item.details[item.selectedIndex].unit
                : 1)
          );
        }, 0)}
      </Text>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          alignSelf: 'center',
        }}>
        {selections?.items?.length > 0 ? (
          <ActionButton
            onPress={() => {
              transitionState(USER_STATE.CHECKOUT);
            }}
            title="CheckOut"
          />
        ) : null}
        <MyButton
          title="Continue Shopping"
          onPress={() => {
            transitionState(USER_STATE.LANDING);
          }}
        />
      </View>
    </>
  );
};
