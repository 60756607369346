import React, {useContext, useEffect, useState} from 'react';
import {Text, View, StyleSheet, Modal} from 'react-native';
import {useScrollToTop} from '@react-navigation/native';

import {MyContext, numReviewColumns, styles} from '../../styles/styles';
import {createReview} from '../../graphql/mutations';
import {listReviews} from '../../graphql/queries';
import {API} from 'aws-amplify';
import uuid from 'react-native-uuid';
import {
  InputTextArea,
  ActionButton,
  InputText,
  MySeparator,
} from '../ui/elements';
import {FlatList} from 'react-native-gesture-handler';
import {isMobile} from 'react-device-detect';
import {USER_STATE} from '../../utilities/Constants';
import {Rating} from 'react-native-elements';
import {getReviewCount, getReviewCountTotal} from '../../queries/Reviews';
import {LinearProgress} from 'react-native-elements';
import {gql} from 'apollo-boost';

export const Reviews = ({navigation}) => {
  const [feedback, setFeedback] = useState({});
  const [reviews, setReviews] = useState({});
  const [reviewSummary, setReviewSummary] = useState({});
  const [error, setError] = useState();
  const {theme, user, business} = useContext(MyContext);
  const [modalVisible, setModalVisible] = useState(true);
  const ratingsArray = [1, 2, 3, 4, 5];
  const ref = React.useRef(null);
  useScrollToTop(ref);

  function onChangeText(key, value) {
    setError('');
    setFeedback((prevFeedback) => {
      return {
        ...prevFeedback,
        [key]: value,
      };
    });
  }
  function ratingCompleted(rating) {
    onChangeText('rating', rating);
  }
  function submitReview() {
    let message;
    console.log('submittinng review', feedback);
    const {title, review, rating} = feedback;
    if (!title) {
      message = 'Please enter title';
    } else if (!review) {
      message = 'Please enter review';
    } else if (!rating) {
      message = 'Please enter rating';
    } else {
      API.graphql({
        query: createReview,
        variables: {
          input: {
            id: uuid.v4(),
            businessId: business?.id,
            review: review,
            rating: rating,
            title: title,
            username: user?.firstName + ' ' + user?.lastName,
          },
        },
        authMode: 'API_KEY',
      })
        .then((result) => {
          console.log(result);
          setError('Thanks for your Review');
        })
        .catch((e) => {
          console.log(e);
          setError(message);
        });
    }
    if (message) {
      setError(message);
    }
  }

  useEffect(() => {
    fetchReviews();
    function fetchReviews() {
      let message;
      API.graphql({
        query: listReviews,
        authMode: 'API_KEY',
      })
        .then((result) => {
          console.log(result);
          setReviews(result.data.listReviews.items);
        })
        .catch((e) => {
          console.log(e);
          setError(message);
        });
    }
  }, []);
  useEffect(() => {
    ratingsArray.forEach((i) => {
      API.graphql({
        query: gql`
        query MyQuery($businessId: ID = "businessId", $rating: Int) {
          listReviews(businessId: $businessId, filter: {rating: {eq: $rating}}) {
            items {
              id
              businessId
              rating
            }
          }
        }        
      `,
        variables: {
          businessId: business?.id,
          rating: i,
        },
        authMode: 'API_KEY',
      })
        .then((result) => {
          setReviewSummary((cur) => {
            return {
              ...cur,
              [i]: result.data.listReviews.items.length,
            };
          });
        })
        .catch((e) => {
          console.log(e);
        });
    });
    API.graphql({
      query: gql`
      query MyQuery($businessId: ID = "businessId") {
        listReviews(businessId: $businessId) {
          items {
            id
            businessId
          }
        }
      }
    `,
      variables: {
        businessId: business?.id,
      },
      authMode: 'API_KEY',
    })
      .then((result) => {
        setReviewSummary((cur) => {
          return {
            ...cur,
            total: result.data.listReviews.items.length,
          };
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }, [navigation]);

  function setReviewSummary2(key, value) {
    setReviewSummary((cur) => {
      return {
        ...cur,
        [key]: result.data.searchReviews.total,
      };
    });
  }
  const localStyles = StyleSheet.create({
    title: {
      fontSize: theme?.fonts?.ml,
      color: theme?.colors?.info,
      padding: 12,
      backgroundColor: 'transparent',
      fontWeight: 600,
      flexWrap: 'wrap',
    },
    heading: {
      fontSize: theme?.fonts?.l,
      color: theme?.colors?.info,
      padding: 12,
      backgroundColor: 'transparent',
      fontWeight: 600,
      flexWrap: 'wrap',
    },
    review: {
      fontSize: theme?.fonts?.s,
      color: theme?.colors?.info,
      padding: 12,
      backgroundColor: 'transparent',
      flexWrap: 'wrap',
    },
  });

  return (
    <View
      style={{
        flex: 1,
        alignSelf: 'center',
        width: isMobile ? theme?.screenWidth : theme?.screenWidth / 3,
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 15,
        shadowColor: '#000',
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
      }}>
      {error && error.length > 0 && (
        <Text style={styles.errorMsg}>{error}</Text>
      )}

      {!user ? (
        <ActionButton
          title="Login to Submit Review"
          onPress={() => {
            navigation.navigate('Auth', {
              dest: 'Search',
              returnState: USER_STATE.REVIEW,
            });
          }}
        />
      ) : (
        <View style={{alignItems: 'center'}}>
          <InputText
            placeholder="Please enter your Review Title"
            name="title"
            onChangeText={onChangeText}
            value={feedback?.title}
            autoCompleteType="off"
            textContentType="none"
            label="*Review Title"
          />
          <InputTextArea
            placeholder="Please enter your Review"
            name="review"
            onChangeText={onChangeText}
            value={feedback?.review}
            autoCompleteType="off"
            textContentType="none"
            label="*Review"
          />
          <Rating
            showRating
            onFinishRating={ratingCompleted}
            style={{paddingVertical: 5}}
            imageSize={14}
            ratingColor={theme?.colors?.primary}
            ratingBackgroundColor={theme?.colors?.primary}
          />
          <ActionButton
            title="Submit"
            onPress={submitReview}
            message={error && error.length ? error : 'Submitted Review'}
          />
        </View>
      )}
      <View style={{alignItems: 'flex-start'}}>
        <MySeparator />
        <Text style={localStyles.heading}>Reviews Summary</Text>
        <FlatList
          data={[1, 2, 3, 4, 5]}
          style={[{borderTopWidth: 0, borderBottomWidth: 0}]}
          renderItem={(item) => {
            return (
              <View
                style={{
                  flexDirection: 'row',
                }}>
                <Rating
                  readonly
                  startingValue={item.item}
                  style={localStyles.review}
                  imageSize={14}
                  selectedColor={theme?.colors?.primary}
                  ratingBackgroundColor={theme?.colors?.primary}
                />
                <View
                  style={{
                    width: 100,
                    height: 10,
                    alignItems: 'center',
                  }}>
                  <View
                    style={{
                      width:
                        '' +
                        (reviewSummary[item.item] / reviewSummary.total) * 100 +
                        '%',
                      height: 10,
                      backgroundColor: theme.colors.primary,
                      marginTop: 15,
                    }}
                  />
                </View>
                <Text style={localStyles.review}>
                  ({reviewSummary[item.item]}/{reviewSummary.total})
                </Text>
              </View>
            );
          }}
          bounces={false}
          keyExtractor={(item) => item}
          numColumns={1}
        />
        <MySeparator />
        <Text style={localStyles.heading}>Reviews</Text>
        <FlatList
          data={reviews}
          ItemSeparatorComponent={MySeparator}
          style={[{borderTopWidth: 0, borderBottomWidth: 0}]}
          renderItem={(item) => {
            return (
              <View
                style={{
                  flexDirection: 'column',
                }}>
                <Text style={localStyles.title}>{item.item.username}</Text>
                <Text style={localStyles.review}>
                  {item.item.createdAt.split('T')[0]}
                </Text>
                <Rating
                  readonly
                  startingValue={item.item.rating}
                  style={localStyles.review}
                  imageSize={14}
                  selectedColor={theme?.colors?.primary}
                  ratingBackgroundColor={theme?.colors?.primary}
                />
                <Text style={localStyles.title}>{item.item.title}</Text>
                <Text style={localStyles.review}>{item.item.review}</Text>
              </View>
            );
          }}
          bounces={false}
          keyExtractor={(item) => item.id}
          numColumns={1}
        />
      </View>
    </View>
  );
};
