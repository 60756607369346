import { default as React, useState, useContext } from 'react';
import { Text, TouchableOpacity, View, Linking, StyleSheet } from 'react-native';
import { styles, dateOptions, MyContext } from '../../styles/styles';
import {
  MyButton,
  MyButtonGroup,
  MyListMenu,
  ActionButton,
  InputText,
} from '../ui/elements';
import { isMobile } from 'react-device-detect';
import { OrderItemPrice } from './OrderItemPrice';
import { ActionMap, StatusMap, USER_STATE, ACTIVE_CART_ORDER, CART_SUFFIX } from '../../utilities/Constants';
import { jsPDF } from 'jspdf';
import {MyStorage as AsyncStorage} from '../../utilities/MyStorage';
import { Storage } from 'aws-amplify';


export const OrderItem = ({
  item,
  index,
  onSelect,
  url,
  getOrderActions,
  SetOrderUpdateSelection,
  SetOrderUpdateSelectionAdminNote,
  transitionState,
  navigation,
}) => {
  const [showDetail, setShowDetail] = useState(true);
  const { user, business, theme, countryDetail } = useContext(MyContext);
  const [returnOrder, setReturnOrder] = useState({});

  const toggleShow = () => {
    setShowDetail((cur) => {
      return !cur;
    });
  };

  const dynStyle = (withWrap) => {
    return withWrap
      ? {
        flexWrap: 'wrap',
        fontSize: 12,
        margin: 2,
        color: theme?.colors?.colorItemEven,
      }
      : {
        fontSize: 12,
        margin: 2,
        color: theme?.colors?.colorItemEven,
      };
  };

  const localStyles = StyleSheet.create({
    item: {
      flexWrap: 'wrap',
      fontSize: 12,
      margin: 2,
      color: theme?.colors?.colorItemEven,
      justifyContent: 'flex-start',
      maxWidth: 175,
    },
    itemsContainer: {
      flex: 2,
      flexDirection: 'column',
      justifyContent: 'flex-start',
      width: '100%',
      flexWrap: 'wrap',
    },
  });
  const updateReturnOrder = (key, value) => {
    console.log(returnOrder);
    setReturnOrder((prevOrder) => {
      return {
        ...prevOrder,
        [key]: value,
      };
    });
  };
  let da = JSON.parse(item.deliveryAddress);
  const printInvoice = async(item) => {
    await Storage.get(user?.businessId + '/invoices/'+ item.id, {
      level: 'private', 
      download: true
    }).then((result) => {
      console.log('Got invoice from S3');
    });;
  }
  const printInvoiceOld = () => {
      let text =
      '<!DOCTYPE html><body>' +
      '<table width="100%" style="text-align: center"s>' +
      '<tr><th/><th>' +
      business.name +
      '</th></tr>' +
      '<tr><th/><th>Invoice' +
      '</th></tr>' +
      '<tr><th>Shipping Address:</th><th>Sold By:</th><th>Invoice Details:</th></tr><tr><td>' +
      da.addressLine +
      '<br/>' +
      da.city +
      '<br/>' +
      da.state +
      '-' +
      da.zipCode +
      '<br/>' +
      da.country +
      '</td><td>' +
      business.name +
      '<br/>' +
      business.profile.contactInfo.address +
      '<br/> Phone: ' +
      business.profile.contactInfo.mobileNumber +
      '<br/>Email: ' +
      business.profile.contactInfo.email +
      '</td><td>' +
      'Invoice Number:<br/>' +
      item.id.substring(0, 7) +
      '<br/>Invoice Date:<br/>' +
      item.orderSortDate +
      '<br/>Order Number:<br/>' +
      item.id.substring(0, 7) +
      '<br/>Order Date:' +
      item.orderSortDate +
      '</td></tr></table>' +
      '<table width="100%" style="text-align: center">' +
      '<tr> <th>S. No.</th> <th>Product Name</th> <th>Qty</th> <th>Unit Price</th> <th>Total</th></tr>';
    let j = 0;
    JSON.parse(item.items[0]).forEach((e) => {
      text =
        text +
        ' <tr> <td>' +
        ++j +
        '</td><td>' +
        e.title +
        '</td><td>' +
        e.quantity +
        '</td><td>' +
        (e.price) +
        '</td><td>' +
        ((e.price) * Number(e.quantity)) +
        '</td></tr>';
    });
    text =
      text +
      '<tr/>' +
      '<tr><td/><td>' +
      'Shipping and Handling: </td>' +
      '<td/><td/><td>' +
      JSON.parse(item.priceDetail).shippingHandling +
      '</td></tr>';
    JSON.parse(item.priceDetail).discount > 0 ? (
      text =
      text +
      '<tr/>' +
      '<tr><td/><td>' +
      'Discount: </td>' +
      '<td/><td/><td>' +
      JSON.parse(item.priceDetail).discount +
      '</td></tr>'
    ) : '';
    text =
      text +
      '<tr><td/><td>' +
      'NET TOTAL (In Value): </td>' +
      '<td/><td/><td>' +
      JSON.parse(item.priceDetail).currency +
      '  ' +
       JSON.parse(item.priceDetail).totalPrice +
      '</td></tr>';
    text = text + '</table>';
    text = text + '</body></html>';
    var doc = new jsPDF({
      orientation: 'p',
      unit: 'pt',
      format: 'a4',
    });
    doc.setFont('times');
    doc.setFontSize(5);
    doc.html(text, {
      callback: function (doc) {
        doc.save('HnH-' + item.orderSortDate);
      },
      margin: 10,
      width: 575,
      windowWidth: 595,
    });
  };

  return (
    <View
      style={
        (styles.orderItemWrapper,
        {
          flexDirection: 'column',
          alignItems: 'center',
          borderWidth: 1,
          padding: 10,
          borderColor: theme.colors.primaryLight,
          borderRadius: 10,
          marginBottom: 10,
        })
      }>
      <Text style={{alignSelf: 'flex-end'}} onPress={()=>transitionState(USER_STATE.ORDERS)}>x</Text>
      <View style={styles.orderItem}>
        <View>
          <Text style={dynStyle}>Order No.: {item?.id.substring(0, 5)}</Text>
          <Text style={dynStyle}>
            Order Date:
            {new Date(item.createdAt).toLocaleDateString(
              business.profile.locale,
              dateOptions,
            )}
          </Text>
          <OrderItemPrice
            item={item}
            returnOrder={returnOrder}
            updateReturnOrder={updateReturnOrder}
            selectReturn={
              getOrderActions(item.status).length === 1 &&
              getOrderActions(item.status)[0] === ActionMap.REQUEST_RETURN
            }
          />
          <Text style={dynStyle}>Status: {item?.status}</Text>
          {item.delivery &&
            item.shippingDetails &&
            JSON.parse(item.shippingDetails)?.response?.awb_code ? (
            <Text style={[dynStyle]}>
              Awb Code: {JSON.parse(item.shippingDetails)?.response?.awb_code}
            </Text>
          ) : null}
          <View style={{ flexDirection: 'row' }}>
            {item.delivery &&
              item.shippingDetails &&
              JSON.parse(item.shippingDetails)?.response?.awb_code ? (
              <MyButton
                title="Track Package"
                onPress={() =>
                  Linking.openURL(
                    'https://app.shiprocket.in/tracking/awb/' +
                    JSON.parse(item.shippingDetails)?.response?.awb_code,
                  )
                }
              />
            ) : null}
            {item.shippingDetails ? (
              <MyButton
                title="Print Invoice"
                onPress={() =>
                  !JSON.parse(item.shippingDetails)?.invoiceURL
                    ? Linking.openURL(
                      JSON.parse(item.shippingDetails)?.invoiceURL,
                    )
                    : false && new Date(item.orderSortDate)> new Date(2022,4,16,0,0)?printInvoice(item):printInvoiceOld()
                }
              />
            ) : null}
          </View>
          {showDetail && (
            <>
              {user?.isPrivileged ? (
                <>
                  <Text style={dynStyle}>
                    isAdminOrder: {item?.isAdminOrder ? 'Yes' : 'No'}
                  </Text>
                  <InputText
                    name="adminNote"
                    placeholder="Admin Note"
                    onChangeText={(name, v) => {
                      item.adminNote = v;
                      SetOrderUpdateSelectionAdminNote({
                        id: item?.id,
                        adminNote: v,
                        priceDetail: item.priceDetail,
                      });
                    }}
                    value={item.adminNote}
                    length={200}
                    width={200}
                    label="Admin Note"
                  />
                  <InputText
                    name="discount"
                    placeholder="Enter discount"
                    onChangeText={(name, v) => {
                      let priceDetail = JSON.parse(item.priceDetail);
                      priceDetail.discountPercent = parseInt(v);
                      priceDetail.discount = Math.round((priceDetail.itemsTotal * priceDetail.discountPercent) / 100);
                      priceDetail.totalPrice =
                        priceDetail.itemsTotal +
                        (priceDetail?.shippingHandling
                          ? priceDetail.shippingHandling
                          : 0) +
                        (priceDetail.tax ? priceDetail.tax : 0) -
                        priceDetail.discount;
                      priceDetail.totalPrice = Math.round(priceDetail.totalPrice);

                      let strprcdtl = JSON.stringify(priceDetail);
                      item.priceDetail = strprcdtl;
                      SetOrderUpdateSelectionAdminNote({
                        id: item?.id,
                        priceDetail: strprcdtl,
                        adminNote: item.adminNote
                      });
                    }}
                    value={JSON.parse(item.priceDetail).discountPercent}
                    length={200}
                    width={200}
                    label="Discount"
                  />
                  <Text style={dynStyle}>
                    Customer Name: {JSON.parse(item.deliveryContact)?.name}
                  </Text>
                  <Text style={dynStyle}>
                    Customer Mobile #:
                    {JSON.parse(item.deliveryContact)?.deliveryContactNumber}
                  </Text>
                </>
              ) : null}
              {item.note ? (
                <Text style={dynStyle(true)}>
                  Order Instructions: {item.note}
                </Text>
              ) : null}

              {item.delivery && (
                <View style={{ flexDirection: 'row' }}>
                  <Text style={([dynStyle], { flex: 1 })}>Shipping Address:</Text>
                  <View style={localStyles.itemsContainer}>
                    <Text style={localStyles.item}>
                      {JSON.parse(item.deliveryContact)?.name},
                    </Text>
                    <Text style={localStyles.item}>
                      {JSON.parse(item.deliveryAddress).addressLine},
                    </Text>
                    <Text style={localStyles.item}>
                      {JSON.parse(item.deliveryAddress).city},
                    </Text>
                    <View style={{ flexDirection: 'row' }}>
                      <Text style={localStyles.item}>
                        {JSON.parse(item.deliveryAddress).state}-
                      </Text>
                      <Text style={localStyles.item}>
                        {JSON.parse(item.deliveryAddress).zipCode},
                      </Text>
                    </View>
                    <Text style={localStyles.item}>
                      {JSON.parse(item.deliveryAddress).country}.
                    </Text>
                  </View>
                </View>
              )}

              {item.delivery &&
                user?.isPrivileged &&
                item.shippingDetails &&
                JSON.parse(item.shippingDetails)?.response?.shipment_id ? (
                <Text style={[dynStyle]}>
                  Shipment Id:
                  {JSON.parse(item.shippingDetails)?.response?.shipment_id}
                </Text>
              ) : null}
              {item.delivery &&
                user?.isPrivileged &&
                item.shippingDetails &&
                JSON.parse(item.shippingDetails)?.response
                  ?.pickup_scheduled_date ? (
                <Text style={[dynStyle]}>
                  Pickup Scheduled Date:
                  {
                    JSON.parse(item.shippingDetails)?.response
                      ?.pickup_scheduled_date
                  }
                </Text>
              ) : null}
              {item.delivery &&
                user?.isPrivileged &&
                item.shippingDetails &&
                JSON.parse(item.shippingDetails)?.response?.courier_name ? (
                <Text style={[dynStyle]}>
                  Courier Name:
                  {JSON.parse(item.shippingDetails)?.response?.courier_name}
                </Text>
              ) : null}
              {item.delivery &&
                user?.isPrivileged &&
                item.shippingDetails &&
                JSON.parse(item.shippingDetails)?.response
                  ?.pickup_token_number ? (
                <Text style={[dynStyle]}>
                  Pick up Token Number:
                  {
                    JSON.parse(item.shippingDetails)?.response
                      ?.pickup_token_number
                  }
                </Text>
              ) : null}
            </>
          )}
        </View>
      </View>
      {showDetail && (
        <>
          <View style={{ flexDirection: 'row' }}>
            {item.delivery &&
              user?.isPrivileged &&
              user?.businessId === business.id &&
              item.shippingDetails &&
              JSON.parse(item.shippingDetails)?.response?.label_url ? (
              <MyButton
                title="Print Label"
                onPress={() =>
                  Linking.openURL(
                    JSON.parse(item.shippingDetails)?.response?.label_url,
                  )
                }
              />
            ) : null}
          </View>
        </>
      )}
      {getOrderActions(item.status).length > 0 &&
        (!isMobile ? (
          <MyButtonGroup
            onPress={(itemIndex) => {
              SetOrderUpdateSelection({
                id: item?.id,
                index: itemIndex,
                order: item,
                returnOrder: returnOrder,
                status: item.status, //only used to get selected user status, not target status
              });
              transitionState(USER_STATE.ORDERS);
            }}
            //            selectedIndex={orderUpdateSelection.index}
            buttons={getOrderActions(item.status)}
            width={'100%'}
          />
        ) : (
          <MyListMenu
            data={getOrderActions(item.status)}
            onPress={(itemIndex) => {
              SetOrderUpdateSelection({
                id: item?.id,
                index: itemIndex,
                order: item,
                status: item.status,
                returnOrder: returnOrder,
              });
              transitionState(USER_STATE.ORDERS);
            }}
          />
        ))}
          <ActionButton
            onPress={() => {
              let deliveryContact = JSON.parse(item.deliveryContact);
              let deliveryAddress = JSON.parse(item.deliveryAddress);
              AsyncStorage.setItem(
                business.id + ACTIVE_CART_ORDER,
                JSON.stringify({
                  businessId: item.businessId,
                  mobileNumber: item?.mobileNumber,
                  deliveryContactNumber: deliveryContact?.deliveryContactNumber,
                  deliveryContactEmail: deliveryContact?.deliveryContactEmail,
                  firstName: deliveryContact?.firstName,
                  lastName: deliveryContact?.lastName,
                  delivery: item?.delivery,
                  address: deliveryAddress?.addressLine,
                  city: deliveryAddress?.city,
                  state: deliveryAddress?.state,
                  zipCode: deliveryAddress?.zipCode,
                  cod: item?.cod,
                  useCustomShipping: item?.useCustomShipping,
                  items: [],
                  price: 0.0,
                  weight: 0.0,
                  lengthx: 0.0,
                  breadth: 0.0,
                  height: 0.0,
                  status: '',
                  termAndConditionsAcceptence: true,
                  deliveryCharges: 0,
                  name: '',
                  displayDimensions: '',
                  ingredients: '',
                  usageInstructions: '',
                  benefits: '',
                }),
              );
              navigation.navigate('Search', {
                dest: 'Search',
                returnState: USER_STATE.CART,
                order: item
              });
            }}
            title="Order Again"
          />         
    </View>
  );
};
