import {Auth} from 'aws-amplify';
import React, {Fragment, useContext, useEffect, useState} from 'react';
import {Text, View} from 'react-native';
import {styles, maxInputFieldWidth, MyContext} from '../../styles/styles';
import {Auth as AmplifyAuth} from 'aws-amplify';
import {
  InputText,
  InputTextArea,
  ActionButton,
  MyListMenu,
  MyCountryPicker,
} from './../ui/elements';
import Orders from '../orders/Orders';
import {SELECT_YOUR_COUNTRY, USER_STATE} from '../../utilities/Constants';
import I18n from '../../I18n/I18n';
import {MyStorage as AsyncStorage} from './../../utilities/MyStorage';
import {constructUser} from '../../utilities/RetailClient';
import {CheckBox} from 'react-native-elements';

export const Profile = (props) => {
  const [userProfile, setUserProfile] = useState({
    error: '',
    address: '',
    family_name: '',
    given_name: '',
    user: null,
    emailSubscription: false,
  });
  const {business, theme} = useContext(MyContext);
  useEffect(() => {
    console.log('useEff: Profile');
    Auth.currentAuthenticatedUser()
      .then((user) => {
        let given_name = user?.signInUserSession.idToken.payload.given_name,
          family_name = user?.signInUserSession.idToken.payload.family_name,
          address = user?.signInUserSession.idToken.payload.address?.formatted,
          email = user?.signInUserSession.idToken.payload.email,
          city = user?.signInUserSession.idToken.payload['custom:city'],
          state = user?.signInUserSession.idToken.payload['custom:state'],
          country = user?.signInUserSession.idToken.payload['custom:country'],
          zipCode = user?.signInUserSession.idToken.payload['custom:zipCode'];
        console.log('given_name', given_name, 'address', address);
        setUserProfile((cur) => {
          return {
            ...cur,
            address: address,
            given_name: given_name,
            family_name: family_name,
            user: user,
            email: email,
            city: city,
            state: state,
            country: country,
            zipCode: zipCode,
            error: '',
          };
        });
      })
      .catch(() => {
        console.log('user not authenticated');
        return null;
      });
  }, [props.navigation]);

  const onChangeText = (key, value) => {
    setState(key, value);
  };
  const setState = (key, value) => {
    setUserProfile((prevState) => {
      return {
        ...prevState,
        [key]: value,
        error: '',
      };
    });
  };

  async function profile() {
    console.log(userProfile);
    const {
      user,
      given_name,
      family_name,
      address,
      email,
      city,
      state,
      country,
      zipCode,
    } = userProfile;
    let err = null;
    if (
      business?.profile?.delivery &&
      !(given_name && address && email && city && state && zipCode)
    ) {
       err=  I18n.t('REQ_MISSING');
    }else if (country===SELECT_YOUR_COUNTRY) {
      err= I18n.t('INVALID_COUNTRY');
    } else {
      business?.profile?.shipping?.allowedCountries.map(
        (element) => {
           if(element['country']===country && element['zipLength']!==zipCode?.length){
            err = I18n.t('INVALID_ZIP');
           }
        }
      );
    }
    if(err){
      setUserProfile((cur) => {
        return {
          ...cur,
          error: err,
        };
      });
      window.scrollTo(0,0);
    }else{
    try {
      await Auth.updateUserAttributes(user, {
        name: given_name + given_name ? '' : ' ' + family_name,
        family_name: family_name,
        given_name: given_name,
        address: address,
        email: email,
        'custom:city': city,
        'custom:state': state,
        'custom:country': country,
        'custom:zipCode': zipCode,
      });
      console.log('successful updated Profile.');
      await Auth.currentAuthenticatedUser()
        .then((updatedUser) => {
          let userObject = constructUser(updatedUser);
          AsyncStorage.setItem('user', userObject);
        })
        .catch(() => {
          console.log('user not authenticated');
        });
      setUserProfile((cur) => {
        return {
          ...cur,
          error: 'Successfully updated Profile',
        };
      });
      props.dest
        ? props.navigation.navigate(props.dest, {forceRender: true})
        : null;//props.navigation.goBack();
    } catch (err) {
      console.log('error update profile...', err);
      setUserProfile((cur) => {
        return {
          ...cur,
          error: err.message,
        };
      });
    }finally{
      window.scrollTo(0,0);
    }
    }
  }

  const handleOnPress = (index) => {
    if (index === 2) {
      AmplifyAuth.signOut();
    } else if (index === 0) {
      props.transitionState(USER_STATE.ORDERS);
    } else {
      props.transitionState(USER_STATE.PROFILE);
    }
  };

  return (
    <>
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        <MyListMenu
          data={['Orders', 'Profile', 'Logout']}
          onPress={handleOnPress}
        />
      </View>
      {props.userState !== USER_STATE.PROFILE ? (
        <Orders
          navigation={props.navigation}
          route={props.route}
          transitionState={props.transitionState}
          userState={props.userState}
          searchedOrderId={props.searchedOrderId}
        />
      ) : (
        <View style={{alignSelf: 'center', alignItems: 'center'}}>
          <Fragment>
            <Text style={styles.errorMsg}>{userProfile.error}</Text>
            <InputText
              placeholder="Please Enter your First Name"
              name="given_name"
              autoComplete={'name-given'}
              textContentType={'givenName'}
              value={userProfile.given_name || ''}
              onChangeText={onChangeText}
              label={(business?.profile?.delivery ? '*' : '') + 'First Name'}
            />
            <InputText
              placeholder="Please Enter your Last Name"
              name="family_name"
              autoComplete={'name-family'}
              textContentType={'familyName'}
              value={userProfile.family_name || ''}
              onChangeText={onChangeText}
              label={(business?.profile?.delivery ? '*' : '') + 'Last Name'}
            />
            <InputText
              placeholder="Please enter your Email"
              name="email"
              textContentType= {'emailAddress'}
              autoComplete= {'email'}
              value={userProfile.email || ''}
              onChangeText={onChangeText}
              label={
                (business?.profile?.delivery ? '*' : '') +
                'Email. (By providing email you agree to receive emails related \n to your orders. You can remove email at any time to stop \n receiving email)'
              }            />
            {false && (
            <CheckBox
              textStyle={{
                color: theme?.colors?.info,
                backgroundColor: 'transparent',
                fontSize: theme?.fonts.s,
                marginBottom: theme?.spacing.ss,
                fontWeight: 'normal',
                flexWrap: 'wrap',
              }}
              containerStyle={{
                padding: 0,
                margin: 0,
                marginLeft: 10,
                width: maxInputFieldWidth - 20,
              }}
              checkedColor="grey"
              title="By checking this box you agree to receive emails related to your orders. You can uncheck email at any time to stop receiving emails)"
              checkedIcon="dot-circle-o"
              uncheckedIcon="circle-o"
              checked={userProfile?.emailSubscription}
              onPress={onChangeText(
                'emailSubscription',
                !userProfile?.emailSubscription,
              )}
            />
            )}
            <InputTextArea
              placeholder="Please Enter your address for delivery"
              value={userProfile.address || ''}
              name="address"
              autoComplete={'street-address'}
              textContentType={'streetAddressLine1'}
              onChangeText={onChangeText}
              label={
                (business?.profile?.delivery ? '*' : '') + 'Shipping Address'
              }
              width={maxInputFieldWidth - 20}
            />
            <InputText
              placeholder="Please enter your City"
              name="city"
              autoComplete={'postal-address-locality'}
              textContentType={'addressCity'}
              value={userProfile.city || ''}
              onChangeText={onChangeText}
              label={(business?.profile?.delivery ? '*' : '') + 'City'}
            />
            <InputText
              placeholder="Please enter your State"
              name="state"
              autoComplete={'postal-address-region'}
              textContentType={'addressState'}
              value={userProfile.state || ''}
              onChangeText={onChangeText}
              label={(business?.profile?.delivery ? '*' : '') + 'State'}
            />
            <InputText
              placeholder="Please enter your Pin Code"
              name="zipCode"
              autoComplete={'ppostal-code'}
              textContentType={'postalCode'}
              value={userProfile.zipCode || ''}
              onChangeText={onChangeText}
              country = {userProfile?.country}
              label={(business?.profile?.delivery ? '*' : '') + 'Pin Code'}
            />
            <MyCountryPicker
              selectedValue={userProfile?.country}
              business={business}
              returnedAttribute="country"
              onChangeText={onChangeText}
              label="*Country"
            />
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
              <ActionButton title="Update Profile" onPress={profile} />
            </View>
          </Fragment>
        </View>
      )}
    </>
  );
};
