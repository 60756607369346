//export const cloudFrontURL = 'https://d38qh1cnis0zvf.cloudfront.net/';
//export const cloudFrontURL = 'https://herbsnhues.com/';
export const cloudFrontURL =
  window.location.host.indexOf('localhost') !== -1
    ? 'https://d38qh1cnis0zvf.cloudfront.net/'
    : '/';

export const CommonProfile = {
  phoneNumberPrefix: '+91',
  usPhoneNumberPrefix: '+1',
  privacyPolicyURL: cloudFrontURL + 'public/privacypolicy/ShopMyShopz.html',
  termsAndConditionsURL:
    cloudFrontURL + 'public/privacypolicy/ShopMyShopz.html',
  faqURL: cloudFrontURL + 'public/privacypolicy/ShopMyShopz.html',
  profileImage: 'public/common/profile.png',
  bizName: 'ShopMyShopz',
};

export const PAYMENTS_CALLBACK_URL = cloudFrontURL + 'dev/payments';

export const PAYMENT_PAGE = cloudFrontURL + 'paymentPage.html?options=';
export const INTERNATIONAL_PAYMENT_PAGE = cloudFrontURL + 'internationalPaymentPage.html?options=';
