import {Analytics, Auth} from 'aws-amplify';
import React, {Fragment, useState, useContext} from 'react';
import {Text, View} from 'react-native';
import {cleanPhoneNumber, PHONE_NUMBER_LENGTH, USER_EVENTS, validatePhone} from '../../utilities/Constants';
import {
  constructUser,
} from '../../utilities/RetailClient';
import {styles, maxInputFieldWidth, MyContext} from '../../styles/styles';
import {
  InputPhone,
  InputSecret,
  ActionButton,
  InputText,
  MyButton,
} from '../ui/elements';
import {MyStorage as AsyncStorage} from './../../utilities/MyStorage';
import {ActivityIndicator} from 'react-native';
import I18n from '../../I18n/I18n';

export const SignIn = (props) => {
  const {business, theme} = useContext(MyContext);
  const [state, setStateInternal] = useState({
    username: '',
    password: '',
    error: '',
    hidePassword: true,
    stage: 0,
    authCode: '',
    phoneNumberPrefix: '',
    showActivityIndicator: false,
  });

  const setState = (key, value) => {
    setStateInternal((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  const onChangeText = (key, value) => {
    setState(key, value?value.trim():value,);
    setState('error', '');
  };

  const signIn = async () => {
    const {username, password, phoneNumberPrefix} = state;
      console.log(
        'username used for login',
        phoneNumberPrefix+username,
      );
      if (phoneNumberPrefix?.length <2) {
        setState('error', I18n.t('INVALID_COUNTRY_CODE'));
        return;
      }
      if (cleanPhoneNumber(username)?.length !== PHONE_NUMBER_LENGTH) {
        setState('error', I18n.t('INVALID_PHONE'));
        return;
      }
      setState('showActivityIndicator', true);
      await Auth.signIn(phoneNumberPrefix+cleanPhoneNumber(username), password).then(
        (user) => {
          let userObject = constructUser(user);
          AsyncStorage.setItem('user', userObject);
          props.dest
            ? props.navigation.navigate(props.dest, {
                forceRender: false,
                returnState: props.returnState,
              })
            : props.navigation.goBack();
          Analytics.record(USER_EVENTS.SIGN_IN);
          console.log('successfully signed in');
        },
      ).catch( (err) =>{
        if (err.code === 'UserNotConfirmedException') {
           Auth.resendSignUp(state.phoneNumberPrefix+cleanPhoneNumber(state.username));
          setState('stage', 1);
        } else {
          Analytics.record(USER_EVENTS.SIGN_IN_FAILURE);
          console.log('Sign In failure', err);
          setState(
            'error',
            "Mobile Number and/or password don't match. Please try again. Try 'Forgot your password?' below if you forgot your password",
          );
        }
        }
      ).finally( () => {
           setState('showActivityIndicator', false);
        }
      )
  };
  const showForgotPassword = () => {
    props.toggleAuthType('showForgotPassword');
  };
  const confirmSignUp = async () => {
    const {username, authCode, phoneNumberPrefix} = state;
    try {
      setState('showActivityIndicator', true);
      await Auth.confirmSignUp(phoneNumberPrefix+cleanPhoneNumber(username), authCode);
      Analytics.record(USER_EVENTS.CONFIRM_SIGNUP);
      setState('stage', 0);
      setState('error', 'Mobile Number confirmed successfully...');
    } catch (err) {
      console.log('error confirming user...', err);
      setState('stage', 0);
      Analytics.record(USER_EVENTS.CONFIRM_SIGNUP_FAILURE);
      setState(
        'error',
        'Confirmation code and mobile number do not match. Please try agaiin...',
      );
    } finally {
      setState('showActivityIndicator', false);
    }
  };
  return (
    <View
      style={{
        maxWidth: maxInputFieldWidth,
        alignSelf: 'center',
        alignItems: 'center',
      }}>
      {state.showActivityIndicator && (
        <ActivityIndicator animating size="large" color="grey" />
      )}
      {state.stage === Number(0) && (
        <Fragment>
          <Text style={styles.errorMsg}>{state.error}</Text>
          <InputPhone
            placeholder="10 digit number"
            name="username"
            value={state.username}
            onChangeText={onChangeText}
            selectedValue={state?.phoneNumberPrefix}
            autoCompleteType={'username'}
            autoComplete={'username'}
            textContentType={'username'}
            business={business}
            label="*Mobile Number"
          />
          <InputSecret
            placeholder="Password"
            name="password"
            value={state.password}
            onChangeText={onChangeText}
            label="*Password (Password must be minimum 8 charctaters and have a lower case, an upppercase and a numeric character)"
          />
          <ActionButton title="Sign In" onPress={signIn} />
        </Fragment>
      )}
      {state.stage === Number(1) && (
        <Fragment>
          <Text style={{marginBottom: 10}}>
            Please enter the confirmation code sent to your mobile
          </Text>
          <InputText
            placeholder="Confirmation Code"
            name="authCode"
            value={state.authCode}
            onChangeText={onChangeText}
            autoComplete="sms-otp"
            textContentType="oneTimeCode"
            label="*Confirmation Code"
          />
          <ActionButton title="Confirm Sign Up" onPress={confirmSignUp} />
        </Fragment>
      )}
      <MyButton title="Forgot your password?" onPress={showForgotPassword} />
    </View>
  );
};
