import {Analytics, API} from 'aws-amplify';
import {gql} from 'apollo-boost';
import * as React from 'react';
import {useState, useEffect, useRef} from 'react';
import {
  FlatList,
  Image,
  TouchableOpacity,
  View,
  Text,
  StyleSheet,
} from 'react-native';
import {useScrollToTop} from '@react-navigation/native';

import {USER_EVENTS, USER_STATE} from '../utilities/Constants';
import {MyStorage as AsyncStorage} from '../utilities/MyStorage';
import {cloudFrontURL} from '../utilities/profile';
import {HeightByWidth, numColumns, numHomeColumns} from '../styles/styles';
import {MyStatusBar} from '../components/ui/MyStatusBar';
import {MyContext} from '../styles/styles';
import {ActionButton, MyText, MyLinks, MyCarousal} from './ui/elements';
import {isDesktop, isMobile} from 'react-device-detect';
import {Footer} from './footer/Footer';
import {WebView} from 'react-native-web-webview';

function HomeScreen({route, navigation}) {
  const {theme, user} = React.useContext(MyContext);
  console.log('route: ', JSON.stringify(route));
  const webView = useRef();

  const [businesses, setBusinesses] = useState([]);
  const [selected, setSelected] = useState();
  const [showHomeSearchBar, setShowHomeSearchBar] = useState(false);
  const [tag, setTag] = useState('');
  const initialPageState = {
    errorMessage: '',
    searched: true,
    searchResultMessage: '',
  };
  const [pageState, setPageState] = useState(initialPageState);
  const ref = React.useRef(null);
  const [curWebView, setCurWebViewInternal] = useState();
  function setCurWebView(wv) {
    setCurWebViewInternal(wv);
    window.scrollTo(0, 0);
  }
  useScrollToTop(ref);

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', (e) => {
      setCurWebView(null);
    });
  }, [navigation]);

  useEffect(() => {
    let domain = route.params.domain;
    /*
    let bizId = route.params.bizId.substring(
      '?bizId='.length,
      route.params.bizId.length,
    );
    API.graphql({
      query: getBusiness,
      variables: {id: bizId},
    }).then((data) => {
      let business = data.data.getBusiness;
      let profile = JSON.parse(business.profile);
      business.profile = profile;
      setBusinesses([business]);
      AsyncStorage.setItem('business', business);
    });
     */
    /* removed opensearch
    API.graphql({
      query: gql`
        query searchBusinesss(
          $nextToken: String
          $joinedQuery: [SearchablebusinessFilterInput]
        ) {
          searchBusinesses(
            filter: {or: $joinedQuery}
            limit: 50
            nextToken: $nextToken
          ) {
            items {
              id
              name
              domain
              rank
              profile
              tags
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      `,
      variables: {
        nextToken: null,
        joinedQuery: [{domain: {regexp: domain}}],
      },
    })*/
    API.graphql({
      query: gql`
      query listBusinesses( $nextToken: String) {
        listBusinesses(nextToken: $nextToken, limit: 50) {
          items {
            id
            name
            domain
            rank
            profile
            tags
            createdAt
            updatedAt
          }
        }
      }
      `,
      variables: {
        nextToken: null
      },
    })
      .then((data) => {
        let businesses = data.data.listBusinesses.items;
        let business = undefined;
        businesses.forEach(biz => {
          if(domain.indexOf(biz.domain) != -1)business=biz;
        })
        let profile = JSON.parse(business.profile);
        business.profile = profile;
        setBusinesses([business]);
        AsyncStorage.setItem('business', business);
        if (window.location.search.indexOf('?search=') !== -1) {
          let search = window.location.search.substring(
            '?search='.length,
            window.location.search.length + 1,
          );
          navigation.navigate('Search', {search: search});
        } else if (window.location.search.indexOf('?orderId=') !== -1) {
          let orderId = window.location.search.substring(
            '?orderId='.length,
            window.location.search.length + 1,
          );
          navigation.navigate('Auth', {orderId: orderId});
        }
      })
      .catch((e) => {
        console.log(e);
        throw e;
      });
  }, [route.params.domain]);

  useEffect(() => {
    //fetchData(user);
    function fetchData(userObject) {
      console.log('home fetch tag: ' + tag);
      const splitQuery = tag ? tag.trim().toLowerCase().split(/\s+/) : '';
      const joinedQueryName = splitQuery
        ? splitQuery.map((part) => ({
            name: {regexp: `.*${part}.*`},
          }))
        : [{name: {regexp: '.*'}}];
      const joinedQueryLocation = splitQuery
        ? splitQuery.map((part) => ({
            location: {regexp: `.*${part}.*`},
          }))
        : [{location: {regexp: '.*'}}];
      const joinedQuery = [...joinedQueryName, ...joinedQueryLocation];
      console.log('joinedQuery: ' + JSON.stringify(joinedQuery));
/*Removed OpenSearch
      API.graphql({
        query: gql`
          query searchBusinesss(
            $nextToken: String
            $joinedQuery: [SearchablebusinessFilterInput]
          ) {
            searchBusinesses(
              filter: {or: $joinedQuery}
              limit: 50
              nextToken: $nextToken
            ) {
              items {
                id
                name
                rank
                profile
                tags
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        `,
        variables: {
          nextToken: null,
          joinedQuery: joinedQuery,
        },
      })
        .then((data) => {
          fetchDataInternal(data.data.searchBusinesses.items, userObject);
        })
        .catch((e) => {
          console.log(e);
          throw e;
        });
        */

        API.graphql({
          query: gql`
            query listBusinesses(
              $nextToken: String
            ) {
              listBusinesses(
                limit: 50
                nextToken: $nextToken
              ) {
                items {
                  id
                  name
                  rank
                  profile
                  tags
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
          `,
          variables: {
            nextToken: null
          },
        })
          .then((data) => {
            fetchDataInternal(data.data.listBusinesses.items, userObject);
          })
          .catch((e) => {
            console.log(e);
            throw e;
          });
    }
    function fetchDataInternal(businessesInternal, userInternal) {
      if (!businessesInternal || businessesInternal.length === 0) {
        setPageState((prevState) => {
          return {
            ...prevState,
            searchResultMessage:
              'No shops found with this name or location. Try again',
            searched: true,
          };
        });
        return;
      } else {
        setPageState((prevState) => {
          return {
            ...prevState,
            searchResultMessage: '',
            errorMessage: '',
            searched: true,
          };
        });
      }
      for (var i = 0; i < businessesInternal.length; i++) {
        let profile = JSON.parse(businessesInternal[i].profile);
        businessesInternal[i].profile = profile;
      }
      businessesInternal.sort(function (a, b) {
        return a.rank - b.rank;
      });
      setBusinesses(businessesInternal);
      if (businessesInternal && businessesInternal.length > 0) {
        var privBusId = userInternal?.isPrivileged
          ? userInternal?.businessId
          : null;
        var selection;
        if (privBusId) {
          businessesInternal.forEach((biz, index) => {
            if (businessesInternal[index].id === privBusId) {
              selection = index;
            }
          });
        }
        setSelected(selection ? selection : 0);
        AsyncStorage.setItem(
          'business',
          businessesInternal[selection ? selection : 0],
        );
      }
      /**
        !selected
          ? showMessage({
              message:
                'Please choose your shop by clicking on it. You can change your selection anytime by clicking on another shop',
  //            type: 'info',
              duration:2000,
            })
          : showMessage({
              message: 'You are now in ' + businesses[selected]?.profile.title,
   //           type: 'info',
            });
            */
      Analytics.record(USER_EVENTS.PAGE_VIEW);
    }
  }, [user, tag]);
  const onSelect = async (index) => {
    setSelected(index);
    console.log('setSelected', index);
    AsyncStorage.setItem('business', businesses[index]);
    navigation.navigate('Search', {
      userState: USER_STATE.LANDING,
      searchText: 'HighLight',
    });
    /**
    showMessage({
      message: 'You are now in '+businesses[index]?.profile.title,
//type: 'info',
    });
     */
  };
  const localStyles = StyleSheet.create({
    infoHeadingHighLight: {
      fontSize: theme?.fonts.xxxl,
      color: theme?.colors?.secondary,
      marginTop: 30,
      fontWeight: isMobile ? 'normal' : 'bold',
    },
    infoMessage: {
      fontSize: isMobile ? theme?.fonts?.ml : theme?.fonts?.xxxl,
      color: theme?.colors?.info,
      marginTop: 20,
      marginBottom: 20,
    },
    itemRowContainer: {
      flexDirection: 'row',
      width: '100%',
      flexWrap: 'wrap',
      alignItems: 'center',
    },
    itemImage: {
      height: (HeightByWidth * theme?.screenWidth) / numHomeColumns,
      width: theme?.screenWidth / numHomeColumns,
    },
    rightBox: {
      flex: 1,
      padding: 0,
      marginLeft: '3%',
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: theme?.screenWidth / numHomeColumns,
    },
    videoRightBox: {
      flex: 1,
      padding: 0,
      marginLeft: '3%',
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: (theme?.screenWidth-10) / (numHomeColumns),
    },
    topBox: {
      flex: 1,
      padding: 0,
      marginBottom: 20,
      flexDirection: 'column',
      width: theme?.screenWidth,
      alignItems: 'center',
    },
    bottomBand: {
      alignSelf: 'center',
      marginBottom: theme?.spacing?.s,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'black',
    },
  });
  function Item({index}) {
    let rq = {uri: cloudFrontURL + businesses[index]?.profile.homePagePic1};
    return (businesses[index]?.profile.webLive &&
      (!user || !user?.isPrivileged)) ||
      (user?.isPrivileged && user?.businessId === businesses[index].id) ? (
      <View style={{flexDirection: 'column', width: '100%'}}>
        <TouchableOpacity
          styles={localStyles.itemImage}
          onPress={() => onSelect(index)}>
          <View style={localStyles.topBox}>
            {businesses[index]?.profile?.logo2 && (
              <Image
                style={[{width: 101, height: 71}]}
                resizeMode="contain"
                source={{
                  uri: cloudFrontURL + businesses[index]?.profile?.logo2,
                }}
              />
            )}
          </View>
          <View style={localStyles.itemRowContainer}>
        {businesses[index]?.profile.homePagePic1 && isDesktop && false && (
        <WebView 
        ref={webView}
        style={{
          alignSelf: 'center',
          height: (theme?.screenWidth / numHomeColumns)*704/480,
          width: (theme?.screenWidth / numHomeColumns),
          paddingLeft: theme?.isMobile?0:theme?.screenWidth/2- (theme?.screenWidth / numHomeColumns)/2,

        }}
        originWhitelist={['*']}
        mixedContentMode={'compatibility'}
        javaScriptEnabled
        scalesPageToFit={false} 
        source={{ html: `<html>
        <head>
        <link href="https://herbsnhues.com/public/d41f69cc-f00f-4d93-ab39-d36abeca7f77/profile/video-js.min.css" rel="stylesheet" />
      </head>
      
      <body>
        <video
          id="my-video"
          class="video-js"
          type="video/mp4"
          controls
          preload="auto"
          aspectRatio="480:704"
          fluid= true
          poster="https://herbsnhues.com/public/d41f69cc-f00f-4d93-ab39-d36abeca7f77/profile/videoposter.png"
          data-setup="{}"
        >
          <source src="https://herbsnhues.com/public/d41f69cc-f00f-4d93-ab39-d36abeca7f77/profile/HomePageMooviemp4.mp4" />
        </video>
        <script src="https://vjs.zencdn.net/7.20.3/video.min.js"></script>
      </body>
    </html>` }} />
        )}
      </View>          
          <View style={localStyles.itemRowContainer}>
            {businesses[index]?.profile.homePagePic1 && (
              <Image
                resizeMode="contain"
                source={{
                  uri: cloudFrontURL + businesses[index]?.profile.homePagePic1,
                }}
                style={localStyles.itemImage}
              />
            )}
            <View style={localStyles.rightBox}>
              <Text style={localStyles.infoHeadingHighLight}>
                {businesses[index]?.profile?.home.para1.homePageText1}
              </Text>
              <Text style={localStyles.infoMessage}>
                {businesses[index]?.profile?.home.para1.homePageText2}
              </Text>
              <Text style={localStyles.infoMessage}>
                {businesses[index]?.profile?.home.para1.homePageText3}
              </Text>
              <Text style={localStyles.infoMessage}>
                {businesses[index]?.profile?.home.para1.homePageText4}
              </Text>
            </View>
          </View>
          <View style={localStyles.itemRowContainer}>
            {businesses[index]?.profile.homePagePic2 && !isDesktop && (
              <Image
                resizeMode="contain"
                source={{
                  uri: cloudFrontURL + businesses[index]?.profile.homePagePic2,
                }}
                style={localStyles.itemImage}
              />
            )}
            <View style={localStyles.rightBox}>
              <Text style={localStyles.infoMessage}>
                {businesses[index]?.profile?.home.para2.homePageText1}
              </Text>
              <Text style={localStyles.infoMessage}>
                {businesses[index]?.profile?.home.para2.homePageText2}
              </Text>
              <Text style={localStyles.infoMessage}>
                {businesses[index]?.profile?.home.para2.homePageText3}
              </Text>
            </View>
            {businesses[index]?.profile.homePagePic2 && isDesktop && (
              <Image
                resizeMode="contain"
                source={{
                  uri: cloudFrontURL + businesses[index]?.profile.homePagePic2,
                }}
                style={localStyles.itemImage}
              />
            )}
          </View>
          <View style={localStyles.itemRowContainer}>
            {businesses[index]?.profile.homePagePic3 && (
              <Image
                resizeMode="contain"
                source={{
                  uri: cloudFrontURL + businesses[index]?.profile.homePagePic3,
                }}
                style={localStyles.itemImage}
              />
            )}
            <View style={localStyles.rightBox}>
              <Text style={localStyles.infoMessage}>
                {businesses[index]?.profile?.home.para3.homePageText1}
              </Text>
              <Text style={localStyles.infoMessage}>
                {businesses[index]?.profile?.home.para3.homePageText2}
              </Text>
              <View style={{marginVertical: 20}} />
              <ActionButton
                title="Shop Now"
                icon="shopping-cart"
                onPress={() => onSelect(index)}
              />
            </View>
          </View>
        </TouchableOpacity>
        <View style={localStyles.bottomBand}>
          <MyCarousal
            sliderWidth={theme?.screenWidth}
            itemWidth={
              isMobile
                ? theme?.screenWidth / 1.5
                : (theme?.screenWidth - 48 * 2) / 5
            }
            data={businesses[index]?.profile.benefits}
            customRenderItem={(item) => {
              return (
                <View
                  style={{
                    padding: 20,
                    margin: 15,
                    backgroundColor: theme?.colors?.homeScreenCarousel,
                    alignItems: 'center',
                  }}>
                  <MyText type="carousel">{item.item.name}</MyText>
                </View>
              );
            }}
            height={30}
            loop={false}
            iconSize={25}
            showArrows={true}
            inactiveSlideOpacity={1}
            inactiveSlideScale={1}
            getItemDescription={(item) => null}
          />
        </View>
        <MyCarousal
          title="Our Customers Testimonials"
          data={businesses[index].profile.testimonials}
          getItemText={(item) => item.comment}
          getItemHeading={(item) => item.name}
          getImageUrl={() => null}
          height={30}
          itemWidth={theme.screenWidth}
          sliderWidth={theme.screenWidth - 96}
          showArrows={true}
          loop={true}
          iconSize={25}
          onPress={(item, index, carouselRef) => {
            if (carouselRef.current.auto) {
              carouselRef.current.stopAutoplay();
              carouselRef.current.auto = false;
            } else {
              carouselRef.current.startAutoplay(true);
              carouselRef.current.auto = true;
            }
          }}
          getItemDescription={(item) => null}
        />
      </View>
    ) : null;
  }

  const handleLoadMore = () => {};

  console.log('home user:', user?.firstName);

  function onChangeText(text) {
    setTag(text);
  }
  function clearSearch() {
    setPageState((prevState) => {
      return {
        ...prevState,
        searchResultMessage:
          'No shops found with this name or location. Try again',
        searched: true,
      };
    });
  }
  return (
    <>
      {false && (
        <MyStatusBar
          title={
            businesses[selected] ? businesses[selected]?.profile?.title : ''
          }
          navigation={navigation}
          rightComponent="cart"
          page="home"
          onChangeText={onChangeText}
          tag={tag}
          clearSearch={clearSearch}
          pageState={pageState}
          logoURL={businesses[selected]?.profile?.logo}
          showHomeSearchBar={showHomeSearchBar}
        />
      )}
      {curWebView ? (
        <MyLinks
          business={businesses[selected ? selected : 0]}
          curWebView={curWebView}
          setCurWebView={setCurWebView}
        />
      ) : (
        <>
          {pageState.searchResultMessage ? (
            <Text
              style={[
                {
                  fontSize: 12,
                  paddingLeft: 15,
                  color: theme?.colors?.error,
                  alignContent: 'center',
                  alignSelf: 'center',
                  textAlignVertical: 'center',
                },
              ]}>
              {pageState.searchResultMessage}
            </Text>
          ) : null}
          <FlatList
            style={[{borderTopWidth: 0, borderBottomWidth: 0, flexGrow: 0}]}
            contentContainerStyle={[{alignContent: 'flex-start'}]}
            data={businesses}
            renderItem={({index}) => <Item index={index} />}
            bounces={false}
            keyExtractor={(item) => item.id}
            numColumns={1}
            horizontal={false}
            onEndReached={handleLoadMore}
            onEndReachedThreshold={0.5}
          />
        </>
      )}
      <Footer setCurWebView={setCurWebView} />
    </>
  );
}
export default HomeScreen;
