import React, {useContext, useEffect, useState, useRef} from 'react';
import {
  TextInput,
  TouchableHighlight,
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  FlatList,
  Image,
} from 'react-native';
import {Button, SearchBar} from 'react-native-elements';
import {
  maxInputFieldWidth,
  carouselPicSize,
  HeightByWidth,
} from '../../styles/styles';
import Icon from 'react-native-vector-icons/FontAwesome';
import NumericInput from 'react-native-numeric-input';
import {MyContext} from '../../styles/styles';
import {Slider, ButtonGroup} from 'react-native-elements';
import Autocomplete from 'react-native-autocomplete-input';
import HTML from 'react-native-render-html';
import {cloudFrontURL} from '../../utilities/profile';
import {isMobile} from 'react-device-detect';
import Carousel, {Pagination} from 'react-native-snap-carousel';
import {Platform} from 'react-native';
import {MyIcon} from './icons';
import {Picker} from '@react-native-picker/picker';
import { cleanPhoneNumber, PHONE_NUMBER_LENGTH, SELECT_YOUR_COUNTRY, validateEmail, validateZipCode } from '../../utilities/Constants';

/* autoCompleteType 
     *Android Only*
     *
     * Possible values for `autoComplete` are:
     * - `birthdate-day`
     * - `birthdate-full`
     * - `birthdate-month`
     * - `birthdate-year`
     * - `cc-csc`
     * - `cc-exp`
     * - `cc-exp-day`
     * - `cc-exp-month`
     * - `cc-exp-year`
     * - `cc-number`
     * - `email`
     * - `gender`
     * - `name`
     * - `name-family`
     * - `name-given`
     * - `name-middle`
     * - `name-middle-initial`
     * - `name-prefix`
     * - `name-suffix`
     * - `password`
     * - `password-new`
     * - `postal-address`
     * - `postal-address-country`
     * - `postal-address-extended`
     * - `postal-address-extended-postal-code`
     * - `postal-address-locality`
     * - `postal-address-region`
     * - `postal-code`
     * - `street-address`
     * - `sms-otp`
     * - `tel`
     * - `tel-country-code`
     * - `tel-national`
     * - `tel-device`
     * - `username`
     * - `username-new`
     * - `off`
     * Possible values for `textContentType` for ios are:
     *
     *  - `'none'`
     *  - `'URL'`
     *  - `'addressCity'`
     *  - `'addressCityAndState'`
     *  - `'addressState'`
     *  - `'countryName'`
     *  - `'creditCardNumber'`
     *  - `'emailAddress'`
     *  - `'familyName'`
     *  - `'fullStreetAddress'`
     *  - `'givenName'`
     *  - `'jobTitle'`
     *  - `'location'`
     *  - `'middleName'`
     *  - `'name'`
     *  - `'namePrefix'`
     *  - `'nameSuffix'`
     *  - `'nickname'`
     *  - `'organizationName'`
     *  - `'postalCode'`
     *  - `'streetAddressLine1'`
     *  - `'streetAddressLine2'`
     *  - `'sublocality'`
     *  - `'telephoneNumber'`
     *  - `'username'`
     *  - `'password'`
     *  - `'newPassword'`
     *  - `'oneTimeCode'`
     *
     */

export const InputText = ({
  placeholder,
  name,
  title,
  value,
  onChangeText,
  autoComplete,
  textContentType,
  width,
  label,
  editable,
  country,
  err,
}) => {
  const {theme, business}=useContext(MyContext);
  const [errMsg, setErrMsg] = React.useState(null);

  const localStyles = StyleSheet.create({
    rowContainer: {
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      marginLeft: 10,
    },
    singleline: {
      borderWidth: 1,
      borderRadius: 5,
      marginBottom: 10,
      width: width ? width : maxInputFieldWidth - 20,
      height: 48,
      textAlignVertical: 'top',
      backgroundColor: theme?.colors?.inputTextBackground,
      fontSize: theme?.fonts.l,
      paddingHorizontal: 14,
      color: theme?.colors?.secondary,
    },
    label: {
      fontSize: theme?.fonts.s,
      marginBottom: theme?.spacing.ss,
    },    
    err: {
      fontSize: theme?.fonts.s,
      marginBottom: theme?.spacing.ss,
      color: 'red'
    },
  });
  return (
    <View style={localStyles.rowContainer}>
      <View style={{flexDirection: 'column'}}>
        {errMsg && <Text style={localStyles.err}>{errMsg}</Text>}
        <Text style={localStyles.label}>{label}</Text>
      </View>
      <TextInput
        title={title}
        style={localStyles.singleline}
        placeholder={placeholder}
        autoCapitalize={"words"}
        autoCorrect={false}
        onChangeText={(v) => {
          if(name==='zipCode'){
             if(!isNaN(v)){
              let errMsg= validateZipCode(business, country, v);
              setErrMsg(errMsg);
              onChangeText(name, v);
             }
          }else if(name==='email'){
            setErrMsg(validateEmail(v));
            onChangeText(name, v);
          }else {
            onChangeText(name, v);
          }
        }}
        placeholderTextColor={theme?.colors?.placeholder}
        selectionColor={theme?.colors?.primary}
        editable = {editable==='undefined'?true:editable}
        autoComplete={autoComplete ? autoComplete : 'name'}
        textContentType={textContentType ? textContentType : 'name'}
        textAlign="left"
        maxLength={theme?.screenWidth}
        value={value}
      />
    </View>
  );
};
export const InputPhone = ({
  placeholder,
  name,
  value,
  onChangeText,
  autoCompleteType,
  autoComplete,
  textContentType,
  width,
  label,
  selectedValue,
  business,
}) => {
  const theme = React.useContext(MyContext)?.theme;
  const formatPhoneNumber = (cleaned) => {
    //Check if the input is of correct
    let match = cleaned.match(/^(1|91)?(\d{0,3})(\d{0,3})(\d{0,4})$/);
    
    if (match) {
      //Remove the matched extension code
      //Change this to format for any country code.
      let intlCode = (match[1]==1 ? '+1 ' : match[1]==91?'+91':'')
      return {intlCode:intlCode, phoneNumber: [match[2]?'('+ match[2]:'', match[3]?') '+match[3]:'', match[4]?'-'+match[4]:''].join('')}
    } 
    else return {};
  }
  const localStyles = StyleSheet.create({
    combinedContainer: {
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      marginBottom: 10,
    },    
    rowContainer: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'flex-start',
      width: width ? width : maxInputFieldWidth - 10,
    },
    singleline: {
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
      marginBottom: 0,
      height: 48,
      textAlignVertical: 'top',
      backgroundColor: theme?.colors?.transparent,
      fontSize: theme?.fonts.l,
      color: theme?.colors?.secondary,
      paddingHorizontal: 2,
      width: '52%',
    },
    countryCode: {
      borderWidth: 1,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      marginBottom: 0,
      width: '10%',
      height: 48,
      textAlignVertical: 'top',
      backgroundColor: theme?.colors?.transparent,
      fontSize: theme?.fonts.l,
      color: theme?.colors?.secondary,
      textAlign: 'center',
    },

    label: {
      fontSize: theme?.fonts.s,
      marginBottom: theme?.spacing.ss,
      width: width ? width : maxInputFieldWidth - 20,
      marginLeft: 10,
    },
  });
  return (
    <View style={localStyles.combinedContainer}>
      <Text style={localStyles.label}>{label}</Text>

    <View style={localStyles.rowContainer}>  
    <MyCountryPicker
            selectedValue={selectedValue}
            business={business}
            returnedAttribute="phoneNumberPrefix"
            onChangeText={onChangeText}
            label=""
            isForPhone={true}
            width='38%'          
      />    
      <TextInput
        editable={false}
        style={localStyles.countryCode}
        placeholder={''}
        autoCapitalize="none"
        autoCorrect={false}
        secureTextEntry={false}
        placeholderTextColor={theme?.colors?.placeholder}
        selectionColor={theme?.colors?.primary}
        value={selectedValue}
      />      
    <TextInput
      editable
      style={localStyles.singleline}
      placeholder={placeholder}
      autoCapitalize="none"
      autoCorrect={false}
      onChangeText={(v) => {
        //Filter only numbers from the input
        let cleaned = cleanPhoneNumber(v);
        if(!cleaned ===""){
          if(isNaN(cleaned)) return;
        } 

        let formattedNumber=formatPhoneNumber(cleaned);
        if(cleanPhoneNumber(formattedNumber.phoneNumber).length > 10)return;
        if(formattedNumber.intlCode)onChangeText("phoneNumberPrefix", formattedNumber.intlCode);
        if(formattedNumber.phoneNumber || formattedNumber.phoneNumber===""){
          onChangeText(name, formattedNumber.phoneNumber);
        }          
      }}
      secureTextEntry={false}
      placeholderTextColor={theme?.colors?.placeholder}
      selectionColor={theme?.colors?.primary}
      autoCompleteType={autoCompleteType?autoCompleteType:'tel'}
      autoComplete={autoComplete?autoComplete:'tel'}
      textContentType={textContentType?textContentType:'telephoneNumber'}
      dataDetectorTypes="phoneNumber"
      keyboardType="phone-pad"
      value={value}
    />
    </View>
    </View>
  );
};

export const InputSecret = ({
  placeholder,
  name,
  value,
  onChangeText,
  autoCompleteType,
  textContentType,
  label,
  width,
}) => {
  const [getIsHidden, setIsHidden] = useState(true);
  const theme = React.useContext(MyContext).theme;
  const localStyles = StyleSheet.create({
    rowContainer: {
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      marginLeft: 10,
    },
    singleline: {
      borderWidth: 1,
      borderRadius: 5,
      marginBottom: 10,
      width: width ? width : maxInputFieldWidth - 20,
      height: 48,
      textAlignVertical: 'top',
      backgroundColor: theme?.colors?.inputTextBackground,
      fontSize: theme?.fonts.l,
      color: theme?.colors?.secondary,
      paddingHorizontal: 14,
    },
    label: {
      fontSize: theme?.fonts.s,
      marginBottom: theme?.spacing.ss,
      width: width ? width : maxInputFieldWidth - 20,
    },
  });
  return (
    <View style={{flexDirection: 'row', alignItems: 'center'}}>
      <View style={localStyles.rowContainer}>
        <Text style={localStyles.label}>{label}</Text>
        <TextInput
          style={localStyles.singleline}
          placeholder={placeholder}
          autoCapitalize="none"
          autoCorrect={false}
          onChangeText={(v) => onChangeText(name, v)}
          secureTextEntry={getIsHidden}
          placeholderTextColor={theme?.colors?.placeholder}
          selectionColor={theme?.colors?.primary}
          autoCompleteType={autoCompleteType ? autoCompleteType : 'password'}
          textContentType={textContentType ? textContentType : 'password'}
          value={value}
        />
      </View>
      {getIsHidden ? (
        <Icon
          name="eye-slash"
          style={{
            position: 'absolute',
            right: 10,
          }}
          type="font-awesome"
          color={theme?.colors?.icon}
          Component={TouchableHighlight}
          activeOpacity={0.6}
          underlayColor={theme?.colors?.iconTouchHighlight}
          size={20}
          onPress={() => setIsHidden(!getIsHidden)}
        />
      ) : (
        <Icon
          name="eye"
          style={{
            position: 'absolute',
            right: 10,
          }}
          type="font-awesome"
          Component={TouchableHighlight}
          activeOpacity={0.6}
          underlayColor={theme?.colors?.iconTouchHighlight}
          color={theme?.colors?.icon}
          size={20}
          onPress={() => setIsHidden(!getIsHidden)}
        />
      )}
    </View>
  );
};

export const InputTextArea = ({
  placeholder,
  name,
  title,
  value,
  onChangeText,
  autoCompleteType,
  textContentType,
  label,
  width,
}) => {
  const theme = React.useContext(MyContext)?.theme;
  const localStyles = StyleSheet.create({
    rowContainer: {
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      marginLeft: 10,
    },
    multiline: {
      borderWidth: 1,
      borderRadius: 5,
      marginBottom: 10,
      width: width ? width : maxInputFieldWidth - 20,
      minHeight: 48,
      height: 106,
      maxHeight: 106,
      textAlignVertical: 'top',
      backgroundColor: theme?.colors?.inputTextBackground,
      fontSize: 16,
      paddingHorizontal: 14,
      color: theme?.colors?.secondary,
    },
    label: {
      fontSize: theme?.fonts.s,
      marginBottom: theme?.spacing.ss,
    },
  });
  return (
    <View style={localStyles.rowContainer}>
      <Text style={localStyles.label}>{label}</Text>
      <TextInput
        multiline
        numberOfLines={2}
        style={localStyles.multiline}
        placeholder={placeholder}
        autoCapitalize="sentences"
        autoCorrect={false}
        onChangeText={(v) => onChangeText(name, v)}
        placeholderTextColor={theme?.colors?.placeholder}
        selectionColor={theme?.colors?.primary}
        autoCompleteType={
          autoCompleteType ? autoCompleteType : 'street-address'
        }
        textContentType={
          textContentType ? textContentType : 'fullStreetAddress'
        }
        value={value}
      />
    </View>
  );
};

export const ActionButton = ({
  onPress,
  title,
  disabled,
  icon,
  message,
  marginLeft,
}) => {
  const theme = React.useContext(MyContext)?.theme;
  const localStyles = StyleSheet.create({
    actionButton: {
      height: 40,
      justifyContent: 'center',
      alignSelf: 'center',
      flexDirection: 'row',
      alignItems: 'center',
    },
    actionButtonText: {
      color: theme?.colors?.primaryVariant,
      fontSize: theme?.fonts.ml,
    },
    actionButtonContainer: {
      backgroundColor: theme?.colors?.primaryLight2,
      borderRadius: 20,
      marginTop: theme?.spacing.sl,
      marginBottom: theme?.spacing.sl,
      marginLeft: 2,
      //   width: maxInputFieldWidth / 2 + 20,
      minWidth: 100,
      justifyContent: 'center',
      paddingLeft: theme?.spacing.m,
      paddingRight: theme?.spacing.m,
    },
    icom: {
      color: theme?.colors?.primaryVariant,
      paddingRight: theme?.spacing.m,
    },
    label: {
      fontSize: theme?.fonts.s,
      marginBottom: theme?.spacing.ss,
      color: theme?.colors?.reghgl,
      justifyContent: 'center',
      alignSelf: 'center',
    },
  });
  const [showMessage, setShowMessage] = useState(false);
  function onPressLocal() {
    onPress();
    setShowMessage(true);
    setTimeout((_) => {
      setShowMessage(false);
    }, 3000);
  }
  return (
    <View style={{flexDirection: 'column'}}>
      {showMessage && <Text style={localStyles.label}>{message}</Text>}
      <TouchableHighlight
        onPress={!disabled ? onPressLocal : null}
        style={localStyles.actionButtonContainer}
        underlayColor={theme?.colors?.secondaryLight}
        activeOpacity={0.6}>
        <View style={localStyles.actionButton}>
          {icon ? (
            <Icon
              name={icon}
              type="font-awesome"
              style={localStyles.icom}
              Component={TouchableHighlight}
              activeOpacity={0.6}
              underlayColor={theme?.colors?.iconTouchHighlight}
              size={20}
            />
          ) : null}
          <Text style={localStyles.actionButtonText}>{title}</Text>
        </View>
      </TouchableHighlight>
    </View>
  );
};

export const MyNumericInput = ({
  value,
  step,
  minValue,
  maxValue,
  onChange,
  editable,
  containerStyle,
}) => {
  const theme = React.useContext(MyContext)?.theme;
  const localStyles = StyleSheet.create({
    numericContainerStyle: {
      color: theme?.colors?.inputText,
      padding: 0,
      textAlign: 'center',
      textAlignVertical: 'center',
      marginLeft: 2,
      borderWidth: 1,
      borderRadius: 0,
      backgroundColor: theme?.colors?.inputBackgound,
      fontSize: theme?.fonts.m,
    },
    numericInputStyle: {
      fontSize: theme?.fonts.s,
    },
  });
  return (
    <NumericInput
      totalWidth={theme?.screenWidth / 6}
      leftButtonBackgroundColor="lightgrey"
      rightButtonBackgroundColor="lightgrey"
      upDownButtonsBackgroundColor="lightgrey"
      rounded={false}
      type="plus-minus"
      step={step}
      minValue={minValue}
      maxValue={maxValue}
      totalHeight={30}
      onChange={onChange}
      containerStyle={localStyles.numericContainerStyle}
      inputStyle={localStyles.numericInputStyle}
      value={value}
      editable={editable ? editable : true}
      separatorWidth={0}
      placeholderTextColor={theme?.colors?.placeholder}
    />
  );
};
export const MySlider = ({
  value,
  step,
  minValue,
  maxValue,
  onChange,
  editable,
  containerStyle,
  business,
}) => {
  const theme = React.useContext(MyContext)?.theme;
  const countryDetail= React.useContext(MyContext)?.countryDetail;
  const localStyles = StyleSheet.create({
    track: {
      height: 10,
      borderRadius: 5,
      backgroundColor: '#d0d0d0',
    },
    thumb: {
      width: 10,
      height: 30,
      borderRadius: 5,
      backgroundColor: 'grey',
    },
  });
  return (
    <View style={[containerStyle, {flex: 1, justifyContent: 'center'}]}>
      <Slider
        value={value}
        onValueChange={onChange}
        maximumValue={maxValue}
        minimumValue={minValue}
        step={step}
        style={[{borderWidth: 0}]}
        trackStyle={localStyles.track}
        thumbStyle={localStyles.thumb}
        minimumTrackTintColor="#eecba8"
      />
      <Text
        style={{
          fontSize: 8,
          padding: 1,
          marginRight: 3,
          alignSelf: 'flex-end',
        }}>
        Approx. Price ({countryDetail.currency})
        {business?.profile?.priceRangeDenom &&
        business?.profile?.priceRangeDenom !== 1
          ? 'in ' + business?.profile?.priceRangeDenom + "'s "
          : ''}
        : {value !== 0 ? value : business ? 'All' : ''}
      </Text>
    </View>
  );
};
export const MyButton = ({
  title,
  onPress,
  disabled,
  size,
  analyticsAttrs,
  analyticsAction,
}) => {
  const theme = React.useContext(MyContext)?.theme;
  const localStyles = StyleSheet.create({
    button: {
      fontSize: size ? size : 18,
      padding: 5,
      color: theme?.colors?.primary,
    },
  });
  return (
    <Button
      title={title}
      type="clear"
      containerStyle={localStyles.button}
      titleStyle={localStyles.button}
      raised={true}
      onPress={onPress}
      disabled={disabled ? true : false}
      data-amplify-analytics-on="click"
      data-amplify-analytics-name={analyticsAction}
      data-amplify-analytics-attrs={analyticsAttrs}
    />
  );
};
export const MySearchBar = ({
  placeholder,
  value,
  onChangeText,
  onClear,
  showLoading,
  onSubmitEditing,
  onPress,
  fuse,
  pageState,
  showAutoComplete,
  setPageState,
  width,
  key,
  alignSelf,
}) => {
  const {theme} = React.useContext(MyContext);
  const localStyles = StyleSheet.create({
    searchbarContainer: {
      flex: 1,
      backgroundColor: theme?.colors?.searchBarBackgroundColor,
      padding: 0,
      borderBottomColor: 'transparent',
      borderTopColor: 'transparent',
      margin: 10,
      borderTopWidth: 0,
      borderBottomWidth: 0,
      borderRadius: 15,
      borderWidth: 0,
      borderColor: 'transparent',
      alignSelf: alignSelf ? alignSelf : 'stretch',
      width: width,
    },
    ipsearchbar: {
      color: theme?.colors?.Primary,
      fontSize: 15,
      marginLeft: 0,
      borderColor: 'transparent',
      borderWidth: 0,
      paddingLeft: 20,
    },
    icsearchbar: {
      height: 40,
      backgroundColor: theme?.colors?.searchBarBackgroundColor,
      borderColor: 'transparent',
      borderWidth: 0,
    },
    containerStyle: {
      flex: 1,
      margin: 10,
      borderWidth: 1,
      borderColor: 'lightgrey',
      alignSelf: 'stretch',
      backgroundColor: 'transparent',
    },
    inputContainerStyle: {
      height: 40,
      backgroundColor: 'transparent',
    },
    listContainerStyle: {
      flex: 0,
      borderWidth: 0,
      backgroundColor: 'transparent',
    },
    listStyle: {
      flex: 0,
      borderWidth: 0,
      backgroundColor: 'transparent',
    },
    itemText: {
      fontSize: 15,
      paddingTop: 5,
      paddingBottom: 5,
      margin: 2,
    },
  });
  return (
    <>
      {true ? (
        <SearchBar
          returnKeyType='done' 
          round
          lightTheme
          containerStyle={[localStyles.searchbarContainer]}
          inputStyle={[localStyles.ipsearchbar]}
          inputContainerStyle={[localStyles.icsearchbar]}
          placeholder={placeholder}
          onChangeText={onChangeText}
          value={value}
          showLoading={showLoading}
          onClear={onClear}
          onSubmitEditing={onSubmitEditing}
          onEndEditing={onSubmitEditing}
          placeholderTextColor={theme?.colors?.placeholder}
          underlineColorAndroid={theme?.colors?.underlineColorAndroid}
          key={key}
          searchIcon={{
            name: 'search',
            type: 'font-awesome',
            color: theme?.colors?.icon,
            onPress: onSubmitEditing,
          }}
        />
      ) : (
        <View
          style={{width: width ? width : '94%', border: 1, borderRadius: 30}}>
          <Autocomplete
            data={fuse?.search(value).map((item) => {
              return item.item.name;
            })}
            keyExtractor={(item, index) => {
              return item;
            }}
            renderItem={({item, i}) => (
              <TouchableOpacity
                style={{height: 30}}
                onPress={() => {
                  onSubmitEditing(item);
                  setPageState((prevState) => {
                    return {
                      ...prevState,
                      hideResults: true,
                      errorMessage: '',
                    };
                  });
                }}>
                <Text style={localStyles.itemText}>{item}</Text>
              </TouchableOpacity>
            )}
            containerStyle={localStyles.containerStyle}
            inputContainerStyle={localStyles.inputContainerStyle}
            listContainerStyle={localStyles.listContainerStyle}
            listStyle={localStyles.listStyle}
            onChangeText={(text) => {
              onChangeText(text);
              setPageState((prevState) => {
                return {
                  ...prevState,
                  hideResults: false,
                  errorMessage: '',
                };
              });
            }}
            hideResults={pageState.hideResults}
            placeholder={placeholder}
            onClear={onClear}
            value={value}
            onSubmitEditing={(text) => {
              onSubmitEditing(text);
              setPageState((prevState) => {
                return {
                  ...prevState,
                  hideResults: true,
                  errorMessage: '',
                };
              });
            }}
          />
        </View>
      )}
    </>
  );
};
export const MySeparator = () => {
  const {theme} = React.useContext(MyContext);
  const localStyles = StyleSheet.create({
    searchListSeparator: {
      height: 1,
      width: '94%',
      backgroundColor: theme?.colors?.separator,
      marginLeft: '3%',
    },
  });
  return <View style={localStyles.searchListSeparator} />;
};

export const MyText = ({children, type, index}) => {
  const {theme} = useContext(MyContext);
  const dynSearchScreenText = (indexInternal) => {
    return {
      fontSize:
        14 +
        (type === 'reg' || type === 'reghgl'
          ? 0
          : type === 'med'
          ? 4
          : type === 'regl'
          ? 2
          : type === 'lrg'
          ? 6
          : 0),
      flexWrap: 'wrap',
      paddingTop: type === 'carousel' ? 0 : 10,
      color:
        type === 'reghgl'
          ? theme?.colors.reghgl
          : type === 'carousel'
          ? theme?.colors?.whiteMessage
          : theme?.colors?.secondary,
    };
  };
  return <Text style={dynSearchScreenText(index)}>{children}</Text>;
};
export const MyCountryPicker = ({
  selectedValue,
  business,
  onChangeText,
  returnedAttribute,
  label,
  isForPhone,
  width,
}) => {
  const theme = React.useContext(MyContext)?.theme;
  const localStyles = StyleSheet.create({
    rowContainer: {
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      width: width?width: maxInputFieldWidth - 20,
      marginLeft: '10px',
    },
    picker: {
      height: 48, borderColor: 'black', marginTop: 0, backgroundColor: 'transparent', 
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
      borderTopRightRadius: isForPhone?0:0,
      borderBottomRightRadius: isForPhone?0:0,
      borderTopWidth: 1,
      borderBottomWidth: 1,
      borderLeftWidth: 1, 
      borderRightWidth: isForPhone?0:1,
      fontSize: theme?.fonts.l,
      color: theme?.colors?.secondary,
      textAlign: 'center',
      width:'100%',
    },
    itemStyle:{
      textAlign: 'center',
    },
    label: {
      fontSize: theme?.fonts.s,
      marginBottom: theme?.spacing.ss,
    },
  });
  return (
    <View style={localStyles.rowContainer}>
      {label && <Text style={localStyles.label}>{label}</Text>}
      <Picker
        selectedValue={selectedValue}
        style={localStyles.picker}
        itemStyle={localStyles.itemStyle}
        onValueChange={ (value, index) => onChangeText(returnedAttribute, value)}>
        {
        (business?.profile?.shipping?.allowedCountries?business?.profile?.shipping?.allowedCountries:[{country: SELECT_YOUR_COUNTRY,phoneNumberPrefix:'',}]).map(
          (element) => {
            return (
              <Picker.Item style={localStyles.itemStyle}
                label={(isForPhone)?(element['country']===SELECT_YOUR_COUNTRY?'Select Country':element['country']):element[returnedAttribute]}
                value={element[returnedAttribute]}
                key={element.key}
              />
            );
          },
        )}
      </Picker>
    </View>
  );
};

export const MyButtonGroup = ({buttons, onPress, selectedIndex, width}) => {
  const theme = React.useContext(MyContext)?.theme;
  const localStyles = StyleSheet.create({
    textStyle: {
      fontSize: theme?.fonts.m,
    },
    selecttedTextStyle: {
      fontSize: theme?.fonts.m,
      color: theme?.colors?.whiteMessage,
    },
    buttonStyle: {
      backgroundColor: theme?.colors?.primaryVariant2,
      paddingLeft: 1,
    },
    selectedButtonStyle: {
      backgroundColor: theme?.colors?.primaryLight2,
    },
    containerStyle: {
      padding: 2,
      height: 40,
      margin: 5,
      width: width ? width : '100%',
      alignSelf: 'center',
    },
  });
  return (
    <ButtonGroup
      onPress={onPress}
      setOpacityTo={() => 0.01}
      buttons={buttons}
      Component={TouchableHighlight}
      activeOpacity={0.6}
      underlayColor={theme?.colors?.touchHighlight}
      selectedIndex={selectedIndex}
      buttonStyle={localStyles.buttonStyle}
      selectedTextStyle={localStyles.selecttedTextStyle}
      containerStyle={localStyles.containerStyle}
      textStyle={localStyles.textStyle}
      selectedButtonStyle={localStyles.selectedButtonStyle}
    />
  );
};
export const MyLinks = ({business, curWebView, setCurWebView}) => {
  const {theme} = useContext(MyContext);
  let myCftUrl = window.location.hostname === 'localhost' ? '' : cloudFrontURL;
  return (
    <>
      <HTML
        source={{
          uri:
            curWebView === 'pp'
              ? myCftUrl + business?.profile?.privacyPolicyURL
              : curWebView === 'tc'
              ? myCftUrl + business?.profile?.termsAndConditionsURL
              : curWebView === 'faq'
              ? myCftUrl + business?.profile?.faqURL
              : curWebView === 'rc'
              ? myCftUrl + business?.profile?.refundAndCancelationURL
              : null,
        }}
        contentWidth={theme?.screenWidth}
        containerStyle={{
          width: isMobile ? theme?.screenWidth : theme.screenWidth * 0.6,
          backgroundColor: 'white',
          scrollEnabled: true,
          alignSelf: 'center',
          padding: 5,
        }}
      />
      <MyButton
        title="Close"
        onPress={() => {
          setCurWebView(null);
          window.scrollTo(0, 0);
        }}
      />
    </>
  );
};
export const MyAcceptence = ({setCurWebView}) => {
  const theme = React.useContext(MyContext)?.theme;
  const localStyles = StyleSheet.create({
    acceptenceWrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      padding: 5,
      marginTop: 15,
      flexWrap: 'wrap',
      width: isMobile ? '100%' : '100%',
    },
    infoMessage: {
      padding: 3,
      fontSize: 12,
      color: theme?.colors?.info,
      marginLeft: 0,
    },
    link: {
      padding: 2,
      fontSize: 12,
      color: theme?.colors?.primary,
      flexWrap: 'wrap',
    },
  });
  return (
    <View style={localStyles.acceptenceWrapper}>
      <Text style={[localStyles.infoMessage]}>
        By proceeding further you agree to accept our
      </Text>
      <Text style={localStyles.link} onPress={() => setCurWebView('tc')}>
        Terms and Conditions
      </Text>
      <Text style={[localStyles.infoMessage]}>and</Text>
      <Text style={localStyles.link} onPress={() => setCurWebView('pp')}>
        Privacy Policy
      </Text>
    </View>
  );
};
export const MyListMenu = ({data, onPress, searchText}) => {
  const {theme, user, business} = React.useContext(MyContext);
  const [selected, setSelected] = useState();
  useEffect(() => {
    if (!data?.includes(searchText)) {
      setSelected(null);
    }
  }, [data, searchText]);
  return (
    <FlatList
      data={data}
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
      renderItem={({item, index}) => {
        return (
          <Text
            style={{
              fontSize: theme?.fonts?.sm,
              color:
                selected === index
                  ? theme?.colors?.primary
                  : theme?.colors?.secondary,
            }}
            onPress={() => {
              onPress(index);
              setSelected(index);
            }}>
            {item}
          </Text>
        );
      }}
      bounces={false}
      keyExtractor={(item) => item}
      ItemSeparatorComponent={() => {
        return (
          <Text
            style={{
              fontSize: theme?.fonts?.sm,
              color: theme?.colors?.separator,
            }}>
            {'  '}|{'  '}
          </Text>
        );
      }}
      style={{borderTopWidth: 0, borderBottomWidth: 0, margin: 5}}
      horizontal={true}
    />
  );
};
export const MyCarousal = ({
  onPress,
  getImageUrl,
  title,
  data,
  getItemText,
  height,
  itemWidth,
  showArrows,
  loop,
  iconSize,
  sliderWidth,
  getItemHeading,
  customRenderItem,
  vertical,
  itemHeight,
  sliderHeight,
  round,
  inactiveSlideOpacity,
  inactiveSlideScale,
  getItemDescription,
  showPagination,
  layout,
}) => {
  const carouselRef = useRef(null);
  const {theme, user, business} = React.useContext(MyContext);
  const [activeIndex, setActiveIndex] = useState();

  const localStyles = StyleSheet.create({
    dealHeader: {
      fontSize: 27,
      color: theme?.colors?.info,
      height: 50,
      textAlign: 'center',
      textAlignVertical: 'center',
      marginTop: theme?.spacing.m,
      alignSelf: 'center',
    },

    carousel: {
      backgroundColor: theme?.colors?.transparent,
      paddingTop: 0,
    },
    item: {
      marginBottom: Platform.select({ios: 0, android: 0}), // 1 for android Prevent a random Android rendering issue
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      backgroundColor: theme?.colors.transparent,
    },
    image: {
      marginBottom: Platform.select({ios: 0, android: 0}), // 1 for android Prevent a random Android rendering issue
      backgroundColor: theme?.colors.transparent,
      height: height
        ? height
        : HeightByWidth *
          (itemWidth
            ? itemWidth
            : isMobile
            ? theme?.screenWidth / 1.5
            : (theme?.screenWidth - (showArrows ? 48 * 2 : 0)) / 3),
      width: itemWidth
        ? itemWidth
        : isMobile
        ? theme?.screenWidth / 1.5
        : (theme?.screenWidth - (showArrows ? 48 * 2 : 0)) / 3,
      borderTopLeftRadius: round ? '70%' : 8,
      borderTopRightRadius: round ? '70%' : 8,
      borderBottomLeftRadius: round ? '70%' : 8,
      borderBottomRightRadius: round ? '70%' : 8,
    },
    text: {
      fontSize: theme?.fonts.l,
      margin: 5,
      alignSelf: 'center',
    },
    description: {
      fontSize: theme?.fonts.l,
      margin: 5,
      alignSelf: 'center',
      fontStyle: 'italic',
    },
    heading: {
      fontSize: theme?.fonts.xl,
      margin: 5,
      alignSelf: 'center',
      fontWeight: 'bold',
    },
    dotStyle: {
      width: 10,
      height: 10,
      borderRadius: 5,
      marginHorizontal: 8,
      backgroundColor: theme.colors.primary,
    },
  });
  const renderItem = ({item, index}, parallaxProps) => {
    return (
      <TouchableOpacity onPress={() => onPress(item, index, carouselRef)}>
        {getItemHeading(item) && (
          <Text style={localStyles.heading}>{getItemHeading(item)}</Text>
        )}
        <View style={localStyles.item}>
          {getImageUrl(item) && (
            <Image
              source={{uri: getImageUrl(item)}}
              style={[localStyles.image]}
              resizeMode="contain"
            />
          )}
          <Text style={localStyles.text}>{getItemText(item)}</Text>
          {getItemDescription(item) && (
            <Text style={localStyles.description}>
              {getItemDescription(item)}
            </Text>
          )}
        </View>
      </TouchableOpacity>
    );
  };
  return (
    <View style={[localStyles.carousel, {zIndex: 0}]}>
      {business?.profile.categories && (
        <>
          {title && <Text style={[localStyles.dealHeader]}>{title}</Text>}
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            {!vertical && showArrows && !isMobile && (
              <MyIcon
                name="angle-left"
                style={[{width: 25, height: 25, alignSelf: 'center'}]}
                type="font-awesome"
                color={theme?.colors?.icon}
                size={iconSize}
                onPress={() => carouselRef.current.snapToNext()}
              />
            )}
            <Carousel
              ref={carouselRef}
              sliderWidth={
                (sliderWidth?sliderWidth : theme?.screenWidth) - (showArrows && !isMobile ? 25 * 2 : 0)
              }
              //sliderHeight={screenHeight / 2}
              itemWidth={
                itemWidth
                  ? itemWidth
                  : isMobile
                  ? theme?.screenWidth / 1.5
                  : (theme?.screenWidth - (showArrows ? 48 * 2 : 0)) / 3
              }
              //itemHeight={((4 / 3) * screenHeight) / 2}
              sliderHeight={sliderHeight}
              itemHeight={itemHeight}
              data={data}
              renderItem={customRenderItem ? customRenderItem : renderItem}
              hasParallaxImages={true}
              autoplay={loop === false ? loop : true}
              autoplayDelay={1000}
              autoplayInterval={4000}
              activeSlideOffset={20}
              enableSnap={loop}
              loop={loop}
              vertical={vertical}
              activeSlideAlignment="center"
              inactiveSlideOpacity={
                inactiveSlideOpacity ? inactiveSlideOpacity : 0.7
              }
              inactiveSlideScale={inactiveSlideScale ? inactiveSlideScale : 0.9}
              firstItem={2}
              loopClonesPerSide={20}
              onSnapToItem={(index) => setActiveIndex(index)}
              layout={layout?layout: "default"}
            />
            {!vertical && showArrows && !isMobile && (
              <MyIcon
                name="angle-right"
                style={[{alignSelf: 'center'}]}
                size={iconSize}
                type="font-awesome"
                color={theme?.colors?.icon}
                onPress={() => carouselRef.current.snapToPrev()}
              />
            )}
          </View>
          {showPagination && !isMobile && (
            <Pagination
              dotsLength={data.length}
              activeDotIndex={activeIndex}
              containerStyle={{
                backgroundColor: 'transparent',
              }}
              dotStyle={localStyles.dotStyle}
              inactiveDotStyle={{
                backgroundColor: theme.colors.secondary,
              }}
              inactiveDotOpacity={0.4}
              inactiveDotScale={0.6}
            />
          )}
        </>
      )}
    </View>
  );
};
