import { View } from 'react-native';
import { gql } from 'apollo-boost';

import React, { useEffect } from 'react';
import { useState, useContext } from 'react';
import { Image, StyleSheet, Text } from 'react-native';
import { getFullPageURL, uploadPrefix } from '../../utilities/Constants';

import { ActionButton, InputText, MyText, InputTextArea } from '../ui/elements';
import uuid from 'react-native-uuid';
import { FileUploader } from '../fileUploader/FileUploader.web';

import { MyContext } from '../../styles/styles';
import { updateItems, deleteItems, createItems } from '../../graphql/mutations';
import { API, Storage } from 'aws-amplify';
import { CheckBox } from 'react-native-elements';

export const EditItem = ({
  id,
  transitionState,
  setIsImageViewVisible,
  setImages,
  setImageIndex,
}) => {
  const { business, user, theme } = useContext(MyContext);
  const [item, setItemInternal] = useState();
  const [message, setMessageInternal] = useState();
  const [image, setImageInternal] = useState();

  useEffect(() => {
    var reader = new FileReader();
    // Closure to capture the file information.
    reader.onload = function (e) {
      //get the base64 url
      var base64URL = e.target.result;
      //print to console
      console.log(base64URL);
    };
    if (id) {
      getItem(id);
    }
  }, [id]);

  const setMessage = (msg) => {
    setMessageInternal(msg);
    setTimeout((_) => {
      setMessageInternal('');
    }, 3000);
  };

  const getItem = async (id) => {
    await API.graphql({
      query: gql`
        query getItems($id: ID!, $businessId: ID!) {
          getItems(id: $id, businessId: $businessId) {
            id
            title
            price
            internationalPrice
            weight
            displayDimensions
            category
            benefits
            unit
            allowedSellingUnits
            ingredients
            usageInstructions
            subCategory
            parent
            lengthx
            breadth
            height
            quantity
            imageKeys
            description
            tags
            labels
            unitMeasure
            businessId
            exemptFromShippingChanrges
          }
        }
      `,
      variables: { id: id, businessId: user?.businessId },
    })
      .then((result) => {
        setItemInternal(result.data.getItems);
      })
      .catch((e) => {
        setMessage(e?.errors[0]?.message);
      });
  };
  const setItem = (key, value) => {
    setItemInternal((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };
  function setImage(content) {
    getBase64(content);
  }
  const addUpdateItem = async (itemToUpdate) => {
    if (!item.id) {
      item.id = uuid.v4();
      console.log(' creating item:', item, 'item.id:', item.id);
      await API.graphql({
        query: createItems,
        variables: { input: item },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })
        .then((result) => {
          console.log(result);
          setMessage('SUCCESS: Created item id: ' + result.data.createItems.id);
          setItem(result.data.createItems);
        })
        .catch((e) => {
          let localMessage = e?.errors[0]?.message;
          console.log('Error during update', item, e, localMessage);
          setMessage(localMessage);
        });
    } else {
      await API.graphql({
        query: updateItems,
        variables: { input: itemToUpdate },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })
        .then((result) => {
          console.log('updateItem: ', itemToUpdate);
          console.log('result', result);
          setMessage('SUCCESS: Updated item id: ' + result.data.updateItems.id);
          console.log(
            'SUCCESS: Updated item id: ' + result.data.updateItems.id,
          );
          setImage(null);
        })
        .catch((e) => {
          let localMessage = e?.errors[0]?.message;
          console.log('Error during update', item, e, localMessage);
          setMessage(localMessage);
        });
    }
  };
  function getBase64(files) {
    let results = [];
    files.forEach((file) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        console.log(reader.result);
        results.push(reader.result);
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    });
    setImageInternal(results);
  }
  async function uploadImageAndSaveItem() {
    try {
      if (!id && !image) {
        setMessage('Please add Photo. Photo is required to load new item');
        return;
      }
      let imageKeys = [];
      image.forEach((img) => {
        let imageKey = uuid.v4();
        imageKeys.push(imageKey);
        storeInS3(imageKey, img);
      });
      setItem('imageKeys', imageKeys);
      item.imageKeys = imageKeys;
      addUpdateItem(item);
    } catch (err) {
      console.log('error creating/updatinng item:', err);
      setMessage(err);
    }
  }
  async function storeInS3(imageKey, image) {
    await Storage.put(
      user?.businessId + '/' + uploadPrefix + imageKey,
      image,
      { level: 'public/upload', useAccelerateEndpoint: true },
    ).then((result) => {
      console.log('Stored file in S3');
    });
  }
  async function deleteFullItem() {
    await API.graphql({
      query: deleteItems,
      variables: { input: { id: id, businessId: user?.businessId } },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    })
      .then((result) => {
        setMessage('Successs. ItemId Deleted: ', result.data.deleteItems.id);
      })
      .catch((err) => {
        console.log('error deleting item:', err);
        setMessage(err);
      });
  }
  const localStyles = StyleSheet.create({
    container: {
      flexDirection: 'column',
    },
    image: {
      width: 100,
      height: 74,
    },
    inputsContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginTop: 10,
      width: '100%',
    },
    buttonsContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'wrap',
      marginTop: 25,
      width: theme?.screenWidth,
      justifyContent: 'center',
    },
    imageContainer: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    imageContainer2: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    checkBoxStyle: {
      fontSize: 12,
      padding: 0,
      borderBottomColor: 'transparent',
      borderTopColor: 'transparent',
      backgroundColor: 'transparent',
      fontWeight: 'normal',
    },
    containerStyle: {
      backgroundColor: 'transparent', borderColor: 'black', height: 48,
      marginTop: 15
    }
  });
  return (
    <>
      {user && user?.isPrivileged && user?.businessId === business?.id && (
        <View style={{ marginLeft: 0, flexDirection: 'column' }}>
          <MyText type="reg">{message}</MyText>
          <View style={localStyles.container}>
            <View style={localStyles.imageContainer}>
              <View style={localStyles.imageContainer2}>
                <Image
                  style={localStyles.image}
                  resizeMode="cover"
                  source={{
                    uri:
                      item?.imageKeys?.length > 0
                        ? getFullPageURL(business?.id, item?.imageKeys[0])
                        : image,
                  }}
                />
                <Image
                  style={localStyles.image}
                  resizeMode="cover"
                  source={{
                    uri:
                      item?.imageKeys?.length > 1
                        ? getFullPageURL(business?.id, item?.imageKeys[1])
                        : image,
                  }}
                />
                <Image
                  style={localStyles.image}
                  resizeMode="cover"
                  source={{
                    uri:
                      item?.imageKeys?.length > 2
                        ? getFullPageURL(business?.id, item?.imageKeys[2])
                        : image,
                  }}
                />
                <Image
                  style={localStyles.image}
                  resizeMode="cover"
                  source={{
                    uri:
                      item?.imageKeys?.length > 3
                        ? getFullPageURL(business?.id, item?.imageKeys[3])
                        : image,
                  }}
                />
                <Image
                  style={localStyles.image}
                  resizeMode="cover"
                  source={{
                    uri:
                      item?.imageKeys?.length > 4
                        ? getFullPageURL(business?.id, item?.imageKeys[4])
                        : image,
                  }}
                />
              </View>
              <View style={{ height: 40, width: 200 }}>
                <FileUploader setImage={setImage} />
              </View>
              <Text style={{ height: 30 }} content>
                Item # {item?.id}
              </Text>
            </View>
            <View style={localStyles.inputsContainer}>
              <InputText
                name="title"
                placeholder="Title"
                onChangeText={setItem}
                value={item?.title}
                label="*Title"
              />
              <InputText
                name="displayDimensions"
                placeholder="DisplayDimensions"
                onChangeText={setItem}
                value={item?.displayDimensions}
                label="*DisplayDimensions"
                width="auto"
              />
              <InputText
                name="price"
                placeholder="Price"
                onChangeText={setItem}
                value={item?.price}
                label="*Price"
                width="auto"
              />
              <InputText
                name="internationalPrice"
                placeholder="Price"
                onChangeText={setItem}
                value={item?.internationalPrice}
                label="*International Price"
                width="auto"
              />

              <InputText
                name="weight"
                placeholder="Weight"
                onChangeText={setItem}
                value={item?.weight}
                label="*Weight"
                width="auto"
              />
              <InputText
                name="category"
                placeholder="Category"
                onChangeText={setItem}
                value={item?.category}
                label="*Category"
                width="auto"
              />
              <InputText
                name="subCategory"
                placeholder="SubCategory"
                onChangeText={setItem}
                value={item?.subCategory}
                label="*SubCategory"
                width="auto"
              />
              <InputText
                name="unit"
                placeholder="Unit"
                onChangeText={setItem}
                value={item?.unit}
                label="Unit"
                width="auto"
              />
              <InputText
                name="allowedSellingUnits"
                placeholder="AllowedSellingUnits"
                onChangeText={setItem}
                value={item?.allowedSellingUnits}
                label="AllowedSellingUnits"
                width="auto"
              />
              <InputText
                name="parent"
                placeholder="Parent"
                onChangeText={setItem}
                value={item?.parent}
                label="Parent"
                width="auto"
              />
              <InputText
                name="lengthx"
                placeholder="Lengthx"
                onChangeText={setItem}
                value={item?.lengthx}
                label="Lengthx"
                width="auto"
              />
              <InputText
                name="breadth"
                placeholder="Breadth"
                onChangeText={setItem}
                value={item?.breadth}
                label="Breadth"
                width="auto"
              />
              <InputText
                name="height"
                placeholder="Height"
                onChangeText={setItem}
                value={item?.height}
                label="Height"
                width="auto"
              />
              <InputText
                name="unitMeasure"
                placeholder="UnitMeasure"
                onChangeText={setItem}
                value={item?.unitMeasure}
                label="UnitMeasure"
                width="auto"
              />
              <InputText
                name="labels"
                placeholder="Labels"
                onChangeText={setItem}
                value={item?.labels}
                label="Labels"
                width="auto"
              />

              <InputText
                name="tags"
                placeholder="Tags"
                onChangeText={setItem}
                value={item?.tags}
                label="Tags"
              />
              <CheckBox
                center
                textStyle={localStyles.checkBoxStyle}
                containerStyle={localStyles.containerStyle}
                checkedColor="grey"
                title="Shipping Exempt"
                checkedIcon="dot-circle-o"
                uncheckedIcon="circle-o"
                checked={item?.exemptFromShippingChanrges}
                onPress={(value) => {
                  setItem('exemptFromShippingChanrges', !item?.exemptFromShippingChanrges);
                }}
              />
              <InputTextArea
                name="benefits"
                placeholder="Benefits"
                onChangeText={setItem}
                value={item?.benefits}
                label="*Benefits"
              />  
              <InputTextArea
                name="description"
                placeholder="description"
                onChangeText={setItem}
                value={item?.description}
                label="*Description"
              />
              <InputTextArea
                name="ingredients"
                placeholder="Ingredients"
                onChangeText={setItem}
                value={item?.ingredients}
                label="*Ingredients"
              />
              <InputTextArea
                name="usageInstructions"
                placeholder="Usage Instructions"
                onChangeText={setItem}
                value={item?.usageInstructions}
                label="*Usage Instructions"
              />                          
            </View>
            <View style={localStyles.buttonsContainer}>
              {item?.id ? (
                <>
                  <ActionButton
                    title="Update"
                    onPress={() => {
                      image ? uploadImageAndSaveItem() : addUpdateItem(item);
                    }}
                  />
                  <ActionButton
                    title="Delete"
                    onPress={() => {
                      deleteFullItem();
                    }}
                  />
                  <ActionButton
                    onPress={() => {
                      setItem('id', null);
                      setItem('imageKeys', []);
                    }}
                    title="Add New"
                  />
                </>
              ) : (
                <ActionButton
                  onPress={() => {
                    uploadImageAndSaveItem();
                  }}
                  title="Save"
                />
              )}
            </View>
          </View>
        </View>
      )}
    </>
  );
};
