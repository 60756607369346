import {cloudFrontURL} from './profile';
import I18n from './../I18n/I18n';

export const access = {level: 'public', download: false};
const resized = 'resized/';
const gallery = 'gallery/';
const fullsize = 'fullsize/';
const fullpage = 'fullpage/';
const categories = 'categories/';
export const LOCAL = 'Local';
export const OUT_STATION = 'OutStation';
export const SPECIAL_LOCATION = 'SpecialLocation';
export const HISTORY_PREFIX = 'History_';
export const USER_STATE_PREFIX = 'UserState_';
export const SELECT_YOUR_COUNTRY = 'Select Country';
export const PHONE_NUMBER_LENGTH = 10;
const emailRegex = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;

//Buttons to show
export const Status = [
  'Pending Payment',
  'PickUp Requested',
  'Ready for PickUp',
  'Processing',
  'Delivered',
  'Cancellation Requested',
  'Cancelled',
  'Return Requested',
  'Returned',
];
export const StatusMap = {
  PENDING_PAYMENT: 'Pending Payment',
  PICKUP_REQUESTED: 'PickUp Requested',
  READY_FOR_PICKUP: 'Ready for PickUp',
  PROCESSING: 'Processing',
  DELIVERED: 'Delivered',
  PAID: 'PAID',
  CANCELATION_REQUESTED: 'Cancellation Requested',
  CANCELED: 'Cancelled',
  RETURN_REQUESTED: 'Return Requested',
  RETURNED: 'Returned',
  NEW: 'New',
};
export const ActionMap = {
  REQUEST_RETURN: 'Request Return',
  REQUEST_CANCELATION: 'Request Cancelation',
  MAKE_PAYMENT: 'Make Payment',
  MARK_RETURNED: 'Mark Returned',
  MARK_CANCELED: 'Mark Canceled',
  MARK_PICKED_UP: 'Mark PickedUp',
  MARK_PAID: 'Mark Paid',
  MARK_DELIVERED: 'Mark Delivered',
  MARK_READY_FOR_PICKUP: 'Mark Ready for PickUp',
  DENY_CANCELATION: 'Deny Cancelation',
};
export const TRANSLATIONS = {
  'Request Cancelation': 'Cancellation Requested',
  'Mark Delivered': 'Delivered',
  'Mark Canceled': 'Cancelled',
  'Mark PickedUp': 'PickedUp',
  'Mark Ready for PickUp': 'Ready for PickUp',
  'Request Return': 'Return Requested',
  'Mark Paid': 'PAID',
  'Mark Returned': 'Returned',
};
export const CUSTOMER_ORDER_ACTIONS = {
  [StatusMap.PENDING_PAYMENT]: [
    ActionMap.REQUEST_CANCELATION,
    ActionMap.MAKE_PAYMENT,
  ],
  [StatusMap.READY_FOR_PICKUP]: [ActionMap.REQUEST_CANCELATION],
  [StatusMap.PROCESSING]: [ActionMap.REQUEST_CANCELATION],
  [StatusMap.CANCELATION_REQUESTED]: [],
  [StatusMap.CANCELED]: [],
  [StatusMap.DELIVERED]: [ActionMap.REQUEST_RETURN],
  [StatusMap.RETURN_REQUESTED]: [],
  [StatusMap.RETURNED]: [],
};

export const ALL_PRODUCTS = 'All Products';

export const PRIVIILEGED_ORDER_ACTIONS = {
  [StatusMap.PENDING_PAYMENT]: [
    ActionMap.REQUEST_CANCELATION,
    ActionMap.MARK_PAID,
  ],
  [StatusMap.PICKUP_REQUESTED]: [ActionMap.MARK_READY_FOR_PICKUP],
  [StatusMap.READY_FOR_PICKUP]: [
    ActionMap.MARK_DELIVERED,
    ActionMap.MARK_CANCELED,
    ActionMap.MARK_PICKED_UP,
  ],
  [StatusMap.PROCESSING]: [
    ActionMap.REQUEST_CANCELATION,
    ActionMap.MARK_CANCELED,
    ActionMap.MARK_DELIVERED,
    ActionMap.MARK_RETURNED,
    ActionMap.MARK_READY_FOR_PICKUP,
    ActionMap.MARK_PICKED_UP,
  ],
  [StatusMap.CANCELATION_REQUESTED]: [
    ActionMap.MARK_CANCELED,
    ActionMap.DENY_CANCELATION,
  ],
  [StatusMap.CANCELED]: [],
  [StatusMap.DELIVERED]: [ActionMap.REQUEST_RETURN],
  [StatusMap.RETURN_REQUESTED]: [ActionMap.MARK_RETURNED],
  [StatusMap.RETURNED]: [],
};

export const FEEDBACK_TYPE = {
  SUGGESTION: 'SUGGESTION',
  ENQUIRY: 'ENQUIRY',
  ISSUE: 'ISSUE',
};
export const MYSTORAGE_KEY_PREFIX = '@MyStorage:';
export const CART_SUFFIX = ':cart';
export const ACTIVE_CART_ORDER = ':activeCartOrder';

export const USER_EVENTS = {
  SIGN_UP: 'SIGN-UP',
  SIGN_IN: 'SIGN-IN',
  SIGN_UP_FAILURE: 'SIGN-UP-FAILURE',
  SIGN_IN_FAILURE: 'SIGN-IN-FAILURE',
  FORGOT_PWD_SUCCESS: 'FORGOT_PWD_SUCCESS',
  FORGOT_PWD_FAILURE: 'FORGOT_PWD_SUCCESS',
  CANCEL_ORDER: 'CANCEL_ORDER',
  PLACE_ORDER: 'PLACE_ORDER',
  PLACE_ORDER_FAILURE: 'PLACE_ORDER_FAILURE',
  APP_ERROR: 'APP_ERROR',
  UPDATE_ORDER_FAILUR: 'UPDATE_ORDER_FAILURE',
  UPDATE_ORDER: 'UPDATE_ORDER_FAILURE',
  ADD_ITEM: 'ADD_ITEM',
  UPDATE_ITEM: 'UPDATE_ITEM',
  ADD_ITEM_FAILURE: 'ADD_ITEM_FAILURE',
  PAGE_VIEW: 'PAGE_VIEW',
  CONFIRM_SIGNUP: 'CONFIRM_SIGNUP',
  CONFIRM_SIGNUP_FAILURE: 'CONFIRM_SIGNUP_FAILURE',
  PAYMENT_SUCCESS: 'PAYMENT_SUCCESS',
  PAYMENT_ABORTED: 'PAYMENT_ABORTED',
  PAYMENT_FAILURE: 'PAYMENT_FAILURE',
  PAYMENT_STARTED: 'PAYMENT_STARTED',
};

export const USER_STATE = {
  DEFAULT: 'LANDING',
  LANDING: 'LANDING',
  SEARCH: 'SEARCH',
  CART: 'CART',
  DETAIL: 'DETAIL',
  CHECKOUT: 'CHECKOUT',
  EDIT: 'EDIT',
  ORDERS: 'ORDERS',
  PROFILE: 'PROFILE',
  ORDER_DETAIL: 'ORDER_DETAIL',
  REVIEW: 'REVIEW',
  PAYMENT_SUCCESS: 'PAYMENT_SUCCESS',
  NONE: 'NONE',
};

export const getCategoryURL = (businessId, imageKey) => {
  return (
    cloudFrontURL +
    access.level +
    '/' +
    businessId +
    '/' +
    categories +
    imageKey
  );
};
export const getFullSizeURL = (businessId, imageKey) => {
  return (
    cloudFrontURL + access.level + '/' + businessId + '/' + fullsize + imageKey
  );
};

export const uploadPrefix = 'upload/';
export const getFullPageURL = (businessId, imageKey) => {
  return (
    cloudFrontURL + access.level + '/' + businessId + '/' + fullpage + imageKey
  );
};

export const getGalleryURL = (businessId, imageKey) => {
  return (
    cloudFrontURL + access.level + '/' + businessId + '/' + fullpage + imageKey
  );
};

export const getThumbnailURL = (businessId, imageKey) => {
  return (
    //  cloudFrontURL + access.level + '/' + businessId + '/' + resized + imageKey
    cloudFrontURL + access.level + '/' + businessId + '/' + resized + imageKey
  );
};
export const cleanPhoneNumber = (str) => {
  return ('' + str).replace(/\D/g, '');
};
export const validateEmail = (v)=>{
  let match = v.match(emailRegex);
  if(!match){
    return "Please enter valid email address";
  }else {
    return null;
  }
}
export const validateZipCode = (business, country, zipCode)=>{
  let err = null;
  business?.profile?.shipping?.allowedCountries.map(
    (element) => {
       if(element['country']===country && element['zipLength']!==zipCode?.length){
        err = I18n.t(country==="India"?'INVALID_PIN':'INVALID_ZIP');
       }
    }
  );
  return err;
}
export const CancelAllowedStatusCodesStrings = [
  'New Order',
  'Invoiced',
  'Ready To Ship',
  'Pickup Scheduled',
  'Pickup Rescheduled',
  'Out For Pickup',
  StatusMap.PENDING_PAYMENT,
  StatusMap.PICKUP_REQUESTED,
];

export const ReturnlAllowedStatusCodesStrings = [StatusMap.DELIVERED];
