import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';
import {NavigationContainer} from '@react-navigation/native';
import {Analytics, Auth as AmplifyAuth, Hub} from 'aws-amplify';
import * as React from 'react';
import {
  TouchableHighlight,
  StyleSheet,
  ImageBackground,
  Image,
  View,
} from 'react-native';
import FlashMessage from 'react-native-flash-message';
import Icon from 'react-native-vector-icons/FontAwesome';
import {MyStorage as AsyncStorage} from '../utilities/MyStorage';
import {constructUser, updateHistory, constructCountryDetails} from '../utilities/RetailClient';
import {
  MyContext,
  isDeskTopBrowser,
  percentScreenShow,
  baseTheme,
  styles,
} from '../styles/styles';
import {withBadge} from 'react-native-elements';
import {Auth} from './auth/Auth';
import HomeScreen from './HomeScreen';
import SearchScreen from './SearchScreen';
import Feedback from './feedback/Feedback';
import About from './about/About';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import {isMobile} from 'react-device-detect';
import {cloudFrontURL} from '../utilities/profile';
import {Dimensions} from 'react-native';
import {MyIcon} from './ui/icons';
import {
  CART_SUFFIX,
  HISTORY_PREFIX,
  USER_STATE,
  USER_STATE_PREFIX,
} from '../utilities/Constants';

import {
  CloudWatchLogsClient,
  AssociateKmsKeyCommand,
} from '@aws-sdk/client-cloudwatch-logs';
import config from './../../aws-exports';
import { Auth as awsAmplifyAuth} from 'aws-amplify';

let clientLoggingLmsKeyId =
  'arn:aws:kms:us-east-1:973185627585:key/2a3c6f8e-d4cb-489b-9ca8-dfbb918a3232';

let client = new CloudWatchLogsClient({
  region: config.aws_appsync_region,
  credentials: {
    accessKeyId: '',
    secretAccessKey: '',
  },
});

Analytics.enable();

Analytics.autoTrack('session', {
  enable: true,
  provider: 'AWSPinpoint',
});

Analytics.autoTrack('pageView', {
  enable: true,
  eventName: 'pageView',
  type: 'SPA',
  provider: 'AWSPinpoint',
  getUrl: () => {
    return window.location.origin + window.location.pathname;
  },
});

Analytics.autoTrack('event', {
  enable: true,
  events: ['click'],
  selectorPrefix: 'data-amplify-analytics-',
  provider: 'AWSPinpoint',
  attributes: {
    attr: 'attr',
  },
});

async function trackUserIdforPinpoint() {
  await AmplifyAuth.currentAuthenticatedUser()
    .then((user) => {
      const {attributes} = user?.attributes;
      Analytics.updateEndpoint({
        phone_number: user?.signInUserSession.idToken.payload.phone_number,
        channelType: 'SMS',
        optOut: 'NONE',
        userId: user?.attributes.sub,
        address: user?.signInUserSession.idToken.payload.address.formatted,
        attributes: attributes,
      });
    })
    .catch((e) => {
      console.log(e);
    });
}

window.onerror = (message, source, lineno, colno, error) => {
  console.log('windowOnError:', message);
  var params = {
    logEvents: [
      {
        message: 'test',
        timestamp: new Date().getTime(),
      },
    ],
    logGroupName: 'ebizclientlogs',
    logStreamName: 'ebizclientlogs',
    kmsKeyId: clientLoggingLmsKeyId,
  };
  const command = new AssociateKmsKeyCommand(params);
  /*
  //Commented as precaution an not needed as we have analytics logs
  client.send(command).then(
    (data) => {
      //console.log(data);
    },
    (err) => {
      console.log(err);
    },
  );
  */
  Analytics.record({
    name: 'clientError',
    attributes: {
      error: error,
      message: message,
      source: source,
      lineno: lineno,
      colno: colno,
    },
  });
  return true;
};

window.addEventListener('beforeunload', function (e) {
  let pageReloaded =
    window.performance
      .getEntriesByType('navigation')
      .map((nav) => nav.type)
      .includes('reload') ||
    window.performance
      .getEntriesByType('navigation')
      .map((nav) => nav.type)
      .includes('navigate');
  if (!pageReloaded) {
    AsyncStorage.clear();
    e.preventDefault();
    e.returnValue = '';
  }
});

//trackUserIdforPinpoint();

const Tab = createMaterialTopTabNavigator();

const App = () => {
  let navigation = null;
  const [user, setUser] = React.useState(null);
  const [badgeValue, setBadgeValue] = React.useState(0);
  const [business, setBusiness] = React.useState(null);
  const [countryDetail, setCountryDetail] = React.useState(null);

  const [theme, setTheme] = React.useState(() => {
    return {
      ...baseTheme,
      colors: {
        ...baseTheme?.colors,
      },
    };
  });
  const appJson = require('../app.json');

  React.useEffect(() => {
    Hub.listen('auth', (data) => {
      console.log('A new auth event has happened', data.payload.event);
      if (data.payload.event === 'signIn') {
        let usr=constructUser(data.payload.data);      
        setUser(usr);
      //  setCountryDetail(constructCountryDetails(usr.country,usr.mobileNumber));
      } else if (data.payload.event === 'signOut') {
        setUser(null);
        AsyncStorage.clear();
     //   setCountryDetail(constructCountryDetails(null,null));
        window.location.reload(true);
      }else if (data.payload.event === 'tokenRefresh') {
        awsAmplifyAuth.currentAuthenticatedUser()
        .then((updatedUser) => {
          let usr = constructUser(updatedUser); 
          setUser(usr);
    //      setCountryDetail(constructCountryDetails(usr.country,usr.mobileNumber));
        })
        .catch(() => {
          console.log('user not authenticated');
        });
      }
    });
    Hub.listen('cart', (data) => {
      console.log('A new cart event has happened');
      let raw = AsyncStorage.getItem(data.payload.bizId + CART_SUFFIX)?.items;
      setBadgeValue(raw ? raw?.length : 0);
    });
    Dimensions.addEventListener('change', () => {
      const {width: screenWidthInternal, height: screenHeightInternal} =
        Dimensions.get('window');
      let newWidth = isDeskTopBrowser
        ? screenWidthInternal * percentScreenShow
        : screenWidthInternal - 15;
      setTheme((curTHeme) => {
        return {
          ...curTHeme,
          screenWidth: newWidth,
          screenHeight: screenHeightInternal,
        };
      });
    });

    let version = appJson.version;
    let versionFromCache = AsyncStorage.getItem('version');
    if (version !== versionFromCache) {
      //AsyncStorage.clear();
      //window.location.reload(true);
      AsyncStorage.setItem('version', version);
    }

    let result = AsyncStorage.getItem('user');
    if (result) {
      setUser(result);
      //setCountryDetail(constructCountryDetails(result.country,result.mobileNumber));
    } else {
      setUser(null);
      //setCountryDetail(constructCountryDetails(null,null));
    }
    let newBiz = AsyncStorage.getItem('business');
    if (newBiz) {
      updateTheme();
    } else {
      setBusiness(null);
    }
    newBiz
      ? (document.getElementById('favicon').href =
          cloudFrontURL + 'public/' + newBiz?.id + '/profile/favicon.ico')
      : null;

    let val = AsyncStorage.getItem(newBiz?.id + CART_SUFFIX)?.items
      ? AsyncStorage.getItem(newBiz?.id + CART_SUFFIX)?.items?.length
      : 0;
    setBadgeValue(val);
  }, [navigation, Dimensions]);

  React.useEffect(()=>{
      setCountryDetail(constructCountryDetails(business, user))
  },[business, user]);
  const localStyles = StyleSheet.create({
    tabStyle: {
      backgroundColor: theme?.colors?.tabBackgroundColor,
    },
  });
  const updateTheme = () => {
    let newBiz = AsyncStorage.getItem('business');
    if (newBiz && newBiz.id != business?.id) {
      setBusiness(newBiz);
      setTheme((curTHeme) => {
        return {
          ...curTHeme,
          ...baseTheme,
          colors: newBiz?.profile?.theme?.colors,
        };
      });
    }
  };
  const reloadIfCurVersionStale = () =>{
    var xmlHttp = new XMLHttpRequest();
    xmlHttp.overrideMimeType("application/json");
    xmlHttp.open("GET", "https://herbsnhues.com/public/curVersionDetails", false); // false for synchronous request
    xmlHttp.send(null);
    let resText = xmlHttp.responseText;
    let res = JSON.parse(resText);
    if (appJson.version !== res.version){
      console.log("Reloadng as current version is stale. appJson.version: ",appJson.version, " latest version", res.version);
      if(res.bc !==1)AsyncStorage.clear();
      location.reload();
    }
  }
  const goBack = () => {
    let finalGoto = null;
    let curRouteName = navigation.getCurrentRoute().name;
    let stateForCurRoute = AsyncStorage.getItem(
      USER_STATE_PREFIX + curRouteName,
    );
    let raw = AsyncStorage.getItem(HISTORY_PREFIX);
    let historyForCurRoute = raw ? JSON.parse(raw) : [];
    if (historyForCurRoute && historyForCurRoute.length > 0) {
      let prevState = [...historyForCurRoute];
      finalGoto = prevState.splice(prevState.length - 1, 1)[0];
      if (
        finalGoto.route === curRouteName &&
        finalGoto.state === stateForCurRoute
      ) {
        finalGoto = prevState.splice(prevState.length - 1, 1)[0];
      }
      navigation.navigate(finalGoto.route, {
        userState: finalGoto.state,
        skipHistory: true,
      });

      AsyncStorage.setItem(HISTORY_PREFIX, JSON.stringify(prevState));
    }
  };
  const BadgedIcon = withBadge('0', {
    status: 'error',
    left: 0,
    top: 0,
    textStyle: styles.badgeText,
    value: badgeValue,
    containerStyle: styles.badgeContainer,
    badgeStyle: styles.badge,
  })(MyIcon);
  return (
    // <ApolloProvider client={this.state.client}>
    <>
    <SafeAreaProvider
      style={{
        maxWidth: theme?.screenWidth,
        alignSelf: 'center',
        backgroundColor: theme?.colors?.backgroundColor,
      }}>
      <ImageBackground
        source={{
          uri:
            cloudFrontURL +
            'public/d41f69cc-f00f-4d93-ab39-d36abeca7f77/profile/bg.jpg',
        }}
        resizeMode="cover"
        style={{opacity: 0.999, width: '100%', height: '100%'}}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <MyIcon
            name="keyboard-backspace"
            type="material-community"
            onPress={() => {
            }}
            containerStyle={{}}
            color='transparent'
            />
          {business?.profile?.logo && (
            <Image
              style={{
                width: isDeskTopBrowser ? 225 : 95,
                height: isDeskTopBrowser ? 125 : 52,
                alignSelf: 'center',
              }}
              resizeMode="contain"
              source={
                business?.profile?.logo
                  ? {uri: cloudFrontURL + business?.profile?.logo}
                  : null
              }
            />
          )}
          <View style={{flexDirection: 'row'}}>
            <BadgedIcon
              name="cart"
              type="material-community"
              iconStyle={{alignSelf: 'center'}}
              color={theme?.colors?.primary}
              size={35}
              z-index={350}
              Component={TouchableHighlight}
              activeOpacity={0.6}
              underlayColor={theme?.colors?.iconTouchHighlight}
              onPress={() => {
                navigation.navigate('Search', {
                  userState: USER_STATE.CART,
                });
              }}
            />
          </View>
        </View>
        <MyContext.Provider
          value={{user: user, theme: theme, business: business, countryDetail: countryDetail, version : appJson.version, versionFromCache : AsyncStorage.getItem('version')}}>
          <NavigationContainer ref={(nav) => (navigation = nav)}>
            <Tab.Navigator
              sceneContainerStyle={{
                backgroundColor: theme?.colors?.backgroundColor,
              }}
              backBehavior="history"
              swipeEnabled={false}
              tabBarOptions={{
                activeTintColor: theme?.colors?.primary,
                inactiveTintColor: theme?.colors?.secondary,
                keyboardHidesTabBar: true,
                tabStyle: localStyles?.tabStyle,
              }}
              lazy={false}
              initialRouteName={
                AsyncStorage?.getItem('route')
                  ? AsyncStorage?.getItem('route')
                  : 'Home'
              }>
              <Tab.Screen
                name="Home"
                component={HomeScreen}
                initialParams={{
                  forceRender: true,
                  dest: 'Home',
                  domain:
                    window.location.hostname === 'localhost'
                      ? 'herbsnhues.com'
                      : window.location.host.substring(
                          window.location.host.lastIndexOf(
                            '.',
                            window.location.host.lastIndexOf('.') - 1,
                          ) + 1,
                        ),
                }}
                listeners={{
                  tabPress: (e) => {
                    console.log('Tab Listener Event in Home:', e);
                    reloadIfCurVersionStale();
                    updateHistory(
                      navigation.getCurrentRoute().name,
                      'Home',
                      USER_STATE.NONE,
                    );
                  },
                  blur: (e) => {
                    console.log('FocusEvent in Home:', e);
                    updateTheme();
                  },
                }}
                tabBarOptions={{
                  showIcon: true,
                }}
                options={{
                  title: 'Home',
                  tabBarLabel: 'Home',
                  tabBarIcon: ({focused, color, size}) => (
                    <Icon
                      raised
                      name="home"
                      type="font-awesome"
                      color={color}
                      size={size}
                      Component={TouchableHighlight}
                      activeOpacity={0.6}
                      underlayColor={theme?.colors?.iconTouchHighlight}
                    />
                  ),
                }}
              />
              <Tab.Screen
                name="About"
                component={About}
                listeners={{
                  tabPress: (e) => {
                    console.log('Tab Listener Event in About:', e);
                    reloadIfCurVersionStale();
                    updateHistory(
                      navigation.getCurrentRoute().name,
                      'About',
                      USER_STATE.NONE,
                    );
                  },
                }}
                options={{
                  title: 'About',
                  tabBarLabel: 'About' + (isDeskTopBrowser ? ' Us' : ''),
                  tabBarIcon: ({color, size}) => (
                    <Icon
                      raised
                      name="envelope-o"
                      type="font-awesome"
                      color={color}
                      size={size}
                      Component={TouchableHighlight}
                      activeOpacity={0.6}
                      underlayColor={theme?.colors?.iconTouchHighlight}
                    />
                  ),
                }}
                initialParams={{
                  action: 'About',
                }}
              />
              <Tab.Screen
                name="Search"
                component={SearchScreen}
                initialParams={{}}
                listeners={{
                  tabPress: (e) => {
                    if(!business){
                      e.preventDefault();
                      return;
                    }
                    console.log('Tab Listener Event in Search:', e);
                    reloadIfCurVersionStale();
                    updateHistory(
                      navigation.getCurrentRoute().name,
                      'Search',
                      USER_STATE.LANDING,
                    );
                  },
                }}
                options={{
                  title: 'Search',
                  tabBarLabel: 'Products',
                  tabBarIcon: ({color, size}) => (
                    <Icon
                      raised
                      name="search"
                      type="material-community"
                      color={color}
                      size={size}
                      Component={TouchableHighlight}
                      activeOpacity={0.6}
                      underlayColor={theme?.colors?.iconTouchHighlight}
                    />
                  ),
                }}
              />
              <Tab.Screen
                name="Feedback"
                component={Feedback}
                listeners={{
                  tabPress: (e) => {
                    console.log('Tab Listener Event in Feedback:', e);
                    reloadIfCurVersionStale();
                    updateHistory(
                      navigation.getCurrentRoute().name,
                      'Feedback',
                      USER_STATE.NONE,
                    );
                  },
                }}
                options={{
                  title: 'Feedback',
                  tabBarLabel: 'Support',
                  tabBarIcon: ({color, size}) => (
                    <Icon
                      raised
                      name="envelope-o"
                      type="font-awesome"
                      color={color}
                      size={size}
                      Component={TouchableHighlight}
                      activeOpacity={0.6}
                      underlayColor={theme?.colors?.iconTouchHighlight}
                    />
                  ),
                }}
                initialParams={{
                  action: 'Feedback',
                }}
              />
              <Tab.Screen
                name="Auth"
                component={Auth}
                listeners={{
                  tabPress: (e) => {
                    console.log('Tab Listener Event in Profile:', e);
                    reloadIfCurVersionStale();
                    AsyncStorage.setItem('route', 'Auth');
                    updateHistory(
                      navigation.getCurrentRoute().name,
                      'Auth',
                      USER_STATE.PROFILE,
                    );
                  },
                }}
                options={{
                  title:
                    user?.isPrivileged || user?.isCustomer
                      ? isMobile
                        ? 'Account'
                        : 'My Account'
                      : 'Login',
                  tabBarLabel:
                    user?.isPrivileged || user?.isCustomer
                      ? isMobile
                        ? 'Account'
                        : 'My Account'
                      : 'Login',
                  tabBarIcon: ({color, size}) =>
                    user?.isPrivileged || user?.isCustomer ? (
                      <Icon
                        raised
                        name="user-circle"
                        type="material-community"
                        color={color}
                        size={size}
                        Component={TouchableHighlight}
                        activeOpacity={0.6}
                        underlayColor={theme?.colors?.iconTouchHighlight}
                      />
                    ) : (
                      <Icon
                        raised
                        name="sign-in"
                        type="font-awesome"
                        color={color}
                        size={size}
                        Component={TouchableHighlight}
                        activeOpacity={0.6}
                        underlayColor={theme?.colors?.iconTouchHighlight}
                      />
                    ),
                }}
                initialParams={{
                  action:
                    user?.isPrivileged || user?.isCustomer
                      ? 'Profile'
                      : 'Autth',
                }}
              />
            </Tab.Navigator>
          </NavigationContainer>
          <FlashMessage position="top" />
        </MyContext.Provider>
      </ImageBackground>
    </SafeAreaProvider>
    <MyIcon
      name="keyboard-backspace"
      type="material-community"
      onPress={() => {
        goBack();
      }}
      color={theme?.colors?.primary}
      containerStyle={{
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed', 
        zIndex: '1350',
        left: 15,
        top: 10,
        backgroundColor: theme?.colors?.primaryVariant2,
      }}
    />
  </>
    //     </ApolloProvider>
  );
  //}
};

export default App;
