import {Auth} from 'aws-amplify';
import React, {useContext, useEffect, useState} from 'react';
import {Text, View, StyleSheet} from 'react-native';
import {useScrollToTop} from '@react-navigation/native';

import {MyContext, styles} from '../../styles/styles';
import {CheckBox} from 'react-native-elements';
import {createFeedback} from '../../graphql/mutations';
import {API} from 'aws-amplify';
import uuid from 'react-native-uuid';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import {MyStatusBar} from '../ui/MyStatusBar';
import {CommonProfile} from '../../utilities/profile';
import {InputText, InputTextArea, ActionButton, MyLinks} from '../ui/elements';
import {initializeLocalState} from '../../utilities/InitializeLocalState';
import {Footer} from '../footer/Footer';

function Feedback({navigation}) {
  const [feedback, setFeedback] = useState({
    contactInfo: '',
    canDoBetter: '',
    doingGood: '',
    name: '',
    username: null,
    giveFeedback: true,
    requestSupport: false,
    issue: '',
  });
  const [error, setError] = useState();
  //const [user, setUser] = useState({isCustomer: false, isPrivileged: false});
  //const [business, setBusiness] = useState();
  const context = useContext(MyContext);
  const theme = context?.theme;
  const user = context?.user;
  const business = context?.business;
  const [curWebView, setCurWebViewInternal] = useState();
  function setCurWebView(wv) {
    setCurWebViewInternal(wv);
    window.scrollTo(0, 0);
  }
  const ref = React.useRef(null);
  useScrollToTop(ref);

  /*
  useEffect(() => {
    function setIniitialState(businessInternal, userInternal) {}
    initializeLocalState(setUser, setBusiness, setIniitialState, navigation);
  }, [navigation]);
*/
  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', (e) => {
      setCurWebView(null);
    });

    Auth.currentAuthenticatedUser()
      .then((user) => {
        setFeedback((prevFeedback) => {
          return {
            ...prevFeedback,
            contactInfo: user?.signInUserSession.idToken.payload.email,
            name:
              user?.signInUserSession.idToken.payload.given_name +
              ' ' +
              user?.signInUserSession.idToken.payload.family_name,
            username: user?.signInUserSession.idToken.payload.phone_number,
          };
        });
      })
      .catch(() => {
        console.log('user not authenticated');
      });
  }, [navigation]);

  function onChangeText(key, value) {
    setError('');
    setFeedback((prevFeedback) => {
      return {
        ...prevFeedback,
        [key]: value,
      };
    });
  }
  function submitFeedback() {
    let message;
    console.log('submittinng feedback', feedback);
    const {
      username,
      contactInfo,
      name,
      doingGood,
      canDoBetter,
      issue,
      giveFeedback,
      requestSupport,
    } = feedback;
    if (giveFeedback && !doingGood && !canDoBetter) {
      message = 'Please enter your feedback';
    } else if (requestSupport && (!name || !contactInfo || !issue)) {
      message = 'Please enter name, contact info and issue detail';
    } else {
      API.graphql({
        query: createFeedback,
        variables: {
          input: {
            id: uuid.v4(),
            issue: issue,
            username: username,
            isFeedback: giveFeedback,
            isSupportRequest: !giveFeedback,
            contactInfo: contactInfo,
            name: name,
            doingGood: doingGood,
            canDoBetter: canDoBetter,
            businessEmail: business?.profile?.contactInfo?.email,
            businessId: business?.id,
          },
        },
        authMode: 'API_KEY',
      })
        .then((result) => {
          console.log(result);
          if (feedback.giveFeedback) {
            message = 'Thanks for your feedback!';
          } else if (feedback.requestSupport) {
            message = 'Thanks for your request! We will be in touch with you';
          }
          setError(message);
          setTimeout((_) => {
            setError('');
          }, 3000);
        })
        .catch((e) => {
          console.log(e);
          setError(message);
          setTimeout((_) => {
            setError('');
          }, 3000);
        });
    }
    if (message) {
      setError(message);
      setTimeout((_) => {
        setError('');
      }, 3000);
    }
  }
  const localStyles = StyleSheet.create({
    infoMessage: {
      fontSize: 18,
      color: theme?.colors?.info,
      padding: 12,
      backgroundColor: 'transparent',
      fontWeight: '300',
    },
    infoQuestion: {
      fontSize: 20,
      color: theme?.colors?.info,
      padding: 12,
      backgroundColor: 'transparent',
      fontWeight: '400',
    },
  });

  return (
    <KeyboardAwareScrollView
      contentContainerStyle={[styles.KeyboardAwareScrollViewStyle]}
      scrollEnabled
      ref={ref}
      showsVerticalScrollIndicator={true}
      automaticallyAdjustContentInsets={false}
      resetScrollToCoords={{x: 0, y: 0}}
      keyboardDismissMode="on-drag"
      keyboardShouldPersistTaps="always">
      {curWebView ? (
        <MyLinks
          business={business}
          curWebView={curWebView}
          setCurWebView={setCurWebView}
        />
      ) : (
        <View style={{flex: 1, alignItems: 'center'}}>
          <View style={{alignItems: 'flex-start'}}>
            {error && error.length > 0 ? (
              <Text style={styles.errorMsg}>{error}</Text>
            ) : (
              <Text style={localStyles.infoQuestion}>
                What would you like to do?
              </Text>
            )}
            <CheckBox
              title="I would like to provide feedback"
              containerStyle={localStyles.infoMessage}
              textStyle={localStyles.infoMessage}
              checkedColor="grey"
              checked={feedback.giveFeedback}
              checkedIcon="dot-circle-o"
              uncheckedIcon="circle-o"
              onPress={() => {
                setFeedback((prevFeedback) => {
                  return {
                    ...prevFeedback,
                    giveFeedback: !feedback.giveFeedback,
                    requestSupport: false,
                    doingGood: '',
                    canDoBetter: '',
                  };
                });
              }}
            />
            <CheckBox
              title="I would like to create support request"
              containerStyle={localStyles.infoMessage}
              textStyle={localStyles.infoMessage}
              checkedColor="grey"
              checked={feedback.requestSupport}
              checkedIcon="dot-circle-o"
              uncheckedIcon="circle-o"
              onPress={() => {
                setFeedback((prevFeedback) => {
                  return {
                    ...prevFeedback,
                    giveFeedback: false,
                    requestSupport: !feedback.requestSupport,
                    doingGood: '',
                    canDoBetter: '',
                  };
                });
              }}
            />
            <Text style={localStyles.infoMessage}>
              Please provide the following information so we can reach you about
              it
            </Text>
            <Text style={localStyles.infoMessage}>*Full Name:</Text>

            <InputText
              placeholder="Please Enter your Full Name"
              name="name"
              onChangeText={onChangeText}
              value={feedback.name}
              label="Full Name"
            />
            <InputTextArea
              placeholder="Please Enter your email"
              name="contactInfo"
              onChangeText={onChangeText}
              value={feedback.contactInfo}
              autoCompleteType="email"
              textContentType="email"
              label="*Email"
            />
            {feedback.giveFeedback && (
              <>
                <Text style={localStyles.infoMessage}>
                  Let us know what can we do to help better or what we are doing
                  good?
                </Text>
                <Text style={localStyles.infoMessage}>
                  What we can do better?
                </Text>
                <InputTextArea
                  placeholder="Please provide your feedback on what we can do better"
                  name="canDoBetter"
                  onChangeText={onChangeText}
                  value={feedback.canDoBetter}
                  autoCompleteType="off"
                  textContentType="none"
                  label="Feedback on what you want changed"
                />
                <Text style={localStyles.infoMessage}>
                  What we are doing good?
                </Text>
                <InputTextArea
                  placeholder="Please provide your feedback on what we are doing good"
                  name="doingGood"
                  onChangeText={onChangeText}
                  value={feedback.doingGood}
                  autoCompleteType="off"
                  textContentType="none"
                  label="Feedbck on what you liked "
                />
              </>
            )}
            {feedback.requestSupport ? (
              <>
                <Text style={localStyles.infoMessage}>Issue Detail:</Text>
                <InputTextArea
                  placeholder="Tell us about the issue you are having and we will be in touch shortly"
                  name="issue"
                  onChangeText={onChangeText}
                  value={feedback.issue}
                  autoCompleteType="off"
                  textContentType="none"
                  label="What issues you are facing"
                />
              </>
            ) : null}
            {feedback.giveFeedback || feedback.requestSupport ? (
              <ActionButton
                style={{marginLeft: 10}}
                title="Submit"
                onPress={submitFeedback}
                message={
                  error && error.length
                    ? error
                    : 'Submitted ' +
                      (feedback.giveFeedback ? 'Feedback' : 'Support Request')
                }
              />
            ) : null}
          </View>
        </View>
      )}
      <Footer setCurWebView={setCurWebView} />
    </KeyboardAwareScrollView>
  );
}
export default Feedback;
