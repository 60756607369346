import {MyStorage as AsyncStorage} from './MyStorage';

import ApolloClient from 'apollo-boost';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {persistCache} from 'apollo-cache-persist';
import {Auth} from 'aws-amplify';
import AppSyncConfig from '../../aws-exports';
import {CommonProfile} from './profile';
import {HISTORY_PREFIX, USER_STATE, USER_STATE_PREFIX,SELECT_YOUR_COUNTRY} from './Constants';

export const constructClient = (jwtToken) => {
  let cache = new InMemoryCache();

  let persCache = async () =>
    await persistCache({
      cache: cache,
      storage: AsyncStorage,
      maxSize: 2,
    });
  persCache();
  let iniitialClient = new ApolloClient({
    cache: cache,
    uri: AppSyncConfig.aws_appsync_graphqlEndpoint,
    headers: {
      'x-api-key': AppSyncConfig.aws_appsync_apiKey,
      authorization: jwtToken,
    },
  });
  iniitialClient.defaultOptions = {
    watchQuery: {
      //'cache-first' | 'no-cache' | 'cache-only' | 'no-cache' | 'standby|'cache-and-network''
      fetchPolicy: 'cache-and-network',
    },
  };
  return iniitialClient;
};

export const SingletonApolloClient = (function () {
  var instance, instanceWithToken;

  function createInstance(token) {
    var object = constructClient(token);
    console.log('created apollo client. usedToken?', token ? true : false);
    return object;
  }
  return {
    getInstance: function (token) {
      token
        ? !instanceWithToken
          ? (instanceWithToken = createInstance(token))
          : null
        : !instance
        ? (instance = createInstance(token))
        : null;

      return token ? instanceWithToken : instance;
    },
  };
})();
export const isPrivilegedUser = () => {
  Auth.currentAuthenticatedUser()
    .then((user) => {
      let groups = user?.signInUserSession.idToken.payload['cognito:groups'];
      let isPrivileged =
        groups.includes('editors') || groups.includes('admins');
      console.log('Authenticated Privileged User: ', isPrivileged);
      return isPrivileged;
    })
    .catch((e) => {
      console.log('isPrivileged: false, user not authenticated');
      return false;
    });
};
export const phoneNumberWithPrefix = (enteredNumber) => {
  return enteredNumber.startsWith('+')
    ? enteredNumber
    : CommonProfile.phoneNumberPrefix + enteredNumber;
};
export const phoneNumberWithoutPrefix = (enteredNumber) => {
  return enteredNumber.startsWith(CommonProfile.phoneNumberPrefix)
    ? enteredNumber.substring(
        CommonProfile.phoneNumberPrefix.length,
        enteredNumber.length,
      )
    : enteredNumber.startsWith(CommonProfile.usPhoneNumberPrefix)
    ? enteredNumber.substring(
        CommonProfile.usPhoneNumberPrefix.length,
        enteredNumber.length,
      )
    : enteredNumber;
};
export const constructUser = (user) => {
  let groups = user?.signInUserSession.idToken.payload['cognito:groups'];
  let isCustomer = groups.includes('customers');
  let isPrivileged = groups.includes('editors') || groups.includes('admin');
  let mobileNumber = user?.signInUserSession.idToken.payload.phone_number;
  let address = user?.signInUserSession.idToken.payload.address?.formatted;
  let name = user?.signInUserSession.idToken.payload.name;
  let firstName = user?.signInUserSession.idToken.payload.given_name;
  let lastName = user?.signInUserSession.idToken.payload.family_name;
  let email = user?.signInUserSession.idToken.payload.email;
  let city = user?.signInUserSession.idToken.payload['custom:city'];
  let state = user?.signInUserSession.idToken.payload['custom:state'];
  let country = user?.signInUserSession.idToken.payload['custom:country'];
  let zipCode = user?.signInUserSession.idToken.payload['custom:zipCode'];
  let discount = user?.signInUserSession.idToken.payload['custom:discount'];
  let jwtToken = user?.signInUserSession.idToken.jwtToken;
  let businessId = user?.attributes['custom:businessId'];
  return {
    isCustomer: isCustomer,
    isPrivileged: isPrivileged,
    jwtToken: jwtToken,
    mobileNumber: mobileNumber,
    address: address,
    name: name,
    firstName: firstName,
    lastName: lastName,
    businessId: businessId,
    username: user?.username,
    email: email,
    state: state,
    zipCode: zipCode,
    country: country,
    city: city,
    discount: discount?""+discount:"0",
    userId: user?.attributes.sub,
  };
};
export const isCustomer = () => {
  Auth.currentAuthenticatedUser()
    .then((user) => {
      let groups = user?.signInUserSession.idToken.payload['cognito:groups'];
      let isCustomer = groups.includes('customers');
      console.log('Authenticated Customer: ', isCustomer);
      return isCustomer;
    })
    .catch((e) => {
      console.log('user is not signed in', e);
      return false;
    });
};

export const signOut = () => {
  Auth.currentAuthenticatedUser()
    .then((user) => {
      console.log('user = ' + JSON.stringify(user));
      console.log('getDevice', user?.getDevice());

      var res = user?.signOut();
      console.log('signOut ');
    })
    .catch((e) => {
      console.log('user is not signed in', e);
    });
};

export const getPhoneNumber = () => {
  Auth.currentAuthenticatedUser()
    .then((user) => {
      let phone = user?.signInUserSession.idToken.payload.phone_number;
      console.log('phone Number: ', phone);
      return phone;
    })
    .catch((e) => {
      console.log('user not authenticated. No phone number');
      return null;
    });
};

export const updateHistory = (
  curRouteName,
  targetRouteName,
  newState,
  skipHistory,
) => {
  let curUserState = AsyncStorage.getItem(USER_STATE_PREFIX + targetRouteName);
  if (newState !== curUserState) {
    AsyncStorage.setItem(USER_STATE_PREFIX + targetRouteName, newState);
  }
  if (!skipHistory) {
    let raw = AsyncStorage.getItem(HISTORY_PREFIX);
    let historyList = raw ? JSON.parse(raw) : [];
    if (historyList.length === 0) {
      AsyncStorage.setItem(
        HISTORY_PREFIX,
        JSON.stringify([
          ...historyList,
          {route: curRouteName, state: USER_STATE.NONE},
        ]),
      );
      raw = AsyncStorage.getItem(HISTORY_PREFIX);
      historyList = raw ? JSON.parse(raw) : [];
    }
    if (
      historyList.length === 0 ||
      (historyList.length > 0 &&
        !(
          historyList[historyList.length - 1].route === targetRouteName &&
          historyList[historyList.length - 1].state === newState
        ))
    ) {
      AsyncStorage.setItem(
        HISTORY_PREFIX,
        JSON.stringify([
          ...historyList,
          {route: targetRouteName, state: newState},
        ]),
      );
    }
  }
};

export const constructCountryDetails = (business, user) => {
  let country=user?.country;
  let mobileNumber = user?.mobileNumber;
  //let business = AsyncStorage.getItem('business');
  if (!country && mobileNumber) {
    let found = false;
    business?.profile?.shipping?.allowedCountries.map(
      (element) => {
        if (mobileNumber?.startsWith(element['phoneNumberPrefix'])) {
          country = element['country'];
          found = true;
        }
      }
    );
  }
  let isInternational = Intl.DateTimeFormat().resolvedOptions().timeZone !=='Asia/Calcutta';
  country = country? country:isInternational?"United States":"India";
  let exchangeRate = 1;
  let priceMultiplier = 1;
  let currency = "INR";
  let currencySymbol = '&#00024';
  business?.profile?.shipping?.allowedCountries.map(
    (element) => {
      if (element['country'] === country) {
        exchangeRate = element["exchangeRate"];
        priceMultiplier = element["priceMultiplier"];
        currency = element["currency"];
        currencySymbol = element['currencySymbol'];
        isInternational = country? (country !=='India'):isInternational; 
      }
    }
  );
  if (business?.profile?.enableInternational || user?.isPrivileged===true){
  //if (true){
  return {     
    exchangeRate: exchangeRate,
    priceMultiplier: priceMultiplier,
    currency: currency, 
    country: country ,
    currencySymbol:currencySymbol,
    isInternational: isInternational,
  };
  }else{
    return {     
      exchangeRate: 1,
      priceMultiplier: 1,
      currency: "INR", 
      country: "India" ,
      currencySymbol:"INR",
      isInternational: false,
    };  
  }
};

