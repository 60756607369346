import {Analytics, API, Auth as AmpliifyAuth} from 'aws-amplify';
import {MyStorage as AsyncStorage} from '../utilities/MyStorage';
import {CART_SUFFIX, USER_EVENTS} from '../utilities/Constants';
import {Hub} from 'aws-amplify';

export const initializeLocalState = (
  setUser,
  setBusiness,
  setIniitialState,
  navigation,
) => {
  console.log('useEffect checkauth common');
  Hub.listen('auth', (data) => {
    if (data.payload.event === 'signOut') {
      console.log('Listening', data.payload.event);
      checkAuth();
    }
  });
  function syncBusiness(userChanged, businessInternal, userInternal) {
    setBusiness((prevBusiness) => {
      let bizChanged =
        (prevBusiness &&
          businessInternal &&
          prevBusiness?.id !== businessInternal?.id) ||
        (!prevBusiness && businessInternal);
      if (userChanged || bizChanged) {
        console.log(
          'prevBusiness',
          prevBusiness?.id,
          'business',
          businessInternal?.id,
        );
        setIniitialState(businessInternal, userInternal);
        console.log(
          'prevBusiness && business && prevBusiness?.id !== business?.id',
        );
      }
      return businessInternal;
    });
  }
  function checkAuth() {
    let businessInternal = AsyncStorage.getItem('business');
    let userInternal = AsyncStorage.getItem('user');

    try {
      Analytics.record(USER_EVENTS.PAGE_VIEW);
      setUser((prevUser) => {
        let userChanged =
          (prevUser &&
            userInternal &&
            prevUser?.username !== userInternal?.username) ||
          (!prevUser && userInternal);
        console.log(
          'prevUser',
          prevUser ? prevUser?.username : null,
          'user',
          userInternal?.username,
        );
        if (userChanged && businessInternal?.id ) {
          AsyncStorage.removeItem(businessInternal.id + CART_SUFFIX);
        }
        syncBusiness(userChanged, businessInternal, userInternal);
        return userInternal;
      });
    } catch (e) {
      console.log('Error:', e);
      throw e;
    }
  }
  checkAuth();
  const unsubscribe = navigation.addListener('focus', (e) => {
    console.log('Event in Search, outside loading:', e);
    checkAuth();
  });
  return unsubscribe;
};
