/**
 * @format
 */
import {AppRegistry} from 'react-native';
import App from './src/components/App';
import {name as appName} from './app.json';
import Amplify from 'aws-amplify';
import config from './aws-exports';
import {YellowBox, LogBox} from 'react-native';
import {MyStorage as AsyncStorage} from './src/utilities/MyStorage';
import iconFont from 'react-native-vector-icons/Fonts/FontAwesome.ttf';
import iconFontMat from 'react-native-vector-icons/Fonts/MaterialIcons.ttf';
import iconFontCom from 'react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf';
import iconFontOct from 'react-native-vector-icons/Fonts/Octicons.ttf';
import iconFontIon from 'react-native-vector-icons/Fonts/Ionicons.ttf';

Amplify.configure(config);

//YellowBox.ignoreWarnings([
LogBox.ignoreLogs(['VirtualizedLists', 'FlatList', 'Require cycle']);
LogBox.ignoreAllLogs();
/*
 Amplify.configure({
   storage: AsyncStorage
 });
 */
const iconFontStyles = `
@font-face {
   src: url(${iconFont});
   font-family: FontAwesome;
 }
 @font-face {
  src: url(${iconFontMat});
  font-family: MaterialIcons;
}
@font-face {
  src: url(${iconFontCom});
  font-family: MaterialCommunityIcons;
}
@font-face {
  src: url(${iconFontOct});
  font-family: Octicons;
}
@font-face {
  src: url(${iconFontIon});
  font-family: Ionicons;
}
 `;

// Create stylesheet
const style = document.createElement('style');
style.type = 'text/css';
if (style.styleSheet) {
  style.styleSheet.cssText = iconFontStyles;
} else {
  style.appendChild(document.createTextNode(iconFontStyles));
}

// Inject stylesheet
document.head.appendChild(style);

AppRegistry.registerComponent(appName, () => App);
AppRegistry.runApplication(appName, {
  initialProps: {},
  rootTag: document.getElementById('app-root'),
});
