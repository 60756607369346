import {Analytics, Auth} from 'aws-amplify';
import React, {useState, useContext} from 'react';
import {Text, View, ActivityIndicator} from 'react-native';
import {cleanPhoneNumber, PHONE_NUMBER_LENGTH, USER_EVENTS} from '../../utilities/Constants';
import {styles, maxInputFieldWidth, MyContext} from '../../styles/styles';
import {InputPhone, InputSecret, InputText, ActionButton} from '../ui/elements';
import I18n from '../../I18n/I18n';


export const ForgotPassword = (props) => {
  const {business, theme} = useContext(MyContext);
  const [state, setStateInternal] = useState({
    stage: 0,
    username: '',
    password: '',
    confirmPassword: '',
    authorizationCode: '',
    error: '',
    showActivityIndicator: false,
    phoneNumberPrefix: '',
  });
  const {stage} = state;
  const setState = (key, value) => {
    setStateInternal((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  const onChangeText = (key, value) => {
    setState(key, value?value.trim():value);
    setState('error', '');
  };
  const resetPassword = () => {
    if (state.phoneNumberPrefix?.length <2) {
      setState('error', I18n.t('INVALID_COUNTRY_CODE'));
      return;
    }    
    if ( cleanPhoneNumber(state.username)?.length !==PHONE_NUMBER_LENGTH) {
      setState('error', I18n.t('INVALID_PHONE'));
      return;
    }
    setState('showActivityIndicator', true);
    Auth.forgotPassword(state.phoneNumberPrefix+cleanPhoneNumber(state.username))
      .then(() => {
        setState('showActivityIndicator', false);
        setState('stage', 1);
        Analytics.record(USER_EVENTS.FORGOT_PWD_SUCCESS);
      })
      .catch((err) => {
        setState('showActivityIndicator', false);
        console.log('error: ', err);
        Analytics.record(USER_EVENTS.FORGOT_PWD_FAILURE);
      });
  };
  const confirmResetPassword = () => {
    setState('showActivityIndicator', true);
    const {username, password, authorizationCode,phoneNumberPrefix, confirmPassword} = state;
    if (confirmPassword!==password) {
      setState('error', I18n.t('MISMATCH_PASSWORD'));
      return;
    }
    Auth.forgotPasswordSubmit(
      phoneNumberPrefix+cleanPhoneNumber(username),
      authorizationCode,
      password,
    )
      .then(() => {
        setState('showActivityIndicator', false);
        setState('error', I18n.t('PWD_CHD'));
        props.toggleAuthType('showSignIn');
      })
      .catch((err) => {
        setState('showActivityIndicator', false);
        console.log('error resetting password:', err);
        setState({
          error:
            'Please check Confirmation code and password. Password must be at least 8 characters and must include at least two character one of which is uppercase and one number',
        });
      });
  };
  return (
    <View style={{maxWidth: maxInputFieldWidth, alignSelf: 'center'}}>
      {state.showActivityIndicator && (
        <ActivityIndicator animating size="large" color="grey" />
      )}
      <Text style={styles.errorMsg}>{state.error}</Text>
      {stage === Number(0) && (
        <View style={{alignItems: 'center'}}>
          <InputPhone
            placeholder="10 digit number"
            name="username"
            value={state.username}
            autoCompleteType={'username'}
            autoComplete={'username'}
            textContentType={'username'}
            onChangeText={onChangeText}
            label="*Mobile Number"
            selectedValue={state?.phoneNumberPrefix}
            business={business}
          />
          <ActionButton title="Get Authorization" onPress={resetPassword} />
        </View>
      )}
      {stage === Number(1) && (
        <View style={{alignItems: 'center'}}>
          <InputText
            placeholder="Authorization Code"
            name="authorizationCode"
            value={state.authorizationCode}
            onChangeText={onChangeText}
            autoComplete="sms-otp"
            textContentType="oneTimeCode"
            label="*Authorization Code"
          />
          <InputSecret
            placeholder="New Password"
            name="password"
            value={state.password}
            onChangeText={onChangeText}
            label="*New Password (Password must be minimum 8 charctaters and have a lower case, an upppercase and a numeric character)"
          />
          <InputSecret
            placeholder="Confirm Password"
            name="confirmPassword"
            value={state.confirmPassword}
            onChangeText={onChangeText}
            label="*Confirm Password"
          />
          <ActionButton title="Reset Password" onPress={confirmResetPassword} />
        </View>
      )}
    </View>
  );
};
