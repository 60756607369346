import {gql} from 'apollo-boost';

export const getReviewCount = gql`
  query getReviewCount($businessId: ID, $rating: Int) {
    searchReviews(
      filter: {rating: {eq: $rating}, businessId: {eq: $businessId}}
    ) {
      total
    }
  }
`;

export const getReviewCountTotal = gql`
  query getReviewCount($businessId: ID) {
    searchReviews(filter: {businessId: {eq: $businessId}}) {
      total
    }
  }
`;
