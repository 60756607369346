/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const ezibizcreateTxToken = /* GraphQL */ `
  mutation EzibizcreateTxToken($input: AWSJSON) {
    ezibizcreateTxToken(input: $input)
  }
`;
export const createBusiness = /* GraphQL */ `
  mutation CreateBusiness(
    $input: CreateBusinessInput!
    $condition: ModelBusinessConditionInput
  ) {
    createBusiness(input: $input, condition: $condition) {
      id
      rank
      domain
      name
      location
      profile
      tags
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteBusiness = /* GraphQL */ `
  mutation DeleteBusiness(
    $input: DeleteBusinessInput!
    $condition: ModelBusinessConditionInput
  ) {
    deleteBusiness(input: $input, condition: $condition) {
      id
      rank
      domain
      name
      location
      profile
      tags
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createFeedback = /* GraphQL */ `
  mutation CreateFeedback(
    $input: CreateFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    createFeedback(input: $input, condition: $condition) {
      id
      isFeedback
      canDoBetter
      doingGood
      isBizLead
      isSupportRequest
      name
      username
      contactInfo
      note
      issue
      businessEmail
      businessId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateFeedback = /* GraphQL */ `
  mutation UpdateFeedback(
    $input: UpdateFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    updateFeedback(input: $input, condition: $condition) {
      id
      isFeedback
      canDoBetter
      doingGood
      isBizLead
      isSupportRequest
      name
      username
      contactInfo
      note
      issue
      businessEmail
      businessId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteFeedback = /* GraphQL */ `
  mutation DeleteFeedback(
    $input: DeleteFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    deleteFeedback(input: $input, condition: $condition) {
      id
      isFeedback
      canDoBetter
      doingGood
      isBizLead
      isSupportRequest
      name
      username
      contactInfo
      note
      issue
      businessEmail
      businessId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createReview = /* GraphQL */ `
  mutation CreateReview(
    $input: CreateReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    createReview(input: $input, condition: $condition) {
      id
      businessId
      review
      title
      rating
      created
      likeCount
      disLikeCount
      username
      pictureURL
      videoUrl
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateReview = /* GraphQL */ `
  mutation UpdateReview(
    $input: UpdateReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    updateReview(input: $input, condition: $condition) {
      id
      businessId
      review
      title
      rating
      created
      likeCount
      disLikeCount
      username
      pictureURL
      videoUrl
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteReview = /* GraphQL */ `
  mutation DeleteReview(
    $input: DeleteReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    deleteReview(input: $input, condition: $condition) {
      id
      businessId
      review
      title
      rating
      created
      likeCount
      disLikeCount
      username
      pictureURL
      videoUrl
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createItems = /* GraphQL */ `
  mutation CreateItems(
    $input: CreateItemsInput!
    $condition: ModelItemsConditionInput
  ) {
    createItems(input: $input, condition: $condition) {
      id
      businessId
      title
      description
      tags
      category
      lengthx
      breadth
      height
      weight
      price
      internationalPrice
      imageKeys
      created
      modified
      quantity
      promotions
      media
      labels
      displayDimensions
      benefits
      ingredients
      subCategory
      usageInstructions
      parent
      unit
      unitMeasure
      allowedSellingUnits
      exemptFromShippingChanrges
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateItems = /* GraphQL */ `
  mutation UpdateItems(
    $input: UpdateItemsInput!
    $condition: ModelItemsConditionInput
  ) {
    updateItems(input: $input, condition: $condition) {
      id
      businessId
      title
      description
      tags
      category
      lengthx
      breadth
      height
      weight
      price
      internationalPrice
      imageKeys
      created
      modified
      quantity
      promotions
      media
      labels
      displayDimensions
      benefits
      ingredients
      subCategory
      usageInstructions
      parent
      unit
      unitMeasure
      allowedSellingUnits
      exemptFromShippingChanrges
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteItems = /* GraphQL */ `
  mutation DeleteItems(
    $input: DeleteItemsInput!
    $condition: ModelItemsConditionInput
  ) {
    deleteItems(input: $input, condition: $condition) {
      id
      businessId
      title
      description
      tags
      category
      lengthx
      breadth
      height
      weight
      price
      internationalPrice
      imageKeys
      created
      modified
      quantity
      promotions
      media
      labels
      displayDimensions
      benefits
      ingredients
      subCategory
      usageInstructions
      parent
      unit
      unitMeasure
      allowedSellingUnits
      exemptFromShippingChanrges
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOrderWithTxToken = /* GraphQL */ `
  mutation CreateOrderWithTxToken(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrderWithTxToken(input: $input, condition: $condition) {
      id
      businessId
      items
      mobileNumber
      businessEmail
      price
      note
      status
      termAndConditionsAcceptence
      delivery
      useCustomShipping
      pickupPostcode
      businessName
      orderSortDate
      createdAt
      updatedAt
      externalOrder
      preVerifiedCourierId
      lengthx
      breadth
      height
      weight
      shippingDetails
      priceDetail
      cod
      displayDimensions
      isCashPayment
      isAdminOrder
      adminNote
      deliveryContact
      deliveryAddress
      returnOrder
      paymentId
      externalOrderId
      owner
      __typename
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      businessId
      items
      mobileNumber
      businessEmail
      price
      note
      status
      termAndConditionsAcceptence
      delivery
      useCustomShipping
      pickupPostcode
      businessName
      orderSortDate
      createdAt
      updatedAt
      externalOrder
      preVerifiedCourierId
      lengthx
      breadth
      height
      weight
      shippingDetails
      priceDetail
      cod
      displayDimensions
      isCashPayment
      isAdminOrder
      adminNote
      deliveryContact
      deliveryAddress
      returnOrder
      paymentId
      externalOrderId
      owner
      __typename
    }
  }
`;
export const updateBusiness = /* GraphQL */ `
  mutation UpdateBusiness(
    $input: UpdateBusinessInput!
    $condition: ModelBusinessConditionInput
  ) {
    updateBusiness(input: $input, condition: $condition) {
      id
      rank
      domain
      name
      location
      profile
      tags
      createdAt
      updatedAt
      __typename
    }
  }
`;
