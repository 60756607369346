import * as React from 'react';
import {useContext} from 'react';
import {useState, useRef, useCallback, useMemo} from 'react';
import {Text} from 'react-native';
import DataGrid from 'react-data-grid';
import {SelectColumn} from 'react-data-grid';
import {MyContext} from '../../styles/styles';
import {USER_STATE} from '../../utilities/Constants';
import { DataTable } from 'react-native-paper';
import { Provider as PaperProvider } from 'react-native-paper';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import {
  StyleSheet,
} from 'react-native';
function isAtBottom(event) {
  const target = event.target;
  return target.clientHeight + target.scrollTop === target.scrollHeight;
}

function rowKeyGetter(row) {
  return row.id;
}

function loadMoreRows(newRowsCount, length) {
  return new Promise((resolve) => {
    const newRows = [];

    for (let i = 0; i < newRowsCount; i++) {
      //Fetch more data
    }

    setTimeout(() => resolve(newRows), 1000);
  });
}

export const MyDataGrid = ({
  rows,
  selectedRows,
  setSelectedRows,
  transitionState,
}) => {
  const gridRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const {user, business, theme, countryDetail} = useContext(MyContext);
  const [sortColumns, setSortColumns] = useState([]);

  /* Start For new Table */
  const [page, setPage] = React.useState(0);
  const [numberOfItemsPerPageList] = React.useState([5,10,20,50,100]);
  const [itemsPerPage, onItemsPerPageChange] = React.useState(numberOfItemsPerPageList[0]);
  const from = page * itemsPerPage;
  const to = Math.min((page + 1) * itemsPerPage, rows.length);

  React.useEffect(() => {
    setPage(0);
  }, [itemsPerPage]);

  /*End for new Table*/

  const [[sortColumn, sortDirection], setSort] = useState([
    'orderDate',
    'DESC',
  ]);
  const initialFilters = {
    id: null,
    title: null,
    description: null,
    price: null,
  };
  const [filters, setFilters] = useState(null);
  const [enableFilterRow, setEnableFilterRow] = useState(false);

  const defaultColumnProperties = {
    sortable: true,
    resizable: true,
  };

  const columns = [
    {
      key: 'id',
      name: 'Order Number',
      formatter({row}) {
        return (
          <span
            style={{color: theme?.colors?.primary, background: 'transparent'}}
            onClick={() => {
              setSelectedRows(row);
              transitionState(USER_STATE.ORDER_DETAIL);
            }}>
            {user?.isPrivileged
              ? row.id.substring(0, 19)
              : row.id.substring(0, 5)}
          </span>
        );
      },
    },
    {key: 'title', name: 'Title'},
    {key: 'status', name: 'Status'},
    //    {key: 'description', name: 'Description'},
    {key: 'price', name: 'Price'},
    {key: 'deliveryContact', name: 'Delivery Contact'},
    {key: 'deliveryContactMobile', name: 'Delivery Contact Mobile'},
    {key: 'orderInstructions', name: 'Order Instructions:'},
    {key: 'orderDate', name: 'Order Date '},
    {key: 'shippingAddress', name: 'Address '},
    {key: 'awbCode', name: 'Awb Code '},
    {key: 'shipmentId', name: 'Shipment Id '},
    {key: 'pickupScheduledDate', name: 'Pickup Scheduled Date '},
    {key: 'carrierName', name: 'Courier Name '},
  ].map((c) => ({...c, ...defaultColumnProperties}));

  function handleFill({columnKey, sourceRow, targetRows}) {
    return targetRows.map((row) => ({
      ...row,
      [columnKey]: sourceRow[columnKey],
    }));
  }

  const customerColumns = [
    SelectColumn,
    {
      key: 'id',
      name: 'Order Number',
      formatter({row}) {
        return (
          <span
            style={{color: theme?.colors?.primary, background: 'transparent'}}
            onClick={() => {
              setSelectedRows(row);
              transitionState(USER_STATE.ORDER_DETAIL);
            }}>
            {user?.isPrivileged
              ? row.id.substring(0, 19)
              : row.id.substring(0, 5)}
          </span>
        );
      },
    },
    {key: 'title', name: 'Title'},
    {key: 'orderDate', name: 'Order Date '},
    {key: 'price', name: 'Order Value (' + countryDetail.currency + ')'},
    {key: 'status', name: 'Status'},
    {key: 'awbCode', name: 'AWB'},
  ].map((c) => ({...c, ...defaultColumnProperties}));

  function handleFill({columnKey, sourceRow, targetRows}) {
    return targetRows.map((row) => ({
      ...row,
      [columnKey]: sourceRow[columnKey],
    }));
  }

  async function handleScroll(event) {
    if (!isAtBottom(event)) {
      return;
    }

    setIsLoading(true);

    const newRows = await loadMoreRows(50, rows.length);

    setIsLoading(false);
  }

  function clearFilters() {
    setFilters(null);
  }

  function toggleFilters() {
    setEnableFilterRow(!enableFilterRow);
  }

  const filteredRows = useMemo(() => {
    return rows.filter((r) => {
      return (
        !filters ||
        ((filters.id ? r.id === filters.id : true) &&
          (filters.title ? r.title === filters.title : true) &&
          (filters.description ? r.description == filters.description : true) &&
          (filters.price ? r.price === filters.price : true))
      );
    });
  }, [rows, filters]);

  function getComparator(sortColumnLocal) {
    switch (sortColumnLocal) {
      case 'id':
      case 'title':
      case 'status':
      case 'deliveryContact':
      case 'deliveryContactMobile':
      case 'orderInstructions':
      case 'shippingAddress':
      case 'description':
      case 'awbCode':
      case 'shipmentId':
      case 'carrierName':
      case 'description':
        return (a, b) => {
          if (a === null || b === null) {
            return 0;
          }
          return a[sortColumnLocal]?.localeCompare(b[sortColumnLocal]);
        };
      case 'available':
        return (a, b) => {
          return a[sortColumnLocal] === b[sortColumnLocal]
            ? 0
            : a[sortColumnLocal]
            ? 1
            : -1;
        };
      case 'orderDate':
      case 'pickupScheduledDate':
        return (a, b) => {
          if (a === null || b === null) {
            return 0;
          }
          return (
            Date.parse(a[sortColumnLocal]) - Date.parse(b[sortColumnLocal])
          );
        };
      case 'price':
        return (a, b) => {
          if (a === null || b === null) {
            return 0;
          }
          return a[sortColumnLocal] - b[sortColumnLocal];
        };
      default:
        throw new Error(`unsupported sortColumn: "${sortColumnLocal}"`);
    }
  }

  const sortedRows = useMemo(() => {
    if (sortColumns.length === 0) {
      return rows;
    }

    let sortedRowsLocal = [...filteredRows];
    sortedRowsLocal.sort((a, b) => {
      for (const sort of sortColumns) {
        const comparator = getComparator(sort.columnKey);
        const compResult = comparator(a, b);
        if (compResult !== 0) {
          return sort.direction === 'ASC' ? compResult : -compResult;
        }
      }
      return 0;
    });
    return sortedRowsLocal;
  }, [rows, sortColumns, filteredRows]);

  const updateItensPerPage =(newCount)=>{
    onItemsPerPageChange(newCount);
  }

  const onClickId= (event) => {
    let row;
    rows.slice().map((item)=>{
       if(event.target.textContent===item.id)row=item;
    }
    );
    setSelectedRows(row);
    transitionState(USER_STATE.ORDER_DETAIL);
  }
  const localStyles = StyleSheet.create({
    tableCellStyle: {
      paddingLeft: 5,
      width:300 ,
    },
    pagination:{
    justifyContent: 'flex-start'    
    },
    idStyle:{
      paddingLeft: 5,
      width:300 ,
      color: theme.colors.primary,
    }
  });
  return (
    <>
    <PaperProvider theme = {{dark: false}}>
      <KeyboardAwareScrollView horizontal>
      <DataTable style={{ flex: 1 }}>
        <DataTable.Header>
          {(columns).slice().map((item) => (
            (user?.isPrivileged || ['id','title','status','awbCode','price'].includes(item.key))? (<DataTable.Title>{item.name}</DataTable.Title>):null
          ))}
        </DataTable.Header>
        {(rows).slice(from, to).map((item) => (
          <DataTable.Row key={item.id}>
            <DataTable.Cell sortDirection='descending' onPress={onClickId} style={localStyles.idStyle}>{item.id}</DataTable.Cell>
            <DataTable.Cell style={localStyles.tableCellStyle}>{item.title}</DataTable.Cell>
            <DataTable.Cell style={localStyles.tableCellStyle}>{item.status}</DataTable.Cell>
            <DataTable.Cell style={localStyles.tableCellStyle}>{item.price} {item.currency}</DataTable.Cell>
            {user?.isPrivileged && (  <DataTable.Cell style={localStyles.tableCellStyle}>{item.deliveryContact}</DataTable.Cell>)}
            {user?.isPrivileged && (  <DataTable.Cell style={localStyles.tableCellStyle}>{item.deliveryContactMobile}</DataTable.Cell>)}
            {user?.isPrivileged && (  <DataTable.Cell style={localStyles.tableCellStyle}>{item.orderInstructions}</DataTable.Cell>)}
            {user?.isPrivileged && (  <DataTable.Cell style={localStyles.tableCellStyle}>{item.orderDate}</DataTable.Cell>)}
            {user?.isPrivileged && (  <DataTable.Cell style={localStyles.tableCellStyle}>{item.shippingAddress}</DataTable.Cell>)}
            <DataTable.Cell style={localStyles.tableCellStyle}>{item.awbCode}</DataTable.Cell>
            {user?.isPrivileged && (  <DataTable.Cell style={localStyles.tableCellStyle}>{item.shipmentId}</DataTable.Cell> )}
            {user?.isPrivileged && (  <DataTable.Cell style={localStyles.tableCellStyle}>{item.pickupScheduledDate}</DataTable.Cell>)}
            {user?.isPrivileged && (  <DataTable.Cell style={localStyles.tableCellStyle}>{item.carrierName}</DataTable.Cell>)}
          </DataTable.Row>
        ))}
        <DataTable.Pagination
          style={localStyles.pagination}
          page={page}
          numberOfPages={Math.ceil(rows.length / itemsPerPage)}
          onPageChange={(page) => setPage(page)}
          label={`${from + 1}-${to} of ${rows.length}`}
          numberOfItemsPerPageList={numberOfItemsPerPageList}
          numberOfItemsPerPage={itemsPerPage}
          onItemsPerPageChange={updateItensPerPage}
          showFastPaginationControls ={true}
          selectPageDropdownLabel={'Rows per page'}
          
        />
      </DataTable>
      </KeyboardAwareScrollView>
      </PaperProvider>
      {rows.length>0 &&(
      <Text
        style={{
          fontSize: theme?.fonts?.ml,
          color: theme?.colors?.info,
          margin: 10,
        }}>
        To see order details, tracking please click on order number
      </Text>
      )}
      {/*
      <DataGrid
        ref={gridRef}
        columns={user?.isPrivileged ? columns : customerColumns}
        rows={sortedRows}
        rowKeyGetter={rowKeyGetter}
        //   onRowsChange={setRows}
        defaultColumnOptions={{
          sortable: true,
          resizable: true,
        }}
        onFill={handleFill}
        rowHeight={28}
        headerRowHeight={28}
        onScroll={handleScroll}
        rowClass={(row) => (row.id.includes('7') ? 'highlight' : undefined)}
        sortColumns={sortColumns}
        onSortColumnsChange={setSortColumns}
        sortDirection={sortDirection}
        enableFilterRow={enableFilterRow}
        filters={filters}
        onFiltersChange={setFilters}
        className="fill-grid"
      />
      */}
    </>
  );
};
