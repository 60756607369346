import React from 'react';
import {useContext} from 'react';
import {
  ActivityIndicator,
  Image,
  TouchableOpacity,
  View,
  StyleSheet,
} from 'react-native';
import {Text} from 'react-native-elements';
import {
  styles,
  dynStyleearch,
  dynSearchScreenText,
  numColumns,
  numColumnsGap,
  isDeskTopBrowser,
  MyContext,
} from '../../styles/styles';

import {MyStorage as AsyncStorage} from '../../utilities/MyStorage';
import {USER_STATE} from '../../utilities/Constants';
import {ActionButton} from '../ui/elements';
import {Picker} from '@react-native-picker/picker';

export const Item = ({
  item,
  index,
  selected: selectedInternal,
  addSelectionToPickup,
  onSelect,
}) => {
  const {theme, user, business,countryDetail} = useContext(MyContext);
  const localStyles = StyleSheet.create({
    searchScreenImageItem: {
      height: theme?.screenWidth / (numColumns + numColumnsGap),
      width: theme?.screenWidth / (numColumns + numColumnsGap),
      marginTop: 10,
    },
    update: {
      width: 25,
      height: 25,
      margin: 5,
    },
  });
  return (
    <View style={dynStyleearch(selectedInternal)}>
      <View
        style={[
          styles.searchScreenImageItemWrapper,
          {flexDirection: 'column', alignItems: 'center'},
        ]}>
        <TouchableOpacity onPress={() => onSelect(item)}>
          <Image
            resizeMode="cover"
            source={{uri: item?.details[0].galleryURL}}
            style={[localStyles.searchScreenImageItem]}
            PlaceholderContent={<ActivityIndicator />}
          />
        </TouchableOpacity>
        <View
          style={{
            flexDirection: 'row',
            width: theme?.screenWidth / (numColumns + numColumnsGap),
          }}>
          <View
            style={{
              flexDirection: 'column',
              alignItems: 'flex-start',
              flexWrap: 'wrap',
              marginTop: 10,
              flex: 3,
            }}>
            <TouchableOpacity onPress={() => onSelect(item)}>
              {user &&
              user?.isPrivileged &&
              user?.businessId === business?.id ? (
                <Text style={dynSearchScreenText(index, 0)}>
                  Item #: {item?.details[0].id.substring(0, 5)}
                </Text>
              ) : null}
              <Text style={dynSearchScreenText(index, 0)}>
                {item?.details[0].title}
              </Text>
            </TouchableOpacity>
            {item?.details?.length > 1 && (
              <View style={{flexDirection: 'row', alignItems: 'center'}}>
                {item?.details[item.selectedIndex]?.displayDimensions ? (
                  <Text style={{fontSize: theme?.fonts.ml}}>Please select</Text>
                ) : null}
                <Picker
                  selectedValue={item.selectedValue}
                  style={{height: 30, marginLeft: 10}}
                  onValueChange={(itemValue, selIndex) => {
                    item.selectedIndex = selIndex;
                    item.selectedValue = itemValue;
                    item.price = item.details[selIndex].price;
                    item.internationalPrice = item.details[selIndex].internationalPrice;
                  }}>
                  {item?.details.map((element) => {
                    return (
                      <Picker.Item
                        label={element.displayDimensions}
                        value={element.displayDimensions}
                        key={element.displayDimensions}
                      />
                    );
                  })}
                </Picker>
              </View>
            )}
            <TouchableOpacity onPress={() => onSelect(item)}>
              {item?.details[0].benefits ? (
                <Text style={dynSearchScreenText(index, 0)}>
                  {item?.details[0].benefits}
                </Text>
              ) : null}
              {item?.details[0].displayDimensions ? (
                <Text style={dynSearchScreenText(index, 0)}>
                  {item?.details
                    .reduce(function (res, item) {
                      return res + item.displayDimensions + '/';
                    }, '')
                    .slice(0, -1)}
                </Text>
              ) : null}
              {business && business?.profile?.addPrice ? (
                <Text style={dynSearchScreenText(index, 0)}>
                  {countryDetail.currency === 'USD' ? <Text>&#36;</Text> : (
                        <Text>&#8377; </Text>
                      )}
                  {item?.details
                    .reduce(function (res, item) {
                      return res + (countryDetail.isInternational?  item.internationalPrice:item.price) + '/';
                    }, '')
                    .slice(0, -1)}
                  {business.profile.showUnitMeasure
                    ? '/' +
                      (!item?.unitMeasure
                        ? 'ea'
                        : item?.details[0].unit || item?.details[0].unit !== 1
                        ? item?.details[0].unit +
                          ' ' +
                          item?.details[0].unitMeasure
                        : 'ea')
                    : ''}
                </Text>
              ) : null}

              {user?.isPrivileged ? (
                <Text style={dynSearchScreenText(index, 0)}>
                  Dimensions(L*B*H*W) {item?.details[0].lengthx}*
                  {item?.details[0].breadth}*{item?.details[0].height}*
                  {item?.details[0].weight}
                </Text>
              ) : null}
              {user &&
              user?.isPrivileged &&
              user?.businessId === business?.id ? (
                <Text style={dynSearchScreenText(index, 0)}>
                  Tags: {item?.details[0].tags}
                </Text>
              ) : null}
              {business &&
              business?.profile?.showOutOfStock &&
              business?.profile?.inventory &&
              !item?.quantity > 0 ? (
                <Text
                  style={[dynSearchScreenText(index, 0), styles.outOfStock]}>
                  Out Of Stock
                </Text>
              ) : null}
            </TouchableOpacity>
            <ActionButton
              title="Add to Cart"
              onPress={() => {
                let clonedDetailItemLocal = null;
                clonedDetailItemLocal = JSON.parse(JSON.stringify(item));
                clonedDetailItemLocal.cloned = true;
                clonedDetailItemLocal.selectedQuantity = 1;
                clonedDetailItemLocal.selectedIndex = item.selectedIndex
                  ? item.selectedIndex
                  : 0;
                clonedDetailItemLocal.selectedValue = item.selectedValue
                  ? item.selectedValue
                  : item.details[0].displayDimensions;
                addSelectionToPickup(clonedDetailItemLocal);
              }}
              message="Added to cart"
            />
          </View>
        </View>
      </View>
    </View>
  );
};
