import React from 'react';
import { Text, TouchableOpacity, View, Modal, Image } from 'react-native';
import { ImageViewer as ImageView } from 'react-native-image-zoom-viewer';
import { getFullSizeURL } from '../../utilities/Constants';
import { screenHeight } from '../../styles/styles';

class RetailImageView extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const localStyles = {
      hdrroot: {
        flexDirection: 'row',
        backgroundColor: this.props.theme?.colors?.backgroundColorBlack,
        flexWrap: 'wrap',
        marginLeft: 20,
        marginTop: 20,
      },
      hdrtext: {
        textAlign: 'left',
        paddingTop: 10,
        fontSize: 12,
        color: this.props.theme?.colors?.whiteMessage,
      },
      footer: {
        paddingLeft: 15,
        fontSize: 12,
        height: 20,
        color: this.props.theme?.colors?.whiteMessage,
      },
      hdrcloseButton: {
        width: 48,
        height: 48,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 25,
      },
      hdrcloseText: {
        fontSize: 25,
        color: this.props.theme?.colors?.whiteMessage,
        textAlign: 'center',
        paddingLeft: 16.5,
        paddingRight: 16.5,
        paddingTop: 7.25,
        paddingBottom: 7.25,
      },
      inputText: {
        padding: 5,
        width: 35,
        height: 22,
        textAlign: 'center',
        backgroundColor: this.props.theme?.colors?.whiteBackgroundColor,
        color: this.props.theme?.colors?.secondary,
      },
    };
    return (
      <Modal
        style={{ width: this.props.theme?.screenWidth }}
        visible={this.props.visible}
        transparent={true}
        onRequestClose={() => this.props.setVisible(false)}>
        <View style={{ width: this.props.theme?.screenWidth, flex: 1, alignSelf: 'center' }}>
          <ImageView
            imageUrls={this.props.images?.items[0]?.imageKeys.map((imageKey, i) => {
              let url = getFullSizeURL(this.props.businessId, imageKey);
              return {
                url: url,
                width: this.props.theme?.screenWidth,
                height: screenHeight,
                flex: 1,
                alignSelf: 'center',
              };
            })}
            index={this.props.imageIndex}
            onCancel={() => this.props.setVisible(false)}
            enableImageZoom={false}
            saveToLocalByLongPress={false}
            renderImage={(image) => {
              return (
                <Image
                  resizeMode="contain"
                  source={{ uri: image.source.uri }}
                  style={[
                    {
                      height: screenHeight,
                      width: this.props.theme?.screenWidth,
                      flex: 1,
                    },
                  ]}
                />
              );
            }}
            renderHeader={(imageIndex) => {
              let inCart = false;
              if (this.props.getSelections) {
                let alreadySelected = this.props.getSelections()
                  ? [this.props.getSelections().items]
                  : [];
                alreadySelected[0].some((item) => {
                  if (item.id === this.props.images.items[imageIndex].id) {
                    inCart = true;
                    return;
                  }
                });
              }
              return (
                <View style={[localStyles.hdrroot]}>
                  <View
                    style={{
                      flex: 1,
                      flexDirection: 'row',
                      alignItems: 'flex-end',
                      justifyContent: 'flex-end',
                      alignSelf: 'flex-end',
                    }}>
                    <TouchableOpacity
                      style={[localStyles.hdrcloseButton]}
                      onPress={() => this.props.setVisible(false)}>
                      <Text style={[localStyles.hdrcloseText]}>X</Text>
                    </TouchableOpacity>
                  </View>
                </View>
              );
            }}
            /*
            renderFooters={(imageIndex) =>
              false ? (
                <View>
                  <Text
                    style={{
                      paddingLeft: 15,
                      fontSize: 12,
                      height: 20,
                      color: this.props.theme?.colors?.whiteMessage,
                      bottom: 0,
                    }}>
                    {imageIndex + 1} / {this.props.images.items.length}
                  </Text>
                </View>
              ) : null
            }*/
            enableSwipeDown={true}
          />
        </View>
      </Modal>
    );
  }
}
export default RetailImageView;
