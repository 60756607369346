import {gql} from 'apollo-boost';

export const SEARCH_ORDERS = gql`
  query searchOrders(
    $searchText: String
    $mobileNumber: String
    $status: String!
    $nextToken: String
    $limit: Int
    $businessId: ID
    $id: ID
  ) {
    searchOrders(
      sort: {field: orderSortDate, direction: desc}
      filter: {
        and: [
          {
            or: [
              {mobileNumber: {matchPhrasePrefix: $mobileNumber}}
              {items: {matchPhrasePrefix: $searchText}}
              {id: {matchPhrasePrefix: $id}}
              {status: {eq: $status}}
            ]
          }
          {and: [{businessId: {eq: $businessId}}]}
        ]
      }
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        businessId
        items
        mobileNumber
        price
        note
        businessName
        shippingDetails
        priceDetail
        delivery
        status
        orderSortDate
        createdAt
        updatedAt
        deliveryContact
        deliveryAddress
        isAdminOrder
        adminNote
        externalOrderId
        paymentId
      }
      nextToken
    }
  }
`;
export const SEARCH_ORDERS_UNFILTERED_CUSTOMERS = gql`
  query searchOrders(
    $searchText: String
    $mobileNumber: String!
    $nextToken: String
    $limit: Int
    $businessId: ID
    $id: ID
  ) {
    searchOrders(
      sort: {field: orderSortDate, direction: desc}
      filter: {
        and: [
          {
            or: [
              {items: {matchPhrasePrefix: $searchText}}
              {id: {matchPhrasePrefix: $id}}
            ]
            and: [
              {mobileNumber: {eq: $mobileNumber}}
              {businessId: {eq: $businessId}}
            ]
          }
        ]
      }
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        businessId
        items
        mobileNumber
        price
        note
        businessName
        delivery
        shippingDetails
        priceDetail
        status
        orderSortDate
        createdAt
        updatedAt
        deliveryContact
        deliveryAddress
        isAdminOrder
        adminNote
        externalOrderId
        paymentId
      }
      nextToken
    }
  }
`;
export const SEARCH_ORDERS_ALL_CUSTOMERS = gql`
  query searchOrders(
    $mobileNumber: String!
    $nextToken: String
    $limit: Int
    $businessId: ID
  ) {
    searchOrders(
      sort: {field: orderSortDate, direction: desc}
      filter: {
        and: [
          {
            and: [
              {mobileNumber: {eq: $mobileNumber}}
              {businessId: {eq: $businessId}}
            ]
          }
        ]
      }
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        businessId
        items
        mobileNumber
        price
        note
        businessName
        delivery
        shippingDetails
        priceDetail
        status
        orderSortDate
        createdAt
        updatedAt
        deliveryContact
        deliveryAddress
        isAdminOrder
        adminNote
        externalOrderId
        paymentId
      }
      nextToken
    }
  }
`;
export const SEARCH_ORDERS_CUSTOMERS = gql`
  query searchOrders(
    $searchText: String
    $mobileNumber: String!
    $status: String!
    $nextToken: String
    $limit: Int
    $businessId: ID
    $id: ID
  ) {
    searchOrders(
      sort: {field: orderSortDate, direction: desc}
      filter: {
        or: [
          {items: {matchPhrasePrefix: $searchText}}
          {status: {eq: $status}}
          {id: {matchPhrasePrefix: $id}}
        ]
        and: [
          {mobileNumber: {eq: $mobileNumber}}
          {businessId: {eq: $businessId}}
        ]
      }
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        businessId
        items
        mobileNumber
        price
        note
        businessName
        delivery
        shippingDetails
        priceDetail
        status
        orderSortDate
        createdAt
        updatedAt
        deliveryContact
        deliveryAddress
        isAdminOrder
        adminNote
      }
      nextToken
    }
  }
`;
export const SEARCH_ORDERS_CATCHALL = gql`
  query searchOrders(
    $searchText: String
    $mobileNumber: String
    $PENDING_PAYMENT: String
    $PICKUP_REQUESTED: String
    $READY_FOR_PICKUP: String
    $DELIVERED: String
    $CANCELED: String
    $CANCELATION_REQUESTED: String
    $RETURN_REQUESTED: String
    $RETURNED: String
    $nextToken: String
    $limit: Int
    $id: ID
    $businessId: ID
  ) {
    searchOrders(
      sort: {field: orderSortDate, direction: desc}
      filter: {
        and: [
          {
            or: [
              {mobileNumber: {matchPhrasePrefix: $mobileNumber}}
              {items: {matchPhrasePrefix: $searchText}}
              {id: {matchPhrasePrefix: $id}}
              {
                and: [
                  {status: {ne: $PENDING_PAYMENT}}
                  {status: {ne: $DELIVERED}}
                  {status: {ne: $CANCELED}}
                  {status: {ne: $PICKUP_REQUESTED}}
                  {status: {ne: $READY_FOR_PICKUP}}
                  {status: {ne: $RETURN_REQUESTED}}
                  {status: {ne: $RETURNED}}
                  {status: {ne: $CANCELATION_REQUESTED}}
                ]
              }
            ]
          }
          {and: [{businessId: {eq: $businessId}}]}
        ]
      }
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        businessId
        items
        mobileNumber
        price
        note
        businessName
        shippingDetails
        priceDetail
        delivery
        status
        orderSortDate
        createdAt
        updatedAt
        deliveryContact
        deliveryAddress
        isAdminOrder
        adminNote
        externalOrderId
        paymentId
      }
      nextToken
    }
  }
`;
export const SEARCH_ORDERS_CUSTOMERS_CATCHALL = gql`
  query searchOrders(
    $searchText: String
    $mobileNumber: String!
    $PENDING_PAYMENT: String
    $PICKUP_REQUESTED: String
    $READY_FOR_PICKUP: String
    $DELIVERED: String
    $CANCELED: String
    $CANCELATION_REQUESTED: String
    $RETURN_REQUESTED: String
    $RETURNED: String
    $nextToken: String
    $limit: Int
    $businessId: ID
    $id: ID
  ) {
    searchOrders(
      sort: {field: orderSortDate, direction: desc}
      filter: {
        or: [
          {items: {matchPhrasePrefix: $searchText}}
          {id: {matchPhrasePrefix: $id}}
          {
            and: [
              {status: {ne: $PENDING_PAYMENT}}
              {status: {ne: $DELIVERED}}
              {status: {ne: $CANCELED}}
              {status: {ne: $PICKUP_REQUESTED}}
              {status: {ne: $READY_FOR_PICKUP}}
              {status: {ne: $RETURN_REQUESTED}}
              {status: {ne: $RETURNED}}
              {status: {ne: $CANCELATION_REQUESTED}}
            ]
          }
        ]
        and: [
          {mobileNumber: {eq: $mobileNumber}}
          {businessId: {eq: $businessId}}
        ]
      }
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        businessId
        items
        mobileNumber
        price
        note
        businessName
        delivery
        shippingDetails
        priceDetail
        status
        orderSortDate
        createdAt
        updatedAt
        deliveryContact
        deliveryAddress
        isAdminOrder
        adminNote
        externalOrderId
        paymentId
      }
      nextToken
    }
  }
`;
