import {DefaultTheme} from '@react-navigation/native';
import {Dimensions, Platform, StyleSheet, StatusBar} from 'react-native';
import {colors as RNEColors} from 'react-native-elements';
import * as React from 'react';
import {getStatusBarHeight} from 'react-native-status-bar-height';
import {isDesktop, isMobile} from 'react-device-detect';
import DeviceInfo from 'react-native-device-info';

export const MyContext = React.createContext();
const {width: screenWidthInternal} = Dimensions.get('window');

export const percentScreenShow = 0.98; //former .85

//export const isDeskTopBrowser = false;
export const isDeskTopBrowser =
  !(isMobile || DeviceInfo.getDeviceType() === 'Handset') && isDesktop;
export const contentMargin = 100;

const {height: screenHeightInternal} = Dimensions.get('window');

export const statusBarHeight = getStatusBarHeight();
// Platform.OS === 'ios' ? 44 : StatusBar.currentHeight;
export const headerHeight = Platform.OS === 'ios' ? 44 : 56;
export const screenHeight =
  screenHeightInternal - (statusBarHeight + headerHeight);
const PRODUCT_ITEM_OFFSET = 0;


export const numReviewColumns = isDeskTopBrowser ? 2 : 1;
export const numColumns = isDeskTopBrowser ? 3 : 2;
export const numHomeColumns = isDeskTopBrowser ? 2.5 : 1;
export const numColumnsGap = isDeskTopBrowser ? 0.5 : 0.5;

const palette = {
  purple: '#5A31F4',
  green: '#0ECD9D',
  red: '#CD0E61',
  black: '#0B0B0B',
  white: '#F0F2F3',
};
const pallete2 = {
  primary: '#bdbdbd',
  primaryVariant: '#8d8d8d',
  primaryLight: '#efefef',
  secondary: '#1976d2',
  secondaryVariant: '#004ba0',
  secondaryLight: '#63a4ff',
  backgroundColor: 'transparent',
  surface: 'black',
  error: 'red',
  onPrimary: '#000000',
  onSecondary: '#ffffff',
  onBackground: 'black',
  onSurface: 'black',
  onError: 'white',
};
const useMyTheme = true;
const systemTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme?.colors,
    ...Platform.select({
      default: RNEColors.platform.android,
      ios: RNEColors.platform.ios,
    }),
    info: 'grey',
    input: 'grey',
    whiteMessage: 'white',
    closeButtonWhite: 'white',
    inputText: 'black',
    inputTextBackground: 'transparent',
    backgroundColor: 'transparent',
    searchBarBackgroundColor: 'white',
    backgroundColorEven: 'white',
    backgroundColorOdd: 'white',
    colorItemEven: 'grey',
    colorItemOdd: 'grey',
    label: 'grey',
    modal: 'white',
    highlight: 'yellow',
    placeholder: 'grey',
    submitButton: 'grey',
    icon: 'grey',
    inputBackgound: 'white',
    backgroundColorBlack: 'black',
    buttonBackgroundColorEven: 'grey',
    buttonBackgroundColorOdd: 'grey',
    buttonColorItemEven: 'white',
    buttonColorItemOdd: 'white',
    selecteListItemText: 'white',
    imageHeaderFooterBG: 'black',
    imageBG: 'black',
    touchHighlight: 'orange',
    iconTouchHighlight: 'orange',
    hyperlink: 'blue',
    title: 'black',
  },
};
const MyTheme = {
  dark: false,
  colors: {
    ...DefaultTheme?.colors,
    ...Platform.select({
      default: RNEColors.platform.android,
      ios: RNEColors.platform.ios,
    }),
    ...pallete2,
    primary: '#8BC34A',
    secondary: 'black',
    primaryVariant: 'white',
    primaryVariant2: 'gainsboro',
    primaryLight: 'rgba(139, 195, 74, .7)',
    primaryLight2: 'rgba(139, 195, 74, .7)',
    secondaryVariant: 'green',
    searchBarBackgroundColor: 'gainsboro',
    placeholder: 'grey',
    backgroundColor: 'transparent',
    tabBackgroundColor: '#edf8f2',
    inputTextBackground: 'transparent',
    whiteBackgroundColor: 'white',
    separator: '#CED0CE',
    link: '#8BC34A',
    reghgl: 'orange',
    homeScreenCarousel: '#1c2f3c',
    footer: 'black',
    whiteMessage: 'white',

    card: 'white',
    text: 'white',
    border: 'transparent',
    warning: 'yellow',
    colorItemEven: '#4f5367',
    colorItemOdd: '#4f5367',
    iconTouchHighlight: '#97898a',
    inputText: 'black',
    inputBackgound: 'white',
    greyMessage: 'white',
    info: 'grey',
    imageBG: 'black',
    icon: 'grey',
    transparent: 'transparent',
    underlineColorAndroid: 'transparent',
    buttonBackgroundColorEven: pallete2.secondaryLight,
    buttonBackgroundColorOdd: pallete2.secondaryLight,
    buttonColorItemEven: pallete2.onSecondary,
    buttonColorItemOdd: pallete2.onSecondary,
    touchHighlight: pallete2.secondaryVariant,
  },
  Avatar: {
    rounded: true,
  },
  Badge: {
    textStyle: {fontSize: 30},
  },
  spacing: {
    ss: isMobile ? 3 : 3,
    s: isMobile ? 8 : 8,
    sl: isMobile ? 10 : 10,
    m: isMobile ? 16 : 16,
    l: isMobile ? 24 : 24,
    xl: isMobile ? 40 : 40,
  },
  fonts: {
    s: isMobile ? 10 : 10,
    m: isMobile ? 9 : 12,
    ml: isMobile ? 14 : 14,
    l: isMobile ? 16 : 16,
    xl: isMobile ? 20 : 20,
    xxl: isMobile ? 20 : 20,
    xxxl: isMobile ? 30 : 22,
  },
  textVariants: {
    header: {
      fontFamily: 'Raleway',
      fontSize: 36,
      fontWeight: 'bold',
    },
    body: {
      fontFamily: 'Merriweather',
      fontSize: 16,
    },
  },
};
/**
 * interface theme {
  colors: {
    primary;
    secondary;
    grey0;
    grey1;
    grey2;
    grey3;
    grey4;
    grey5;
    greyOutline;
    searchBg;
    success;
    error;
    warning;
    divider;
    platform: {
      ios: {
        primary;
        secondary;
        success;
        error;
        warning;
      };
      android: {
        // Same as ios
      };
    };
  };
}
 */

export const baseTheme = useMyTheme ? MyTheme : systemTheme;
const theme = baseTheme;
export const darkTheme = {
  ...theme,
  colors: {
    ...theme?.colors,
    background: palette.black,
    foreground: palette.white,
  },
};
export const dateOptions = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

theme.screenWidth = isDeskTopBrowser
  ? screenWidthInternal * percentScreenShow
  : screenWidthInternal - 15;

theme.screenHeight = screenHeightInternal;
export const HeightByWidth = 3 / 4;

export const carouselPicSize = isDeskTopBrowser
  ? theme?.screenWidth / 2
  : theme?.screenWidth;

export const maxColumnWidth = isDeskTopBrowser
  ? theme?.screenWidth / 3
  : theme?.screenWidth;

export const maxInputFieldWidth = isDeskTopBrowser
  ? theme?.screenWidth / 4
  : theme?.screenWidth;
console.log('DeviceInfo.getDeviceType() %s', DeviceInfo.getDeviceType());
console.log('isMobile %s', isMobile);
console.log('isDesktop %s', isDesktop);
console.log('isDeskTopBrowser %s', isDeskTopBrowser);
console.log(
  'screenWidthInternal %s,theme?.screenWidth %s, statusBarHeight %s',
  screenWidthInternal,
  theme?.screenWidth,
  statusBarHeight,
);
export const dynStyle = (index, withWrap) => {
  return withWrap
    ? {
        maxWidth: (theme?.screenWidth * 3) / 4,
        flexWrap: 'wrap',
        fontSize: 12,
        margin: 2,
        color:
          index % 2 === 0
            ? theme?.colors?.colorItemEven
            : theme?.colors?.colorItemOdd,
      }
    : {
        fontSize: 12,
        margin: 2,
        color:
          index % 2 === 0
            ? theme?.colors?.colorItemEven
            : theme?.colors?.colorItemOdd,
      };
};
export const dynStyleearch = (selectedInternal) => {
  return {
    flex: 1,
    ...Platform.select({
      ios: {
        fontWeight: '400',
      },
    }),
    margin: PRODUCT_ITEM_OFFSET * 2,
    borderTopWidth: 0,
    borderBottomWidth: 0,
    color: theme?.colors?.selecteListItem,
    backgroundColor: theme?.colors?.transparent,
  };
};
export const dynSearchScreenText = (index, fonticrement) => {
  return {
    fontSize: 14 + fonticrement,
    flexWrap: 'wrap',
    paddingTop: 10,
    color:
      index % 2 === 0
        ? theme?.colors?.colorItemEven
        : theme?.colors?.colorItemOdd,
  };
};
const openButtonBase = {
  paddingLeft: 0,
  paddingTop: 5,
  backgroundColor: theme?.colors?.highlight,
  color: theme?.colors?.primary,
  fontSize: 12,
  textDecorationLine: 'underline',
};
export const styles = StyleSheet.create({
  container: {
    flex: 1,
    //justifyContent: 'center',
    backgroundColor: theme?.colors?.backgroundColor,
    //backgroundColor: 'black',
  },
  pickerContainer: {
    flex: 0.1,
    paddingTop: 0,
    alignItems: 'center',
  },
  item: {
    flex: 1,
    flexDirection: 'column',
    width: theme?.screenWidth / 3,
    height: theme?.screenWidth / 3,
  },
  close: {
    paddingLeft: 2,
    paddingTop: 0,
    position: 'absolute',
    width: 15,
    height: 15,
    backgroundColor: theme?.colors?.whiteMessage,
    borderRadius: 8,
  },
  imageContainer: {
    flex: 1,
    position: 'relative',
    marginBottom: Platform.select({ios: 0, android: 1}), // Prevent a random Android rendering issue
    backgroundColor: theme?.colors?.backgroundColor,
    borderRadius: 8,
  },
  image: {
    ...StyleSheet.absoluteFillObject,
    resizeMode: 'contain',
    backgroundColor: 'black',
  },
  carouselSafe: {
    flex: 0.192,
  },
  carousel: {
    flex: 1,
    backgroundColor: theme?.colors?.backgroundColor,
    paddingTop: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  carouselItem: {},

  top: {
    flex: 0.05,
  },
  cameraTop: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  camera2wrap: {
    flex: 1.5,
    flexDirection: 'row',
  },
  error: {
    flex: 1,
    paddingLeft: 15,
    fontSize: 8,
    height: 25,
    color: theme?.colors?.error,
    alignContent: 'flex-start',
    flexDirection: 'column',
  },
  errorMsg: {
    padding: 5,
    fontSize: 12,
    color: theme?.colors?.error,
  },
  camera2wrapinternal: {
    flex: 1,
    flexDirection: 'row',
  },
  camera1wrap: {
    flex: 1,
    flexDirection: 'row',
    marginTop: 0,
  },
  camera: {
    width: 25,
    height: 25,
  },
  message: {
    fontSize: 8,
    width: 40,
    height: 25,
    color: theme?.colors?.info,
    alignContent: 'center',
    flexDirection: 'column',
  },

  bottom: {
    flex: 0.708,
  },
  label: {},
  inputContainer: {height: 20},
  input: {height: 10, fontSize: 12},
  formItemMulti: {},
  formSubmit: {},

  picker: {
    height: 40,
    backgroundColor: '#ddd',
    marginBottom: 10,
    padding: 8,
  },
  right: {
    flex: 1,
    justifyContent: 'flex-end',
    paddingLeft: 2,
    backgroundColor: theme?.colors?.backgroundColor,
    alignContent: 'flex-end',
    color: theme?.colors?.primary,
    height: 16,
    marginLeft: theme?.screenWidth - 25,
  },

  home: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignContent: 'center',
  },

  search: {
    position: 'absolute',
    zIndex: 2,
  },
  searchTextTouchable: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  searchText: {
    flex: 1,
    position: 'absolute',
    color: theme?.colors?.grey,
    alignSelf: 'center',
    backgroundColor: 'white',
    //padding: 5,
    fontSize: 16,
  },
  header: {
    flex: 0.085,
    alignItems: 'center',
    alignSelf: 'center',
    height: 50,
    zIndex: 150,
    backgroundColor: 'grey',
  },
  fullpage: {
    flex: 1,
  },
  KeyboardAwareScrollViewStyle: {
    backgroundColor: theme?.colors?.backgroundColor,
  },

  headerComponentStyle: {
    alignItems: 'center',
    backgroundColor: theme?.colors?.whiteMessage,
    paddingBottom: 0,
    paddingTop: 0,
    height: headerHeight,
  },
  imagesListContainer: {
    top: 0,
    margin: 0,
    padding: 0,
    borderTopWidth: 0,
    flex: 1,
    borderBottomWidth: 0,
    flexGrow: 1,
  },

  logo: {
    width: 50,
    height: 50,
  },
  homeImageBG: {
    flex: 1,
    zIndex: 100,
    width: theme?.screenWidth,
  },
  badge: {
    zIndex: 100,
    height: 15,
    width: 15,
    padding: 0,
    minWidth: 15,
  },
  badgeContainer: {
    zIndex: 100,
  },
  badgeText: {
    fontSize: 10,
  },
  owner: {
    flex: 0.1,
    position: 'absolute',
    bottom: 0,
  },
  rightleft: {
    flexDirection: 'row',
    flex: 0.05,
    justifyContent: 'flex-start',
    alignContent: 'center',
    marginBottom: 15,
  },
  Left: {
    height: 16,
  },
  searchCarousel: {
    flex: 1,
    backgroundColor: theme?.colors?.primaryLight,
    paddingTop: 0,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  searchImageContainer: {
    flex: 1,
    position: 'relative',
    marginBottom: Platform.select({ios: 0, android: 0}), // 1 for android Prevent a random Android rendering issue
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    backgroundColor: theme?.colors.transparent,
  },
  searchResultItem: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignContent: 'center',
    backgroundColor: theme?.colors?.primaryLight,
    height: (HeightByWidth) * (carouselPicSize - 100) + 30,
    padding: 0,
  },
  touch: {
    position: 'absolute',
    height: (HeightByWidth) * (carouselPicSize - 100),
    width: carouselPicSize,
    padding: 0,
  },
  searchImage: {
    ...StyleSheet.absoluteFillObject,
    resizeMode: 'contain',
    width: carouselPicSize,
    height: (HeightByWidth) * (carouselPicSize - 100),
  },
  dealHeader: {
    position: 'relative',
    fontSize: 22,
    height: 35,
    color: theme?.colors?.title,
    backgroundColor: theme?.colors?.dark,
    padding: 0,
    textAlign: 'center',
    textAlignVertical: 'center',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  titleText: {
    position: 'relative',
    fontSize: 12,
    height: 30,
    color: theme?.colors?.whiteMessage,
    backgroundColor: theme?.colors?.imageHeaderFooterBG,
    padding: 0,
    textAlign: 'center',
    textAlignVertical: 'center',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  searchCarouselSafe: {
    flex: 1,
  },
  zoom: {
    position: 'absolute',
    top: -5,
    left: theme?.screenWidth / 3 - 37,
  },
  errorMessage: {
    padding: 5,
    fontSize: theme?.fonts?.ml,
    color: theme?.colors?.error,
    flexWrap: 'wrap',
  },
  infoMessage: {
    padding: 5,
    fontSize: theme?.fonts?.ml,
    marginLeft: 10,
    color: theme?.colors?.info,
    flexWrap: 'wrap',
  },
  checkBoxStyle: {
    fontSize: 12,
    color: theme?.colors?.info,
    padding: 0,
    borderBottomColor: 'transparent',
    borderTopColor: 'transparent',
    backgroundColor: 'transparent',
    marginLeft: 0,
    marginRight: 0,
    fontWeight: 'normal',
  },
  orderText: {
    padding: 5,
    fontSize: 8,
    color: theme?.colors?.label,
  },

  listContainerStyle: {
    flex: 0,
    borderWidth: 0,
    marginTop: 20,
  },
  autocompleteIPContainer: {
    marginTop: 20,
    height: 0,
    padding: 0,
    borderBottomColor: 'transparent',
    borderTopColor: 'transparent',
  },
  autocompleteContainer: {
    flexGrow: 1,
    flex: 0,
    flexShrink: 0,
    padding: 0,
    borderWidth: 0,
  },
  footeer: {
    justifyContent: 'center',
    paddingLeft: 15,
    fontSize: 12,
    height: 20,
    color: theme?.colors?.whiteMessage,
  },
  hdrcontainer: {
    flexDirection: 'row',
  },
  searchAddRem: {
    width: 44,
    height: 44,
    margin: 7,
  },
  delete: {
    position: 'absolute',
    width: 25,
    height: 25,
    margin: 0,
    top: 0,
    left: (4 / 5) * theme?.screenWidth - 45,
  },
  update: {
    position: 'absolute',
    width: 25,
    height: 25,
    margin: 0,
    top: 5,
    left: (4 / 5) * theme?.screenWidth - 80,
  },
  hdrcloseButton: {
    width: 48,
    height: 48,
    alignItems: 'center',
    justifyContent: 'center',
  },
  hdrcloseText: {
    fontSize: 25,
    color: theme?.colors?.whiteMessage,
    textAlign: 'center',
    paddingLeft: 16.5,
    paddingRight: 16.5,
    paddingTop: 7.25,
    paddingBottom: 7.25,
  },
  hdrtextbox: {
    marginTop: 10,
    marginLeft: 10,
  },
  hdrtext: {
    textAlign: 'left',
    fontSize: 12,
    color: theme?.colors?.whiteMessage,
  },
  hdrroot: {
    flexDirection: 'row',
    backgroundColor: theme?.colors?.backgroundColorBlack,
    flexWrap: 'wrap',
  },
  left: {},

  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: theme?.colors?.modal,
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  openButton: {
    ...openButtonBase,
  },
  textStyle: {
    color: theme?.colors?.whiteMessage,
    fontWeight: 'bold',
    textAlign: 'right',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
  },
  listContainer: {
    flex: 1,
    padding: PRODUCT_ITEM_OFFSET,
  },
  itemTitle: {
    flex: 1,
    ...Platform.select({
      ios: {
        fontWeight: '400',
      },
    }),
    margin: PRODUCT_ITEM_OFFSET * 2,
  },
  itemFooter: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingTop: PRODUCT_ITEM_OFFSET * 2,
    borderWidth: 0,
    borderTopWidth: StyleSheet.hairlineWidth,
    flexWrap: 'wrap',
  },
  flpicker: {
    height: 50,
    width: theme?.screenWidth - 130,
    position: 'absolute',
    margin: 0,
    padding: 0,
  },
  buttonContainer: {
    paddingTop: 15,
    justifyContent: 'center',
    flexDirection: 'row',
  },
  bottomMessage: {
    fontSize: 18,
    padding: 5,
    paddingLeft: 10,
    textAlign: 'center',
  },
  bottomMessageHighlight: {
    color: theme?.colors?.secondary,
    paddingLeft: 10,
  },
  multiline: {
    borderWidth: 1,
    borderRadius: 5,
    marginBottom: 10,
    marginLeft: 10,
    width: theme?.screenWidth - 20,
    minHeight: 48,
    height: 106,
    maxHeight: 106,
    textAlignVertical: 'top',
    backgroundColor: theme?.colors?.inputTextBackground,
    fontSize: 16,
    paddingHorizontal: 14,
    color: theme?.colors?.inputText,
  },
  singleline: {
    borderWidth: 1,
    borderRadius: 5,
    marginBottom: 10,
    marginLeft: 10,
    width: maxInputFieldWidth - 20,
    height: 48,
    textAlignVertical: 'top',
    backgroundColor: theme?.colors?.inputTextBackground,
    fontSize: 16,
    paddingHorizontal: 14,
    color: theme?.colors?.inputText,
  },
  checkBox: {
    padding: 12,
  },
  companyInfo: {
    color: theme?.colors?.hyperlink,
    textDecorationLine: 'underline',
  },
  companyInfoLink: {
    alignSelf: 'center',
    color: 'white',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: 15,
    fontSize: 16,
    width: theme?.screenWidth,
    justifyContent: 'center',
    textAlign: 'center',
  },
  headerIcon: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  buttonGroupContainerStyle: {
    padding: 2,
    height: 30,
    margin: 5,
  },
  buttonGroupTextStyle: {
    fontSize: 13,
  },
  buttonGroupSelectedButtonStyle: {
    backgroundColor: theme?.colors?.secondary,
  },
  buttonGroupWrapperStyle: {
    flex: 0.2,
    flexDirection: 'column',
  },
  searchOrders: {position: 'relative'},
  solistbuttonStyle: {
    backgroundColor: theme?.colors?.primary,
  },
  solistSelectedButtonStyle: {
    backgroundColor: theme?.colors?.buttonBackgroundColorEven,
  },
  solisttTextStyle: {
    fontSize: 12,
    color: theme?.colors?.buttonColorItemEven,
  },
  solistSelectedTextStyle: {
    fontSize: 12,
    color: theme?.colors?.buttonColorItemEven,
  },
  solistContainerStyle: {
    height: 25,
    margin: 2,
    padding: 2,
  },
  orderItemWrapper: {
    borderTopWidth: 0,
    borderBottomWidth: 0,
    color: theme?.colors?.selecteListItem,
    backgroundColor: theme?.colors?.backgroundColorEven,
  },
  orderItem: {
    borderTopWidth: 0,
    borderBottomWidth: 0,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  orderItemImage: {
    height: theme?.screenWidth / (5 * (numColumns + numColumnsGap)),
    width: theme?.screenWidth / (5 * (numColumns + numColumnsGap)),
    alignItems: 'center',
    marginTop: 10,
  },
  tabStyle: {backgroundColor: theme?.colors?.whiteMessage},
  searchScreenImageItemWrapper: {
    borderTopWidth: 0,
    borderBottomWidth: 0,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  searchScreenImageItem: {
    height: theme?.screenWidth / (numColumns + numColumnsGap),
    width: theme?.screenWidth / (numColumns + numColumnsGap),
    alignItems: 'center',
    marginTop: 10,
  },
  outOfStock: {
    fontSize: theme?.fonts.m,
    color: 'orange',
  },

  searchListSeparatpr: {
    height: 1,
    width: '94%',
    backgroundColor: '#CED0CE',
    marginLeft: '3%',
  },
  tocCheckBoxWrapper: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'center',
    padding: 5,
    marginTop: 15,
    flex: 0.1,
  },
  tocInfo: {
    fontSize: 12,
    color: theme?.colors?.info,
    padding: 0,
    borderBottomColor: 'transparent',
    borderTopColor: 'transparent',
    backgroundColor: 'transparent',
    marginLeft: 0,
    marginRight: 0,
    fontWeight: 'normal',
  },
  tocModalButton: {
    ...openButtonBase,
    backgroundColor: theme?.colors?.primary,
  },
  orderplacement: {
    backgroundColor: theme?.backgroundColor,
    borderBottomColor: '#000000',
    borderBottomWidth: 0,
  },
  pp: {
    textAlign: 'center',
    color: theme?.colors?.primary,
    paddingLeft: 10,
    fontSize: 18,
    padding: 0,
  },
});
