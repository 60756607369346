import {useQuery} from '@apollo/react-hooks';
import {Analytics, API} from 'aws-amplify';
import {
  default as React,
  useState,
  useEffect,
  useCallback,
  useContext,
} from 'react';
import {
  ActivityIndicator,
  FlatList,
  Text,
  View,
  StyleSheet,
} from 'react-native';
import {useScrollToTop} from '@react-navigation/native';
import {updateOrder} from '../../graphql/mutations';
import {
  CUSTOMER_ORDER_ACTIONS,
  getThumbnailURL,
  PRIVIILEGED_ORDER_ACTIONS,
  Status,
  TRANSLATIONS,
  USER_EVENTS,
  USER_STATE,
  StatusMap,
  CancelAllowedStatusCodesStrings,
  ReturnlAllowedStatusCodesStrings,
  ActionMap,
} from '../../utilities/Constants';
import {styles, MyContext} from '../../styles/styles';
import RetailImageView from '../ui/RetailImageView';
import {initializeLocalState} from '../../utilities/InitializeLocalState';
import {
  ActionButton,
  MyButtonGroup,
  MyListMenu,
  MySearchBar,
} from '../ui/elements';
import {MyDataGrid} from '../ui/MyDataGrid';
import {isMobile} from 'react-device-detect';
import {OrderItem} from '../orderItem/OrderItem';
import {
  SEARCH_ORDERS,
  SEARCH_ORDERS_CATCHALL,
  SEARCH_ORDERS_UNFILTERED_CUSTOMERS,
  SEARCH_ORDERS_ALL_CUSTOMERS,
} from '../../queries/Order';
import uuid from 'react-native-uuid';
import {gql} from 'apollo-boost';

function Orders({navigation, transitionState, userState, searchedOrderId}) {
  console.log('Order: ');

  const context = useContext(MyContext);
  const theme = context?.theme;

  const initialImages = {
    items: [],
  };
  const [selected, setSelected] = useState(null);
  const [isImageViewVisible, setIsImageViewVisible] = useState(false);
  const [order, setOrder] = useState();
  const [retailImages, setRetailImages] = useState(initialImages);
  const initiialSearchOrders = {
    data: [],
  };
  const initiialSearchOrdersMeta = {
    loading: false,
    error: null,
    refreshing: false,
    nextToken: null,
    endReached: false,
    pageNumber: 1,
    isFirstRequest: true,
  };
  const [searchOrders, setSearchOrders] = useState(initiialSearchOrders);
  const [searchOrdersMeta, setSearchOrdersMeta] = useState(
    initiialSearchOrdersMeta,
  );
  const [user, setUser] = useState({isCustomer: false, isPrivileged: false});
  const [selectedIndex, setSelectedIndex] = useState();
  const [searchTerm, setSearchTerm] = useState();
  const [orderUpdateSelection, SetOrderUpdateSelection] = useState({});
  const [orderUpdateSelectionAdminNote, SetOrderUpdateSelectionAdminNote] =
    useState({});

  const limit = 500;

  const [business, setBusiness] = useState();
  const [loaded, setLoaded] = useState(false);
  const [tag, setTag] = useState();
  const [tag2, setTag2] = useState();
  const [showList, setShowList] = useState(true);
  const useApollo = false;
  const useLocalSearch = true;

  const [curWebView, setCurWebViewInternal] = useState();
  function setCurWebView(wv) {
    setCurWebViewInternal(wv);
    window.scrollTo(0, 0);
  }
  const ref = React.useRef(null);
  const [selectedRows, setSelectedRowsInternal] = useState();
  const [orderItem, setOrderItem] = useState();

  useScrollToTop(ref);

  useEffect(() => {
    function setIniitialState(businessInternal, userInternal) {
      setSearchTerm(Status[1]);
      setSelected(null);
      setIsImageViewVisible(false);
      setOrder(null);
      setRetailImages(initialImages);
      setSearchOrders(initiialSearchOrders);
      setSearchOrdersMeta(initiialSearchOrdersMeta);
      setSelectedIndex(0);
      setSearchTerm(Status[0]);
      SetOrderUpdateSelection({});
      SetOrderUpdateSelectionAdminNote({});
      setLoaded(false);
    }
    initializeLocalState(setUser, setBusiness, setIniitialState, navigation);
  }, [navigation]);

  useEffect(() => {
    console.log('search by term');
    fetchData(searchTerm);
  }, [business, searchTerm, user]);

  useEffect(() => {
    setSearchTerm(searchedOrderId);
  }, [searchedOrderId]);

  useEffect(() => {
    console.log('search by tag', tag2);
    fetchData(tag2);
  }, [tag2]);

  useEffect(() => {
    if(!orderUpdateSelectionAdminNote?.adminNote && !orderUpdateSelectionAdminNote?.priceDetail)return;
    let orderId = orderUpdateSelectionAdminNote?.id;
    let adminNote = orderUpdateSelectionAdminNote?.adminNote;
    let priceDetail = orderUpdateSelectionAdminNote?.priceDetail;
    try {
      if (!orderId || adminNote === 'undefiined') {
        return;
      }
      API.graphql({
        query: updateOrder,
        variables: {
          input: {
            id: orderId,
            adminNote: adminNote,
            businessId: user?.isPrivileged ? user?.businessId : business?.id,
            priceDetail: priceDetail
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })
        .then((data) => {
          Analytics.record(USER_EVENTS.UPDATE_ORDER);
        })
        .catch((e) => {
          console.log(e);
          throw e;
        });
    } catch (err) {
      console.log('error updating items', err);
      Analytics.record(USER_EVENTS.UPDATE_ORDER_FAILURE);
    }
  }, [business, orderUpdateSelectionAdminNote, user]);

  useEffect(() => {
    let index = orderUpdateSelection.index;
    let orderId = orderUpdateSelection?.id;
    let returnOrder = orderUpdateSelection?.returnOrder;
    let order = orderUpdateSelection?.order; 
    let status = null;
    try {
      if (!orderId || index === 'undefiined') {
        return;
      }
      let statusBeforeTranslatiion = getOrderActions(
        orderUpdateSelection.status,
      )[index];
      status = TRANSLATIONS[statusBeforeTranslatiion];
      if (orderUpdateSelection?.returnOrder) {
        returnOrder = {
          items: [],
          status: StatusMap.NEW,
          createdAt: new Date(),
          id: uuid.v4(),
        };
        JSON.parse(orderItem.items[0]).forEach((item) => {
          if (orderUpdateSelection?.returnOrder[item.id]) {
            let retItem = JSON.parse(JSON.stringify(item));
            retItem.quantity = item.quantity;
            returnOrder.items.push(retItem);
          }
        });
      }
      let shippingDetails = order.shippingDetails;
      if(status == StatusMap.DELIVERED){
        let shippingDetailsJSON = JSON.parse(shippingDetails);
        shippingDetailsJSON.deliveryDate= new Date();
        shippingDetails = JSON.stringify(shippingDetailsJSON);
      }
      API.graphql({
        query: updateOrder,
        variables: {
          input: {
            id: orderId,
            status: status,
            shippingDetails: shippingDetails,
            returnOrder: JSON.stringify(returnOrder),
            businessId: user?.isPrivileged ? user?.businessId : business?.id,
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })
        .then((data) => {
          Analytics.record(USER_EVENTS.UPDATE_ORDER + status);
        })
        .catch((e) => {
          console.log(e);
          throw e;
        });
    } catch (err) {
      console.log('error updating items', err);
      Analytics.record(USER_EVENTS.UPDATE_ORDER_FAILURE + status);
    }
  }, [business, orderUpdateSelection, user]);

  const onSelect = useCallback(
    (index) => {
      setSelected(index);
      var retailImagesInternal = null;
      if (searchOrders.data.length > 0) {
        var imageItems = [...searchOrders?.data[index]?.items];
        retailImagesInternal = {items: JSON.parse(imageItems)};
        setRetailImages(retailImagesInternal);
        setIsImageViewVisible(true);
        setOrder(searchOrders.data[index]);
      }
    },
    [searchOrders.data],
  );
  async function fetchData(searchRequst) {
    console.log('searchRequst: ', searchRequst);
    if (!user?.isCustomer && !user?.isPrivileged) {
      return;
    }
    setSearchOrdersMeta((searchOrdersInternalMeta) => {
      return {
        ...searchOrdersInternalMeta,
        loading: true,
      };
    });
    let searchQuery =
      user?.isCustomer && !user?.isPrivileged
        ? searchRequst !== undefined && searchRequst !== ''
          ? SEARCH_ORDERS_UNFILTERED_CUSTOMERS
          : SEARCH_ORDERS_ALL_CUSTOMERS
        : searchTerm !== StatusMap.PROCESSING
        ? SEARCH_ORDERS
        : SEARCH_ORDERS_CATCHALL;
    let queryVariables =
      user?.isCustomer && !user?.isPrivileged
        ? searchTerm !== StatusMap.PROCESSING
          ? {
              searchText: searchRequst,
              id: searchRequst,
              mobileNumber: user?.mobileNumber,
              status: searchRequst,
              nextToken: searchOrdersMeta.isFirstRequest
                ? null
                : searchOrdersMeta.nextToken,
              businessId: business?.id,
              limit: limit,
            }
          : {
              id: searchRequst,
              searchText: searchRequst,
              mobileNumber: user?.mobileNumber,
              PENDING_PAYMENT: StatusMap.PENDING_PAYMENT,
              DELIVERED: StatusMap.DELIVERED,
              CANCELED: StatusMap.CANCELED,
              READY_FOR_PICKUP: StatusMap.READY_FOR_PICKUP,
              PICKUP_REQUESTED: StatusMap.PICKUP_REQUESTED,
              RETURN_REQUESTED: StatusMap.RETURN_REQUESTED,
              RETURNED: StatusMap.RETURNED,
              CANCELATION_REQUESTED: StatusMap.CANCELATION_REQUESTED,
              nextToken: searchOrdersMeta.isFirstRequest
                ? null
                : searchOrdersMeta.nextToken,
              businessId: business?.id,
              limit: limit,
            }
        : searchTerm !== StatusMap.PROCESSING
        ? {
            id: searchRequst,
            searchText: searchRequst,
            mobileNumber: searchRequst,
            status: searchRequst,
            businessId: user?.businessId,
            nextToken: searchOrdersMeta.isFirstRequest
              ? null
              : searchOrdersMeta.nextToken,
            limit: limit,
          }
        : {
            id: searchRequst,
            searchText: searchRequst,
            mobileNumber: searchRequst,
            PENDING_PAYMENT: StatusMap.PENDING_PAYMENT,
            DELIVERED: StatusMap.DELIVERED,
            CANCELED: StatusMap.CANCELED,
            READY_FOR_PICKUP: StatusMap.READY_FOR_PICKUP,
            PICKUP_REQUESTED: StatusMap.PICKUP_REQUESTED,
            RETURN_REQUESTED: StatusMap.RETURN_REQUESTED,
            RETURNED: StatusMap.RETURNED,
            CANCELATION_REQUESTED: StatusMap.CANCELATION_REQUESTED,
            businessId: user?.businessId,
            nextToken: searchOrdersMeta.isFirstRequest
              ? null
              : searchOrdersMeta.nextToken,
            limit: limit,
          };
    console.log(
      'Existiing searchOrders before fetch',
      searchOrders?.length,
      'business?.id',
      user?.isPrivileged ? user?.businessId : business?.id,
      ' queryVariables ',
      queryVariables,
      ' searchOrdersMeta.isFirstRequest ',
      searchOrdersMeta.isFirstRequest,
    );

    if (useApollo) {
      const {loading, error, data} = useQuery(searchQuery, {
        variables: queryVariables,
      });
      if (loading) {
        return <Text>Loading...</Text>;
      }
      if (error) {
        return <Text>Error...</Text>;
      }

      processFetchedData(data);
    } else if(useLocalSearch){
      if(user?.isCustomer && !user?.isPrivileged){
        query=  gql`
        query listOrders($businessId: ID ,  $mobileNumber: String,  $limit: Int) {
          listOrders(businessId: $businessId,  filter: {mobileNumber: {eq: $mobileNumber}} ,limit: $limit) {          
            nextToken
            items {
              id
              businessId
              items
              mobileNumber
              price
              note
              businessName
              shippingDetails
              priceDetail
              delivery
              status
              orderSortDate
              createdAt
              updatedAt
              deliveryContact
              deliveryAddress
              isAdminOrder
              adminNote
              externalOrderId
              paymentId


            }
          }
        }
        `;
        variables={
          businessId: user?.isPrivileged? user?.businessId : business?.id ,              
          limit: limit,
          mobileNumber: user?.isCustomer && !user?.isPrivileged?user?.mobileNumber: ''
        }

      }else{
        if(searchTerm !== StatusMap.PROCESSING){
        query=gql`
        query listOrders($businessId: ID , $status: String ,$limit: Int) {
          listOrders(businessId: $businessId, filter: {status: {eq: $status}},limit: $limit) {          
            nextToken
            items {
              id
              businessId
              items
              mobileNumber
              price
              note
              businessName
              shippingDetails
              priceDetail
              delivery
              status
              orderSortDate
              createdAt
              updatedAt
              deliveryContact
              deliveryAddress
              isAdminOrder
              adminNote
              externalOrderId
              paymentId
            }
          }
        }
        `;
        variables={
          businessId: user?.isPrivileged? user?.businessId : business?.id ,              
          limit: limit,
          status: searchRequst
        }
      }else{
          query=gql`
           query listOrders($businessId: ID ,$limit: Int ) {
            listOrders(businessId: $businessId,limit: $limit) {          
              nextToken
              items {
                id
                businessId
                items
                mobileNumber
                price
                note
                businessName
                shippingDetails
                priceDetail
                delivery
                status
                orderSortDate
                createdAt
                updatedAt
                deliveryContact
                deliveryAddress
                isAdminOrder
                adminNote
                externalOrderId
                paymentId
              }
            }
          }
          `;
          variables={
            businessId: user?.isPrivileged? user?.businessId : business?.id ,              
            limit: limit
          }
        };
      }
      API.graphql(
        {
          query: query,
          variables: variables,
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        }
      ).then((data) => {
        processFetchedData(data.data);
        console.log(
          'searchTerm',
          searchTerm,
          'fetch resultCount',
          data.data.listOrders.items.length,
          'result  ',
        );
      })
      .catch((e) => {
        console.log(e);
        throw e;
      });
      
    }else {
      API.graphql({
        query: searchQuery,
        variables: queryVariables,
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })
        .then((data) => {
          processFetchedData(data.data);
          console.log(
            'searchTerm',
            searchTerm,
            'fetch resultCount',
            data.data.searchOrders.items.length,
            'result  ',
          );
        })
        .catch((e) => {
          console.log(e);
          throw e;
        });
    }
  }
  function processFetchedData(data) {
    let newOrders = data.listOrders.items;
    let updatedOrders = null;
    let myCurrentDate = new Date();
    let myFutureDate = new Date(myCurrentDate);
    myFutureDate.setDate(myFutureDate.getDate() - 30);
    let pastEpoch = myFutureDate.getTime();
    if (!searchOrdersMeta.isFirstRequest) {
      updatedOrders = [...searchOrders.data];
      newOrders.forEach((element) => {
        let orderDate = Date.parse(element.createdAt);
        let potentialExclude =
          element.status === StatusMap.PENDING_PAYMENT &&
          element.externalOrderId !== null &&
          element.paymentId === null;
        let exclude =
          user.isCustomer && !user.isPrivileged
            ? potentialExclude
            : potentialExclude && orderDate < pastEpoch;
        if (exclude) {
          return;
        }
        let found = false;
        updatedOrders.forEach((element2) => {
          if (element2.id === element.id) {
            found = true;
          }
        });
        if (!found) {
          updatedOrders.push(element);
        }
      });
    } else {
      updatedOrders = newOrders.filter(function (element) {
        let orderDate = Date.parse(element.createdAt);

        let potentialExclude =
          element.status === StatusMap.PENDING_PAYMENT &&
          element.externalOrderId !== null &&
          element.paymentId === null;

        let exclude =
          user.isCustomer && !user.isPrivileged
            ? potentialExclude
            : potentialExclude && orderDate < pastEpoch;

        return !exclude;
      });
    }

    let sortedSorders = [...new Set(updatedOrders)].sort(function (a, b) {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
    setSearchOrders((searchOrdersInternal) => {
      if (searchedOrderId) {
        let rows = {id: searchedOrderId};
        sortedSorders.forEach((element) => {
          if (rows.id === element.id) {
            setSelectedRowsInternal(rows);
            setOrderItem(element);
            return;
          }
        });
      }
      return {
        ...searchOrdersInternal,
        data: sortedSorders,
      };
    });
    setSearchOrdersMeta((searchOrdersInternalMeta) => {
      return {
        ...searchOrdersInternalMeta,
        error: null,
        loading: false,
        refreshing: false,
        endReached: data.listOrders.items.length < limit,
        nextToken: data.listOrders.nextToken,
      };
    });
    console.log('sorting length ', sortedSorders.length);
    if (sortedSorders.length === 0) {
      setSearchOrdersMeta((searchOrdersInternalMeta) => {
        return {
          ...searchOrdersInternalMeta,
          error: 'No orders found. Please try again',
        };
      });
    }
  }
  const getOrderActions = (orderStatus) => {
    if (selectedIndex || selectedIndex === 0) {
      let actions = [];
      if (user?.isPrivileged) {
        actions = PRIVIILEGED_ORDER_ACTIONS[Status[selectedIndex]];
      } else if (business?.profile?.allowCancelation) {
        if (CancelAllowedStatusCodesStrings.includes(orderStatus)) {
          actions =
            CUSTOMER_ORDER_ACTIONS[
              Status[Status.indexOf(StatusMap.PROCESSING)]
            ];
        } else if (ReturnlAllowedStatusCodesStrings.includes(orderStatus)) {
          actions =
            CUSTOMER_ORDER_ACTIONS[Status[Status.indexOf(StatusMap.RETURNED)]];
        }
      }
      if (
        orderStatus &&
        !CancelAllowedStatusCodesStrings.includes(orderStatus)
      ) {
        if (actions.indexOf(StatusMap.CANCELATION_REQUESTED) !== -1) {
          actions.splice(actions.indexOf(StatusMap.CANCELATION_REQUESTED), 1);
        }
      }
      if (
        orderStatus &&
        orderItem.shippingDetails &&
        (!ReturnlAllowedStatusCodesStrings.includes(orderStatus) || !JSON.parse(orderItem.shippingDetails).deliveryDate ||
          new Date() -
            new Date(JSON.parse(orderItem.shippingDetails).deliveryDate) >
            business.profile.returnDaysAfterDelivery * 24 * 3600 * 1000)
      ) {
        if (actions.indexOf(ActionMap.REQUEST_RETURN) !== -1) {
          actions.splice(actions.indexOf(ActionMap.REQUEST_RETURN), 1);
        }
      }
      return actions;
    } else {
      return [];
    }
  };

  const handleRefresh = () => {
    setSearchOrdersMeta((searchOrdersInternalMeta) => {
      return {
        ...searchOrdersInternalMeta,
        refreshing: true,
        pageNumber: 1,
        isFirstRequest: true,
      };
    });
  };

  const handleLoadMore = () => {
    if (!searchOrdersMeta.endReached) {
      setSearchOrdersMeta((searchOrdersInternalMeta) => {
        return {
          ...searchOrdersInternalMeta,
          pageNumber: searchOrdersInternalMeta.pageNumber + 1,
          isFirstRequest: false,
        };
      });
    }
  };

  const localStyles = StyleSheet.create({
    listItem: {
      flex: 1,
      borderColor: 'grey',
      borderWidth: 1,
    },
  });
  const renderSeparator = () => {
    return <View style={styles.searchListSeparatpr} />;
  };
  const setSearchTermInternal = (e) => {
    setSearchTerm(tag);
    setSearchOrdersMeta((searchOrdersInternalMeta) => {
      return {
        ...searchOrdersInternalMeta,
        isFirstRequest: true,
      };
    });
  };

  function renderFooter() {
    if (!searchOrdersMeta.loading) {
      return null;
    }

    return (
      <View
        style={{
          paddingVertical: 20,
          borderTopWidth: 1,
          borderColor: '#CED0CE',
        }}>
        <ActivityIndicator animating size="large" color="grey" />
      </View>
    );
  }
  const dataGridRows = () => {
    return searchOrders.data.map((e) => rowGetter(e));
  };
  function rowGetter(item) {
    return {
      id: item?.id,
      title: JSON.parse(item.items)[0]?.title,
      description: JSON.parse(item.items)[0]?.description,
      status: item?.status,
      price: item.price,
      deliveryContact: JSON.parse(item.deliveryContact)?.name,
      deliveryContactMobile: JSON.parse(item.deliveryContact)
        ?.deliveryContactNumber,
      orderInstructions: item.note,
      orderDate: item.createdAt,
      shippingAddress: JSON.parse(item.deliveryAddress)?.addressLine,
      awbCode: JSON.parse(item.shippingDetails)?.response?.awb_code,
      shipmentId: JSON.parse(item.shippingDetails)?.response?.shipment_id,
      pickupScheduledDate: JSON.parse(item.shippingDetails)?.response
        ?.pickup_scheduled_date,
      carrierName: JSON.parse(item.shippingDetails)?.response?.courier_name,
      pickUpTokenNumber: JSON.parse(item.shippingDetails)?.response
        ?.pickup_token_number,
      printLabel: JSON.parse(item.shippingDetails)?.response?.label_url,
      trackPackage:
        'https://app.shiprocket.in/tracking/awb/' +
        JSON.parse(item.shippingDetails)?.response?.awb_code,
      currency: (JSON.parse(item.priceDetail).currency) === 'USD' ? <Text>&#36;</Text> : (<Text>&#8377; </Text>),
    };
  }

  function handleOnPress(itemIndex) {
    transitionState(USER_STATE.ORDERS);
    setSelectedIndex(itemIndex);
    setSearchTerm(Status[itemIndex]);
    setTag('');
    setSearchOrdersMeta((searchOrdersInternalMeta) => {
      return {
        ...searchOrdersInternalMeta,
        isFirstRequest: true,
      };
    });
  }

  const handleChnageView = () => {
    setShowList((cur) => {
      return !cur;
    });
  };

  function setSelectedRows(rows) {
    setSelectedRowsInternal(rows);
    if (!rows) {
      setOrderItem(null);
      return;
    }
    searchOrders.data.forEach((element) => {
      if (rows.id === element.id) {
        setOrderItem(element);
        return;
      }
    });
  }
  return (
    <View style={{width: '100%'}}>
      {userState !== USER_STATE.PROFILE && (
        <>
          <MySearchBar
            placeholder={business ? business?.profile?.orderSearchText : null}
            onChangeText={setTag}
            value={tag}
            onSubmitEditing={(e) => {
              setTag2(e.nativeEvent.text);
              setSearchOrdersMeta((searchOrdersInternalMeta) => {
                return {
                  ...searchOrdersInternalMeta,
                  isFirstRequest: true,
                };
              });
            }}
            width="80%"
            showLoading={false}
            pageState={searchOrdersMeta.error}
            alignSelf="center"
          />
          <View style={{height: 10}} />
          {user?.isPrivileged && (
            <MyListMenu data={Status} onPress={handleOnPress} />
          )}
          <View>
            {userState === USER_STATE.ORDERS ? (
              <MyDataGrid
                rows={dataGridRows()}
                onRefresh={handleRefresh}
                refreshing={searchOrdersMeta.refreshing}
                onEndReached={handleLoadMore}
                onEndReachedThreshold={0.5}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                transitionState={transitionState}
              />
            ) : (
              <View style={{alignItems: 'center'}}>
                {orderItem && (
                  <OrderItem
                    item={orderItem}
                    index={0}
                    selected={true}
                    onSelect={onSelect}
                    url={getThumbnailURL(
                      user?.isPrivileged ? user?.businessId : business?.id,
                      orderItem &&
                        orderItem?.items &&
                        orderItem?.items.length > 0
                        ? JSON.parse(orderItem?.items)[0]?.imageKey
                        : '',
                    )}
                    getOrderActions={() => getOrderActions(orderItem.status)}
                    SetOrderUpdateSelection={SetOrderUpdateSelection}
                    SetOrderUpdateSelectionAdminNote={
                      SetOrderUpdateSelectionAdminNote
                    }
                    transitionState={transitionState}
                    navigation={navigation}
                  />
                )}
              </View>
            )}
          </View>
          <RetailImageView
            images={retailImages}
            visible={isImageViewVisible}
            setVisible={setIsImageViewVisible}
            businessId={user?.isPrivileged ? user?.businessId : business?.id}
            business={business}
            order={order}
            imageIndex={0}
            theme={theme}
          />
        </>
      )}
    </View>
  );
}

export default Orders;
