import {View} from 'react-native';

import React, {useContext, useEffect} from 'react';
import {useState} from 'react';
import {TouchableOpacity, Image, StyleSheet} from 'react-native';
import {Text} from 'react-native-elements';
import {getFullPageURL, USER_STATE} from '../../utilities/Constants';

import {MyButton, ActionButton, MyText} from '../ui/elements';
import {Picker} from '@react-native-picker/picker';
import {MyIcon} from '../ui/icons';
import {API} from 'aws-amplify';
import {deleteItems} from '../../graphql/mutations';
import {isDeskTopBrowser, HeightByWidth, MyContext} from '../../styles/styles';
import {MyStorage as AsyncStorage} from '../../utilities/MyStorage';
import {MyCarousal, MyLinks} from '../ui/elements';

export const ItemDetail = ({
  business,
  user,
  detailItem,
  theme,
  transitionState,
  setIsImageViewVisible,
  setEditItem,
  addSelectionToPickup,
  selections,
  setImages,
  setImageIndex,
  updateSelection,
}) => {
  const [clonedDetailItem, setClonedDetailItem] = useState();

  useEffect(() => {
    let clonedDetailItemLocal = null;
    if (detailItem && !detailItem.cloned) {
      clonedDetailItemLocal = JSON.parse(JSON.stringify(detailItem));
      clonedDetailItemLocal.cloned = true;
      clonedDetailItemLocal.selectedQuantity = 1;
      clonedDetailItemLocal.selectedIndex = 0;
      clonedDetailItemLocal.selectedValue = clonedDetailItemLocal.details[0].displayDimensions;
      clonedDetailItemLocal.price = clonedDetailItemLocal.details[0].price;
      clonedDetailItemLocal.internationalPrice = clonedDetailItemLocal.details[0].internationalPrice;
    } else {
      clonedDetailItemLocal = detailItem;
    }
    setClonedDetailItem(clonedDetailItemLocal);
  }, [detailItem]);
  const countryDetail = useContext(MyContext).countryDetail;
  const localStyles = StyleSheet.create({
    container: {
      width: '100%',
      flexWrap: 'wrap',
      alignSelf: 'center',
      flexDirection: 'row',
    },
    image: {
      width: isDeskTopBrowser ? theme?.screenWidth / 3 : theme?.screenWidth,
      height:
        HeightByWidth *
        (isDeskTopBrowser ? theme?.screenWidth / 3 : theme?.screenWidth),
    },
    rightBox: {
      flexWrap: 'wrap',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      marginLeft: '5%',
      width: isDeskTopBrowser ? '50%' : '90%',
    },
    buttonsContainer: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      marginTop: 50,
    },
    firstRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    imageContainer: {
      widtth: '50%',
    },
  });
  function onEditSelect(id) {
    setEditItem(id);
    if (id) {
      AsyncStorage.setItem(business.id + ':editItem', id);
    }
    transitionState(USER_STATE.EDIT);
  }
  return (
    <View style={localStyles.container}>
      {clonedDetailItem?.details && (
        <View style={localStyles.imageContainer}>
          {clonedDetailItem?.details[clonedDetailItem.selectedIndex].imageKeys.length<2?(
          <TouchableOpacity
            style={localStyles.image}
            onPress={() => {
              setImages({
                items: [
                  clonedDetailItem?.details[clonedDetailItem.selectedIndex],
                ],
              });
              setImageIndex(0);
              setIsImageViewVisible(true);
              //setSelected(item.id);
              setIsImageViewVisible(true);
            }}>
            <Image
              resizeMode="cover"
              source={{
                uri: getFullPageURL(
                  business?.id,
                  clonedDetailItem?.details[clonedDetailItem.selectedIndex]
                    .imageKey,
                ),
              }}
              style={localStyles.image}
            />
          </TouchableOpacity>
          ):(
            <MyCarousal
              title={null}
              getImageUrl={(item) =>
                getFullPageURL(business.id, item)
              }
              onPress={() => {
                setImages({
                  items: [
                    clonedDetailItem?.details[clonedDetailItem.selectedIndex],
                  ],
                });
                setImageIndex(0);
                setIsImageViewVisible(true);
                //setSelected(item.id);
                setIsImageViewVisible(true);
              }}
              data={clonedDetailItem?.details[clonedDetailItem.selectedIndex].imageKeys}
              getItemHeading={(item) => null}
              showArrows={true}
              loop={false}
              round={false}
              getItemDescription={(item) => null}
              showPagination={false}
              getItemText={(item) => null}
              sliderWidth={isDeskTopBrowser ? theme?.screenWidth / 3 : theme?.screenWidth}
              itemWidth={(isDeskTopBrowser ? theme?.screenWidth / 3 : theme?.screenWidth)-48}
            /> 
            )}
        </View>
      )}
      <View style={localStyles.rightBox}>
        {user && user?.isPrivileged && user?.businessId === business?.id ? (
          <MyText type="reg" content>
            Item #
            {clonedDetailItem?.details[
              clonedDetailItem.selectedIndex
            ].id.substring(0, 5)}
          </MyText>
        ) : null}
        <View style={localStyles.firstRow}>
          <MyText type="lrg">
            {clonedDetailItem?.details[clonedDetailItem.selectedIndex].title}
          </MyText>
          {user &&
          isDeskTopBrowser &&
          user?.isPrivileged &&
          user?.businessId === business?.id ? (
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'flex-end',
                flex: 1,
              }}>
              <View style={[localStyles.update]}>
                <MyIcon
                  name="request-changes"
                  type="octicon"
                  color={theme?.colors?.icon}
                  onPress={() => {
                    console.log(
                      'Updating item :' +
                        clonedDetailItem?.details[
                          clonedDetailItem.selectedIndex
                        ].id,
                    );
                    onEditSelect(
                      clonedDetailItem?.details[clonedDetailItem.selectedIndex]
                        .id,
                    );
                    // navigation.navigate('Inventory', {item: item});
                  }}
                />
              </View>
            </View>
          ) : null}
        </View>
        {clonedDetailItem?.details[clonedDetailItem.selectedIndex]
          .description ? (
          <Text
            style={{
              fontSize: theme?.fonts.ml,
              fontStyle: 'italic',
              marginBottom: 20,
            }}>
            {
              clonedDetailItem?.details[clonedDetailItem.selectedIndex]
                .description
            }
          </Text>
        ) : null}
        {clonedDetailItem?.details[clonedDetailItem.selectedIndex].benefits ? (
          <Text
            style={{
              fontSize: theme?.fonts.ml,
              fontWeight: 'bold',
              marginBottom: 20,
            }}>
            {clonedDetailItem?.details[clonedDetailItem.selectedIndex].benefits}
          </Text>
        ) : null}
        {clonedDetailItem?.details[clonedDetailItem.selectedIndex]
          .ingredients ? (
          <>
            <Text style={{fontSize: theme?.fonts.ml, marginBottom: 20}}>
              <Text
                style={{
                  fontSize: theme?.fonts.ml,
                  fontWeight: 'bold',
                  marginBottom: 20,
                }}>
                Ingredients:&nbsp;
              </Text>
              {
                clonedDetailItem?.details[clonedDetailItem.selectedIndex]
                  .ingredients
              }
            </Text>
          </>
        ) : null}
        {clonedDetailItem?.details[clonedDetailItem.selectedIndex]
          .usageInstructions ? (
          <>
            <Text
              style={{
                fontSize: theme?.fonts.ml,
                fontWeight: 'bold',
                marginBottom: 20,
              }}>
              Usage Instructions
            </Text>
            <Text style={{fontSize: theme?.fonts.ml, marginBottom: 20}}>
              {
                clonedDetailItem?.details[clonedDetailItem.selectedIndex]
                  .usageInstructions
              }
            </Text>
          </>
        ) : null}
        {clonedDetailItem?.details?.length > 1 && (
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            {clonedDetailItem?.details[clonedDetailItem.selectedIndex]
              .displayDimensions ? (
              <Text style={{fontSize: theme?.fonts.ml}}>Please select</Text>
            ) : null}
            <Picker
              selectedValue={clonedDetailItem.selectedValue}
              style={{height: 30, marginLeft: 10}}
              onValueChange={(itemValue, selIndex) => {
                setClonedDetailItem((cur) => {
                  let newClonedDetailItem = {
                    ...cur,
                    selectedIndex: selIndex,
                    selectedValue: itemValue,
                    price: cur.details[selIndex].price,
                  };
                  if (detailItem.cloned) {
                    detailItem.selectedValue = itemValue;
                    detailItem.selectedIndex = selIndex;
                    detailItem.price = detailItem.details[selIndex].price;
                    detailItem.internationalPrice = detailItem.details[selIndex].internationalPrice;
                  }
                  return newClonedDetailItem;
                });
              }}>
              {clonedDetailItem?.details.map((element) => {
                return (
                  <Picker.Item
                    label={element.displayDimensions}
                    value={element.displayDimensions}
                    key={element.displayDimensions}
                  />
                );
              })}
            </Picker>
          </View>
        )}
        {clonedDetailItem?.details[clonedDetailItem.selectedIndex]
          .displayDimensions ? (
          <Text style={{fontSize: theme?.fonts.ml}}>
            {
              clonedDetailItem?.details[clonedDetailItem.selectedIndex]
                .displayDimensions
            }
          </Text>
        ) : null}
        {business && business?.profile?.addPrice ? (
          <MyText type="lrg">
            {countryDetail.currency === 'USD' ? <Text>&#36;</Text> : (
                        <Text>&#8377; </Text>
                      )}
            {countryDetail.isInternational?clonedDetailItem?.details[clonedDetailItem.selectedIndex].internationalPrice:clonedDetailItem?.details[clonedDetailItem.selectedIndex].price}
            {business.profile.showUnitMeasure
              ? '/' +
                (!clonedDetailItem?.details[clonedDetailItem.selectedIndex]
                  .unitMeasure
                  ? 'ea'
                  : clonedDetailItem?.details[clonedDetailItem.selectedIndex]
                      .unit ||
                    clonedDetailItem?.details[clonedDetailItem.selectedIndex]
                      .unit !== 1
                  ? clonedDetailItem?.details[clonedDetailItem.selectedIndex]
                      .unit +
                    ' ' +
                    clonedDetailItem?.details[clonedDetailItem.selectedIndex]
                      .unitMeasure
                  : 'ea')
              : ''}
          </MyText>
        ) : null}
        {user?.isPrivileged ? (
          <MyText type="reg">
            Dimensions(L*B*H*W)
            {clonedDetailItem?.details[clonedDetailItem.selectedIndex].lengthx}*
            {clonedDetailItem?.details[clonedDetailItem.selectedIndex].breadth}*
            {clonedDetailItem?.details[clonedDetailItem.selectedIndex].height}*
            {clonedDetailItem?.details[clonedDetailItem.selectedIndex].weight}
          </MyText>
        ) : null}
        {user && user?.isPrivileged && user?.businessId === business?.id ? (
          <MyText type="reg">
            Tags:
            {clonedDetailItem?.details[clonedDetailItem.selectedIndex].tags}
          </MyText>
        ) : null}
        {business &&
        business?.profile?.showOutOfStock &&
        business?.profile?.inventory &&
        !clonedDetailItem?.details[clonedDetailItem.selectedIndex].quantity >
          0 ? (
          <MyText type="reghgl">Out Of Stock</MyText>
        ) : null}
        <View style={localStyles.buttonsContainer}>
          {detailItem && !detailItem.cloned ? (
            <ActionButton
              title="Add to Cart"
              onPress={() => {
                addSelectionToPickup(clonedDetailItem);
              }}
              message="Added to cart"
            />
          ) : null}
          {selections?.items?.length > 0 ? (
            <ActionButton
              onPress={() => {
                transitionState(USER_STATE.CHECKOUT);
              }}
              title="Proceed to CheckOut"
              marginLeft="10"
            />
          ) : null}
        </View>
      </View>
    </View>
  );
};
