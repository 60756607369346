import {default as React, useState, useContext} from 'react';
import {Text, View, StyleSheet, Image} from 'react-native';
import {MyContext} from '../../styles/styles';
import {MySeparator} from '../ui/elements';
import {FlatList} from 'react-native';
import {set} from 'react-native-reanimated';
import {TextInput} from 'react-native';
import {CheckBox} from 'react-native-elements/dist/checkbox/CheckBox';
import {getFullPageURL} from '../../utilities/Constants';

export const OrderItemPrice = ({
  item,
  returnOrder,
  updateReturnOrder,
  selectReturn,
}) => {
  const {user, business, theme, countryDetail} = useContext(MyContext);

  const localStyles = StyleSheet.create({
    item: {
      flexWrap: 'wrap',
      fontSize: 12,
      margin: 2,
      color: theme?.colors?.colorItemEven,
      justifyContent: 'flex-start',
      flex: 1,
    },
    itemsContainer: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      width: '100%',
      alignItems: 'center',
    },
    checkBoxStyle: {
      fontSize: 8,
      color: theme?.colors?.info,
      padding: 0,
      borderBottomColor: 'transparent',
      borderTopColor: 'transparent',
      backgroundColor: 'transparent',
      fontWeight: 'normal',
      flex: 1,
    },
    qty: {
      flexWrap: 'wrap',
      fontSize: 12,
      margin: 20,
      color: theme?.colors?.colorItemEven,
      flex: 1,
      borderWidth: 1,
      maxWidth: 20,
    },
    itemImage: {
      flexWrap: 'wrap',
      fontSize: 12,
      margin: 1,
      justifyContent: 'flex-start',
      flex: 1,
      resizeMode: 'contain',
      height: 60,
      backgroundColor: theme.colors.secondary,
    },
  });

  return (
    <>
      <MySeparator />
      <View style={[localStyles.itemsContainer]}>
        {selectReturn && <Text style={localStyles.item}>Return</Text>}
        <Text style={localStyles.item}>Image</Text>
        <Text style={localStyles.item}>Product</Text>
        <Text style={localStyles.item}>Quantity</Text>
        {selectReturn && <Text style={localStyles.item}>Return Quantity</Text>}
        <Text style={localStyles.item}>
          Price (
          {item?.priceDetail && JSON.parse(item?.priceDetail)?.currency === 'USD' ?<Text>&#36;</Text>:(<Text>&#8377;</Text>)}
          )
        </Text>
      </View>
      <MySeparator />
      <FlatList
        data={JSON.parse(item?.items?item?.items:"[]")}
        renderItem={(element) => (
          <>
            <View style={[localStyles.itemsContainer]}>
              {selectReturn && (
                <CheckBox
                  textStyle={localStyles.checkBoxStyle}
                  containerStyle={{padding: 0, margin: 0, flex: 1}}
                  checkedColor="grey"
                  title=""
                  checkedIcon="dot-circle-o"
                  uncheckedIcon="circle-o"
                  checked={returnOrder && returnOrder[element?.item?.id] > 0}
                  onPress={() =>
                    updateReturnOrder(
                      element.item.id,
                      returnOrder && returnOrder[element?.item?.id] > 0
                        ? 0
                        : element?.item?.quantity,
                    )
                  }
                />
              )}
            <Image
              resizeMode="contain"
              source={{
                uri: getFullPageURL(
                  business?.id,
                  element?.item?.imageKey,
                ),
              }}
              style={localStyles.itemImage}
            />
              <Text style={localStyles.item}>{element?.item?.title}</Text>
              <Text style={localStyles.item}>{element?.item?.quantity}@{element?.item?.price}</Text>
              {selectReturn && (
                <TextInput
                  editable
                  maxLength={3}
                  style={localStyles.qty}
                  value={returnOrder && returnOrder[element?.item?.id]}
                  onChangeText={(newval) => {
                    updateReturnOrder(
                      element?.item?.id,
                      newval <= element?.item?.quantity
                        ? newval
                        : element?.item?.quantity,
                    );
                  }}
                />
              )}
              <Text style={localStyles.item}>{element?.item?.quantity * (element?.item?.price)}</Text>
            </View>
          </>
        )}
        bounces={false}
        keyExtractor={(element) => element.id}
        numColumns={1}
      />
      <MySeparator />
      {!selectReturn &&
        item.priceDetail &&
        JSON.parse(item.priceDetail)?.itemsTotal >=0 && (
          <View style={[localStyles.itemsContainer]}>
            <Text style={[localStyles.item]} />
            <Text style={[localStyles.item]}>SubTotal</Text>
            <Text style={[localStyles.item]}>
              {JSON.parse(item.priceDetail)?.itemsTotal}
            </Text>
          </View>
        )}
      {!selectReturn &&
        item.priceDetail &&
        (JSON.parse(item.priceDetail)?.discount>0) && (
          <View style={[localStyles.itemsContainer]}>
            <Text style={[localStyles.item]} />
            <Text style={[localStyles.item]}>Discount</Text>
            <Text style={[localStyles.item]}>
              {JSON.parse(item.priceDetail)?.discount}
            </Text>
          </View>
        )
      }
      {!selectReturn &&
        item.priceDetail &&
        (JSON.parse(item.priceDetail)?.shippingHandling ||
          JSON.parse(item.priceDetail)?.shippingHandling === 0) && (
          <View style={[localStyles.itemsContainer]}>
            <Text style={[localStyles.item]} />
            <Text style={[localStyles.item]}>
              {JSON.parse(item.priceDetail)?.shippingHandling >= 0
                ? 'Shipping'
                : JSON.parse(item.priceDetail)?.shippingHandling < 0
                ? 'Discount'
                : ''}
            </Text>
            <Text style={[localStyles.item]}>
              {JSON.parse(item.priceDetail)?.shippingHandling}
            </Text>
          </View>
        )
      }
      {false && !selectReturn &&
        item.priceDetail &&
        (
          <View style={[localStyles.itemsContainer]}>
            <Text style={[localStyles.item]} />
            <Text style={[localStyles.item]}>GST</Text>
            <Text style={[localStyles.item]}>
              {JSON.parse(item.priceDetail)?.GST}
            </Text>
          </View>
        )
      }
      <MySeparator />
      {!selectReturn &&
        item.priceDetail &&
        JSON.parse(item.priceDetail)?.totalPrice >=0 && (
          <View style={[localStyles.itemsContainer]}>
            <Text style={[localStyles.item]} />
            <Text style={[localStyles.item]}>Total</Text>
            <Text style={[localStyles.item]}>
              {JSON.parse(item.priceDetail)?.totalPrice}
            </Text>
          </View>
        )}
      <MySeparator />
    </>
  );
};
