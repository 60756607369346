import {Text, View, Image, StyleSheet} from 'react-native';
import {useScrollToTop} from '@react-navigation/native';
import {
  HeightByWidth,
  isDeskTopBrowser,
  numHomeColumns,
  styles,
} from '../../styles/styles';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import {MyStatusBar} from '../ui/MyStatusBar';
import {default as React, useState, useEffect, useContext} from 'react';
import {initializeLocalState} from '../../utilities/InitializeLocalState';
import {cloudFrontURL} from '../../utilities/profile';
import {Footer} from '../footer/Footer';
import {MyContext} from '../../styles/styles';
import {MyLinks} from '../ui/elements';
import {isMobile} from 'react-device-detect';

export default function About({navigation}) {
  const [about, setAbout] = useState({
    contactInfo: '',
  });
  const [user, setUser] = useState({isCustomer: false, isPrivileged: false});
  const [business, setBusiness] = useState();
  const ref = React.useRef(null);
  const [curWebView, setCurWebViewInternal] = React.useState();
  const theme = useContext(MyContext)?.theme;
  function setCurWebView(wv) {
    setCurWebViewInternal(wv);
    window.scrollTo(0, 0);
  }
  useScrollToTop(ref);

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', (e) => {
      setCurWebView(null);
    });
    function setIniitialState(businessInternal, userInternal) {}
    initializeLocalState(setUser, setBusiness, setIniitialState, navigation);
  }, [navigation]);

  const localStyles = StyleSheet.create({
    infoHeadingHighLight: {
      padding: 5,
      fontSize: 22,
      marginLeft: 10,
      color: theme?.colors?.primary,
      fontStyle: 'italic',
    },
    itemImage: {
      height: (HeightByWidth * theme?.screenWidth) / numHomeColumns,
      width: theme?.screenWidth / numHomeColumns,
      alignSelf: 'center',
    },
    itemRowContainer: {
      flexDirection: 'row',
      width: '100%',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 20,
    },
    infoMessage: {
      fontSize: isMobile ? theme?.fonts?.ml : theme?.fonts?.xxxl,
      color: theme?.colors?.info,
      marginTop: 20,
      marginBottom: 20,
      marginLeft: isMobile ? 0 : 20,
      marginRight: isMobile ? 0 : 20,
    },
  });

  return (
    <KeyboardAwareScrollView
      contentContainerStyle={[styles.KeyboardAwareScrollViewStyle]}
      scrollEnabled
      ref={ref}
      showsVerticalScrollIndicator={true}
      automaticallyAdjustContentInsets={false}
      resetScrollToCoords={{x: 0, y: 0}}
      keyboardDismissMode="on-drag"
      keyboardShouldPersistTaps="always">
      {curWebView ? (
        <MyLinks
          business={business}
          curWebView={curWebView}
          setCurWebView={setCurWebView}
        />
      ) : (
        <View style={{flex: 1, flexDirection: 'column'}}>
          <View style={{flex: 1, flexDirection: 'row'}}>
            <View style={{flexDirection: 'column', width: '100%'}}>
              <View style={localStyles.itemRowContainer}>
                <Text style={[localStyles.infoHeadingHighLight]}>
                  {business?.profile?.about.para1.aboutUsTextH}
                </Text>
                <Text style={[localStyles.infoMessage]}>
                  {business?.profile?.about.para1.aboutUsText}
                </Text>
                <Image
                  resizeMode="contain"
                  source={{
                    uri:
                      cloudFrontURL + business?.profile.about.para1.aboutUsUrl,
                  }}
                  style={localStyles.itemImage}
                />
              </View>
              <View style={localStyles.itemRowContainer}>
                <Text style={[localStyles.infoHeadingHighLight]}>
                  {business?.profile?.about.para2.aboutUsTextH}
                </Text>

                <Text style={[localStyles.infoMessage]}>
                  {business?.profile?.about.para2.aboutUsText}
                </Text>
                <Image
                  resizeMode="contain"
                  source={{
                    uri:
                      cloudFrontURL + business?.profile.about.para2.aboutUsUrl,
                  }}
                  style={localStyles.itemImage}
                />
              </View>
              <View style={localStyles.itemRowContainer}>
                <Text style={[localStyles.infoHeadingHighLight]}>
                  {business?.profile?.about.para3.aboutUsTextH}
                </Text>

                <Text style={[localStyles.infoMessage]}>
                  {business?.profile?.about.para3.aboutUsText}
                </Text>
                <Image
                  resizeMode="contain"
                  source={{
                    uri:
                      cloudFrontURL + business?.profile.about.para3.aboutUsUrl,
                  }}
                  style={localStyles.itemImage}
                />
              </View>
            </View>
          </View>
        </View>
      )}
      <Footer setCurWebView={setCurWebView} />
    </KeyboardAwareScrollView>
  );
}
