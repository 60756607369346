import {gql} from 'apollo-boost';
import {API} from 'aws-amplify';
import Fuse from 'fuse.js';
import React, {useState, useEffect, useContext} from 'react';
import {
  ActivityIndicator,
  FlatList,
  TouchableHighlight,
  TouchableOpacity,
  View,
} from 'react-native';
import {Hub} from 'aws-amplify';
import {Text} from 'react-native-elements';
import {MyCarousal, MyLinks} from './ui/elements';
import {deleteItems, updateItems} from '../graphql/mutations';
import {
  ALL_PRODUCTS,
  CART_SUFFIX,
  getCategoryURL,
  getFullPageURL,
  getGalleryURL,
  getThumbnailURL,
  HISTORY_PREFIX,
  USER_STATE,
  USER_STATE_PREFIX,
} from '../utilities/Constants';
import {MyStorage as AsyncStorage} from '../utilities/MyStorage';
import {SingletonApolloClient, updateHistory} from '../utilities/RetailClient';
import {styles, MyContext, numColumns} from '../styles/styles';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import {MyStatusBar} from './ui/MyStatusBar';

import RetailImageView from './ui/RetailImageView';
import {initializeLocalState} from '../utilities/InitializeLocalState';
import {CheckOut} from './checkout/CheckOut';
import {ShoppingCart} from './shoppingCart/ShoppingCart';
import {Footer} from './footer/Footer';
import {ItemDetail} from './itemDetail/ItemDetail';
import {EditItem} from './editItem/EditItem';
import {Item} from './item/Item';
import {useScrollToTop} from '@react-navigation/native';
import I18n from './../I18n/I18n';
import {
  allPriceRange,
  apolloSearch,
  catchAll,
  ignorePriceRange,
  orderItemSearch,
  noFilter,
  listItems,
} from './../queries/Search';
import {Reviews} from './feedback/Reviews';
import {isMobile} from 'react-device-detect';

function SearchScreen({route, navigation}) {
  console.log('route: ', JSON.stringify(route));
  const highLightString = 'HighLight';
  const dealOfTheDayString = 'DealOfTheDay';
  const useApollo = false;
  const useOpenSearch = false;
  const theme = useContext(MyContext)?.theme;
  const countryDetail = useContext(MyContext)?.countryDetail;

  const initialPageState = {
    errorMessage: '',
    searched: true,
    hideResults: true,
    searchResultMessage: '',
  };

  const initialImages = {
    items: [],
  };
  const initialImagesMeta = {
    refreshing: false,
    nextToken: null,
    endReached: false,
  };

  const [showSearchBar, setShowSearchBar] = useState(true);

  const initialRange = {val: 0, margin: 0.25};
  const [searchResults, setSearchResults] = useState(initialImages);
  const [searchResultsMeta, setSearchResultsMeta] = useState(initialImagesMeta);
  const [allProducts, setAllProducts] = useState();
  const [allCategories, setAllCategories] = useState();
  const [highlights, setHighlights] = useState(initialImages);
  const [dealsOfTheDay, setDealsOfTheDay] = useState(initialImages);  
  const [highlightsTrigger, setHighlightsTrigger] = useState();
  const [dealsOfTheDayTrigger, setDealsOfTheDayTrigger] = useState();
  const [selections, setSelections] = useState(initialImages);
  const [images, setImages] = useState(initialImages);
  const [selected, setSelected] = useState(null);
  const [range, setRange] = useState(initialRange);

  const [pageState, setPageState] = useState(initialPageState);
  const [searchText, setSearchTextInternal] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [detailItem, setDetailItem] = useState();
  const options = {
    // isCaseSensitive: false,
    // includeScore: false,
    // shouldSort: true,
    // includeMatches: false,
    // findAllMatches: false,
    //minMatchCharLength: 1,
    minMatchCharLength: 2,
    // location: 0,
    // threshold: 0.6,
    threshold: 0.6,
    // distance: 100,
    // useExtendedSearch: false,
    keys: ['name'],
  };
  const sesrchFuseOptions = {
    minMatchCharLength: 3,
    threshold: 0.3,
    keys: [        
      'id',
      'title',
      'category',
      'benefits',
      'ingredients',
      'usageInstructions',
      'subCategory',
      'tags'
    ]
  };
  const [tag, setTag] = useState('');
  const [fuse, setFuse] = useState(new Fuse([], options));

  const [isImageViewVisible, setIsImageViewVisible] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  const [hasResults, setHasResults] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userState, setUserState] = useState(
    AsyncStorage.getItem(USER_STATE_PREFIX + route.name)
      ? AsyncStorage.getItem(USER_STATE_PREFIX + route.name)
      : USER_STATE.DEFAULT,
  );
  const [isFirstRequest, setIsFirstRequest] = useState(true);
  const [itemCategories, setItemCategories] = useState([]);
  const maxResults = 200;
  const ref = React.useRef(null);
  const [editItem, setEditItem] = useState();

  const [curWebView, setCurWebViewInternal] = useState();
  const SAVED_STATE = 'SAVED_STATE';
  function setCurWebView(wv) {
    setCurWebViewInternal(wv);
    window.scrollTo(0, 0);
  }
  useScrollToTop(ref);
  useEffect(() => {
    console.log('useEffect 1');
    window.scrollTo(0, 0);
    setPageState((prevState) => {
      return {
        ...prevState,
        hideResults: true,
        errorMessage: '',
      };
    });
    if(route.params.order)populateSelections(route.params.order);
  }, [userState, route, navigation]);
  useEffect(() => {
    const unsubscribe2 = navigation.addListener('focus', (e) => {
      setCurWebView(null);
    });
    console.log('useEffect 2', navigation);
    const unsubscribe = navigation.addListener('tabPress', (e) => {
      transitionState(USER_STATE.LANDING);
      //setSearchText(highLightString);
    });
    function setIniitialState(businessInternal, userInternal) {
      console.log('setIniitialState');
      const savedState = AsyncStorage.getItem(USER_STATE_PREFIX + route.name);
      const savedStateSearchText = AsyncStorage.getItem('searchText');
      AsyncStorage.removeItem('searchText');
      transitionState(
        savedState
          ? savedState
          : savedStateSearchText
          ? USER_STATE.SEARCH
          : USER_STATE.LANDING,
      );
      setSearchResults(initialImages);
      setSelections(initialImages);
      setImages(initialImages);
      setPageState(initialPageState);
      setTag('');
      setFuse(new Fuse([], options));
      setIsImageViewVisible(false);
      setImageIndex(0);
      setHasResults(false);
      setLoading(false);
      setHighlights(initialImages);
      setDealsOfTheDay(initialImages);
      setRange(initialRange);
      if (businessInternal) {
        let selectionsInternal = AsyncStorage.getItem(
          businessInternal.id + CART_SUFFIX,
        );
        if (selectionsInternal) {
          setSelections(selectionsInternal);
        } else {
          setSelections(initialImages);
        }
        let detailItemLocal = AsyncStorage.getItem(
          businessInternal.id + ':detailItem',
        );
        if (detailItemLocal) {
          setDetailItem(JSON.parse(detailItemLocal));
        }

        let editItemLocal = AsyncStorage.getItem(
          businessInternal.id + ':editItem',
        );
        if (editItemLocal) {
          setEditItem(editItemLocal);
        }

        setIsFirstRequest(true);

        if (savedStateSearchText) {
          setSearchText(SAVED_STATE + savedStateSearchText);
        } /*else {
          setSearchText(highLightString);
        }*/
        let parsedTags = JSON.parse(businessInternal?.tags);
        setFuse(new Fuse(parsedTags, options));
        let ic = [];
        businessInternal?.profile?.categories?.forEach((item) => {
          ic.push(item.name);
        });
        setItemCategories(ic);
      }
    }
    initializeLocalState(setUser, setBusiness, setIniitialState, navigation);
  }, [navigation]);

  useEffect(() => {
    console.log('useEffect 3', route);
    if (route?.params?.returnState) {
      transitionStateInternal(route.params.returnState);
    } else if (route?.params?.userState) {
      transitionState(route.params.userState, route.params.skipHistory);
      if (route?.params?.searchText) {
        setSearchText(route?.params?.searchText);
      }
    }
    if (route.params.search) {
      setSearchText(route.params.search);
      //setUserState(USER_STATE.DETAIL);
    }
  }, [route]);

  const [user, setUser] = useState();
  const [business, setBusiness] = useState();
  const Client = SingletonApolloClient.getInstance(null);

  function updateTag(tagVal) {
    setTag(tagVal);
    setPageState((prevState) => {
      return {
        ...prevState,
        hideResults: false,
        errorMessage: '',
      };
    });
  }

  const populateSelections =  (order) => {
    let items = JSON.parse(order.items);
    items.forEach(it =>{
      populateSelectionsInternal(order,it);
    })
  }

  async function populateSelectionsInternal(order, it){
    let localItems = []
    let result =  await Client.query({
      query: orderItemSearch,
      variables: {
        parent: it.id,
        id: it.id,
        businessId: order.businessId,
        nextToken: null
      },
    });
    let items = result.data.searchItems.items;
    localItems=processSearchInternal(items, localItems, order.businessId);
    localItems.forEach((itm)=>{
      itm.selectedQuantity = it.quantity;
      itm.selectedIndex=0
      addSelectionToPickup(itm);
    })
  }

  function setSearchText(text) {
    setSearchTextInternal(text);
    setCurWebView(null);
    if (
      text !== highLightString &&
      text !== dealOfTheDayString &&
      text.indexOf(SAVED_STATE) === -1
    ) {
      AsyncStorage.setItem('searchText', text);
    }
  }

  function getSelections() {
    return selections;
  }
  const fullpage = 'fullpage/';

  function transitionStateInternal(state) {
    console.log('setting state ', state);
    switch (state) {
      case USER_STATE.LANDING:
        setUserState(USER_STATE.LANDING);
        break;
      case USER_STATE.SEARCH:
        setUserState(USER_STATE.SEARCH);
        setPageState((prevState) => {
          return {
            ...prevState,
            errorMessage: '',
          };
        });
        break;
      case USER_STATE.CART:
        setUserState(USER_STATE.CART);
        setPageState((prevState) => {
          return {
            ...prevState,
            errorMessage: '',
          };
        });
        break;
      case USER_STATE.DETAIL:
        setUserState(USER_STATE.DETAIL);
        setPageState((prevState) => {
          return {
            ...prevState,
            errorMessage: '',
          };
        });
        break;
      case USER_STATE.CHECKOUT:
        setUserState(USER_STATE.CHECKOUT);
        setPageState((prevState) => {
          return {
            ...prevState,
            errorMessage: '',
          };
        });
        break;
      case USER_STATE.EDIT:
        setUserState(USER_STATE.EDIT);
        setPageState((prevState) => {
          return {
            ...prevState,
            errorMessage: '',
          };
        });
        break;
      case USER_STATE.REVIEW:
        setUserState(USER_STATE.REVIEW);
        setPageState((prevState) => {
          return {
            ...prevState,
            errorMessage: '',
          };
        });
        break;
      case USER_STATE.PAYMENT_SUCCESS:
        setUserState(USER_STATE.PAYMENT_SUCCESS);
        setPageState((prevState) => {
          return {
            ...prevState,
            errorMessage: '',
          };
        });
        break;
      case USER_STATE.DEFAULT:
        break;
    }
  }
  function transitionState(newState, skipHistory) {
    transitionStateInternal(newState);
    updateHistory(route.name, route.name, newState, skipHistory);
  }

  useEffect(() => {
    if(business){
      console.log('useEffect 4.1',"business:",business);
      searchPhotosForUIInput(ALL_PRODUCTS);
      setHighlightsTrigger(highLightString);
      if (business?.profile?.dealOfTheDay) {
        setDealsOfTheDayTrigger(dealOfTheDayString);
      }
    }
  }, [business, user]);

  useEffect(() => {
    if(business){
      console.log('useEffect 4.2');
      searchPhotosForUIInput(dealOfTheDayString);
    }
  }, [dealsOfTheDayTrigger]);

  useEffect(() => {
    if(business){
      console.log('useEffect 4.3');
      searchPhotosForUIInput(highLightString);
    }
  }, [highlightsTrigger]);

  useEffect(() => {
    if(business){
      console.log('useEffect 4.4');
      searchText && searchPhotosForUIInput(searchText);
    }
  }, [pageNumber, searchText, isFirstRequest, range]);

  async function searchPhotosForUIInput(searchText) {
    let businessPassed = business;
    let isNotFirstRequest =
      searchText === highLightString || searchText === dealOfTheDayString
        ? false
        : !isFirstRequest;
    let ignorePriceRangeInternal =
      searchText === highLightString || searchText === dealOfTheDayString
        ? true
        : business?.profile?.priceRange
        ? false
        : true;
    let rangePassed = range;
    setLoading(true);
    let rangeToBeUsed = ignorePriceRangeInternal
      ? 0
      : rangePassed
      ? rangePassed
      : range.val;
    let time = new Date().getTime();

    let tagValue =
      searchText?.indexOf(SAVED_STATE) === -1
        ? searchText
        : searchText.substring(SAVED_STATE.length, searchText.length);

    setPageState((prevState) => {
      return {
        ...prevState,
        errorMessage: '',
        searched: false,
        hideResults: true,
      };
    });
    console.log(
      'Searching items.  tagValue: ',
      tagValue,
      'businessPassed',
      businessPassed?.id,
      'isNotFirstRequest',
      isNotFirstRequest,
      'ignorePriceRange',
      ignorePriceRangeInternal,
      'rangePassed',
      rangePassed,
      'rangeToBeUsed',
      rangeToBeUsed,
      'nextToken',
      searchResults?.nextToken,
    );

    let items = [];
    console.log('useOpenSearch',useOpenSearch);
    if(!useOpenSearch){
      isNotFirstRequest=false;
      let allItems = [];
      let categories = [];
      if(!allProducts?.length>0){
        let result = await API.graphql({
          query: listItems,
          variables: {
            businessId: businessPassed ? businessPassed.id : business?.id,
            nextToken: null,
          },
          authMode: 'API_KEY',
        });
        allItems = result.data.listItems.items;
        setAllProducts(allItems);
        business?.profile?.categories.forEach( (ct)=>{
          categories.push(ct.name);
          }
        );
        setAllCategories(categories);
      }else {
        allItems=allProducts;
        categories = allCategories;
      }

      //let optionsUsed = (tagValue===dealOfTheDayString || tagValue===highLightString)?sesrchFuseOptionsTags : business?.profile?.categories.includes(tagValue)? sesrchFuseOptionsCategory: sesrchFuseOptions;
      if(tagValue===dealOfTheDayString || tagValue===highLightString){
        allItems.forEach((fi)=>{
              if(fi.tags.length>0 &&  fi.tags[0].indexOf(tagValue)!= -1)items.push(fi);
        });
      }else if(categories.includes(tagValue)){
        allItems.forEach((fi)=>{
          if(fi.category.indexOf(tagValue)!= -1 || 'All Products'===tagValue || (fi.tags.length>0 &&  fi.tags[0].indexOf(tagValue)!= -1))items.push(fi);
        });
      }else {
        let fuse = new Fuse(allItems,sesrchFuseOptions);
        let filteredItems  = fuse.search(tagValue);
        filteredItems.forEach((fi) => {
          items.push(fi.item);
        });
      }
      console.log('processSearchResultsos','tagValue', tagValue, ' items ', items);
    }else{
      let result = null;
    if (tagValue === ALL_PRODUCTS) {
      result = await Client.query({
        query: noFilter,
        variables: {
          businessId: businessPassed ? businessPassed.id : business?.id,
          nextToken: isNotFirstRequest ? searchResultsMeta.nextToken : null,
        },
      });
    } else if (useApollo) {
      result = await Client.query({
        query: apolloSearch,
        variables: {
          tag: tagValue,
          id: tagValue,
          businessId: businessPassed ? businessPassed.id : business?.id,
          nextToken: isNotFirstRequest ? searchResultsMeta.nextToken : null,
        },
      });
    } else if (ignorePriceRangeInternal) {
      result = await API.graphql({
        query: ignorePriceRange,
        variables: {
          tag: tagValue,
          id: tagValue,
          businessId: businessPassed ? businessPassed.id : business?.id,
          nextToken: isNotFirstRequest ? searchResultsMeta.nextToken : null,
          //         quantity: businessPassed?(businessPassed?.profile?.inventory?1:0):(business?.profile?.inventory?1:0)
        },
        authMode: 'API_KEY',
      });
    } else if (range.val === 0) {
      result = await API.graphql({
        query: allPriceRange,
        variables: {
          tag: tagValue,
          id: tagValue,
          businessId: businessPassed ? businessPassed.id : business?.id,
          nextToken: isNotFirstRequest ? searchResultsMeta.nextToken : null,
          //        quantity: businessPassed?(businessPassed?.profile?.inventory?1:0):(business?.profile?.inventory?1:0)
        },
        authMode: 'API_KEY',
      });
    } else {
      console.log(
        'range:',
        rangeToBeUsed,
        range.margin,
        rangeToBeUsed * (1 - range.margin),
        rangeToBeUsed * (1 + range.margin),
      );
      result = await API.graphql({
        query: catchAll,
        variables: {
          tag: tagValue,
          id: tagValue,
          businessId: businessPassed ? businessPassed.id : business?.id,
          nextToken: isNotFirstRequest ? searchResultsMeta.nextToken : null,
          range: [
            (1.0 - range.margin) * rangeToBeUsed.val,
            (1.0 + range.margin) * rangeToBeUsed.val,
          ],
          //        quantity: businessPassed?(businessPassed?.profile?.inventory?1:0):(business?.profile?.inventory?1:0)
        },
        authMode: 'API_KEY',
      });
    }
    items = result.data.searchItems.items;
    console.log('processSearchResults','tagValue', tagValue, ' items ', items, 'count',items.length);
  }
    //console.log('Searched items result' + JSON.stringify(result));
    processSearchResults(
      items,
      time,
      businessPassed ? businessPassed : business,
      tagValue,
      isNotFirstRequest,
    );
  }
  function processSearchResults(
    items,
    time,
    businessPassed,
    tagValue,
    isNotFirstRequest,
  ) {
    if (items.length>0) {
      setHasResults(true);
      let localItems = [];
      try {
        localItems = processSearchInternal(items, localItems, businessPassed);
      } catch (e) {
        console.log('error: ' + e);
      } finally {
        let timeElapsed = new Date().getTime() - time;
        console.log(
          'Total Items: ' +
            localItems.length +
            ' timeElapsed beofre set state: ' +
            timeElapsed,
        );

        if (tagValue === highLightString) {
          setHighlights({items: localItems});
        } else if (tagValue === dealOfTheDayString) {
          setDealsOfTheDay({items: localItems});
        } else {
          console.log(
            'endReached:',
            !items.nextToken,
            'nextToken',
            items.nextToken,
          );
          if (!isNotFirstRequest) {
            console.log(
              'setting search results for new search',
              tagValue,
              localItems.length,
            );
            setSearchResults((searchResultsInternal) => {
              return {
                ...initialImages,
                items: localItems,
              };
            });
            let endReached =
              items.length === 200 ? false : true;
            setSearchResultsMeta((searchResultsInternal) => {
              return {
                ...initialImagesMeta,
                nextToken: items.nextToken,
                endReached: endReached,
              };
            });
            if (endReached) {
              /*
              if (highlights.items.length === 0) {
                setIsFirstRequest(true);
                setSearchText(highLightString);
              }
              */
              if (localItems.length === 1) {
                onSelect(localItems[0]);
              }
            }
          } else {
            let existingSearchResults = [...searchResults.items];
            localItems.forEach((element) => {
              existingSearchResults.push(element);
            });
            console.log(
              'setting additional search results for existing search',
              tagValue,
            );
            setSearchResults((searchResultsInternal) => {
              return {
                ...searchResultsInternal,
                items: existingSearchResults,
              };
            });
            let endReached =
              items.length === 200 ? false : true;
            setSearchResultsMeta((searchResultsInternal) => {
              return {
                ...searchResultsInternal,
                nextToken: items.nextToken,
                endReached: endReached,
              };
            });              
            if (endReached) {
              /*
              if (highlights.items.length === 0) {
                setIsFirstRequest(true);
                setSearchText(highLightString);
              }
              */
              if (existingSearchResults.length === 1) {
                onSelect(localItems[0]);
              }
            }
          }
        }
        timeElapsed = new Date().getTime() - time;
        console.log(
          'timeElapsed',
          timeElapsed,
          ' matches: ',
          localItems.length,
        );
        setPageState((prevState) => {
          return {
            ...prevState,
            errorMessage: '',
            searched: true,
            hideResults: true,
            searchResultMessage: '',
          };
        });
        timeElapsed = new Date().getTime() - time;
        console.log(' timeElapsed after setting state: ' + timeElapsed);
        if (items.length !== 0) {
          // setIsImageViewVisible(true);
        }
        setLoading(false);
      }
    } else {
      let timeElapsed = new Date().getTime() - time;
      console.log('No items found. execution time: ' + timeElapsed);
      let searchResultMessage = '';
      if (!isNotFirstRequest) {
        //setSearchResults(initialImages);
        //setSearchResultsMeta(initialImagesMeta);
        searchResultMessage = I18n.t('NO_RESULTS');
        transitionState(USER_STATE.LANDING);
      } else {
        setSearchResultsMeta((searchResultsInternal) => {
          return {
            ...searchResultsInternal,
            nextToken: items.nextToken,
            endReached:
              !items.length === 200 ? true : false,
          };
        });
      }
      setPageState((prevState) => {
        return {
          ...prevState,
          errorMessage: '',
          searched: true,
          hideResults: true,
          searchResultMessage: searchResultMessage,
        };
      });
      setTimeout((_) => {
        setPageState((prevState) => {
          return {
            ...prevState,
            searchResultMessage: '',
          };
        });
      }, 3000);
      setLoading(false);
    }
  }
  function processSearchInternal(items, localItems, businessPassed) {
    for (var i = 0; i < items.length; i++) {
      var parentFound = null;
      var imageKeys = items[i].imageKeys;
      var localItem = { price: items[i].price, parent: items[i].parent , internationalPrice: items[i].internationalPrice?items[i].internationalPrice: Math.ceil(items[i].price*countryDetail.priceMultiplier/countryDetail.exchangeRate),
    };
      localItem.details = [];
      if (items[i].parent) {
        localItems.forEach((potentialParent) => {
          if (potentialParent.parent === items[i].parent) {
            parentFound = potentialParent;
          }
        });
      }
      if (parentFound) {
        localItem = parentFound;
      } else {
        localItems.push(localItem);
      }
      var imageKey = imageKeys[0];
      localItem.details.push({
        id: items[i].id,
        title: items[i].title,
        price: items[i].price,
        internationalPrice: items[i].internationalPrice?items[i].internationalPrice: Math.ceil(items[i].price*countryDetail.priceMultiplier/countryDetail.exchangeRate),
        weight: items[i].weight,
        displayDimensions: items[i].displayDimensions,
        category: items[i].category,
        benefits: items[i].benefits,
        unit: items[i].unit,
        allowedSellingUnits: items[i].allowedSellingUnits,
        unitMeasure: items[i].unitMeasure,
        ingredients: items[i].ingredients,
        usageInstructions: items[i].usageInstructions,
        subCategory: items[i].subCategory,
        parent: items[i].parent,
        lengthx: items[i].lengthx,
        breadth: items[i].breadth,
        height: items[i].height,
        quantity: items[i]?.quantity,
        description: items[i].description,
        tags: items[i].tags,
        exemptFromShippingChanrges: items[i].exemptFromShippingChanrges,
        imageKeys: imageKeys,
        imageKey: imageKey,
        fullSizeURL: {
          uri: getFullPageURL(
            businessPassed ? businessPassed.id : business?.id,
            imageKey
          ),
        },
        thumbNailURL: getThumbnailURL(
          businessPassed ? businessPassed.id : business?.id,
          imageKey
        ),
        galleryURL: getGalleryURL(
          businessPassed ? businessPassed.id : business?.id,
          imageKey
        ),
      });
    }
    if (range.val !== 0) {
      localItems = localItems.sort(function (a, b) {
        return (
          Math.abs(range.val - a.price) - Math.abs(range.val - b.price)
        );
      });
    }
    localItems.forEach((x)=>{
      x.details=x.details.sort((a,b) => (a.price > b.price) ? -1 : ((b.price > a.price) ? 1 : 0));
    });
    
    return localItems;
  }

  async function addSelectionToPickup(item) {
    let existingSelectionItems = [...selections.items];
      let exists = false;
      existingSelectionItems.forEach((cur) => {
        if (cur.details[cur.selectedIndex].id === item.details[item.selectedIndex].id && !exists) {
          cur.selectedQuantity = cur.selectedQuantity + item.selectedQuantity;
          exists = true;
        }
      });
      if (!exists) {
        existingSelectionItems.push(item);
      }
      setSelections({items: existingSelectionItems});
      console.log('Selections added for pick up: ' + selections?.items?.length);
      AsyncStorage.setItem(business.id + CART_SUFFIX, {
        items: existingSelectionItems,
      });
      Hub.dispatch('cart', {bizId: business.id});
      setIsImageViewVisible(false);
    /*
    let exists = false;
    existingSelectionItems.map((existingItem, idx) => {
      if (existingItem.thumbNailURL === item.thumbNailURL) {
        exists = true;
      }
    });

    if (!exists) {
      console.log('Selections added for pick up: ' + selections?.items?.length);
      existingSelectionItems.push({
        id: item.id,
        title: item.title,
        price: item.price,
        lengthx: item.lengthx,
        height: item.height,
        breadth: item.breadth,
        weight: item.weight,
        displayDimensions: item.displayDimensions,
        category: item.category,
        benefits: item.benefits,
        unit: item.unit,
        allowedSellingUnits: item.allowedSellingUnits,
        unitMeasure: item.unitMeasure,
        ingredients: item.ingredients,
        subCategory: item.subCategory,
        parent: item.parent,
        quantity: 1,
        thumbNailURL: item.thumbNailURL,
        imageKey: item.imageKey,
        fullSizeURL: item.fullSizeURL,
      });
      setSelections({items: existingSelectionItems});
      AsyncStorage.setItem(business.id + CART_SUFFIX, {
        items: existingSelectionItems,
      });
      setIsImageViewVisible(false);
    } else {
      console.log(
        'Photo already exists in selections. Not adding to selections',
      );
    }
    */
  }

  async function removeSelectionFromPickup(index) {
    var newSelectionsItems = [...selections.items];
    newSelectionsItems.splice(index, 1);
    setSelections({items: newSelectionsItems});
    AsyncStorage.setItem(business.id + CART_SUFFIX, {
      items: newSelectionsItems,
    });
    Hub.dispatch('cart', {bizId: business.id});
    window.scrollTo(0, 0);
  }
  async function deleteItem(imageIndexInternal) {
    var uri = [...images.items][imageIndexInternal.imageIndex].fullSizeURL.uri;
    var suffix = fullpage;
    var imgId = uri.substring(uri.indexOf(suffix) + suffix.length, uri.length);
    var id = [...images.items][imageIndexInternal.imageIndex].id;

    var result = await API.graphql({
      query: gql`
        query getItems($id: ID!, $businessId: ID!) {
          getItems(id: $id, businessId: $businessId) {
            id
            imageKeys
          }
        }
      `,
      variables: {id: id, businessId: user?.businessId},
    });
    var imageKeys = result.data.getItems.imageKeys;
    console.log(result);
    if (
      imageKeys != null &&
      imageKeys.length > 0 &&
      !imageKeys.includes(imgId)
    ) {
      let errMsg =
        'delete error. Id does not match with db id, abort. ImgIdDB  ' +
        imageKeys.imageKeys[0] +
        ' imgid requested for delete: ';
      console.log(errMsg, imgId);
    }
    console.log('deleting imgId: ', imgId, ' item id ', id);
    if (imageKeys.length === 1) {
      const deleteResult = await API.graphql({
        query: deleteItems,
        variables: {input: {id: id, businessId: user?.businessId}},
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      });
      console.log('deleteResult:  ', deleteResult, user?.businessId);
    } else {
      console.log('imageKeys:  ', imageKeys);
      var newImageKeys = imageKeys.filter((value, index, arr) => {
        return value !== imgId;
      });
      console.log('xpectd newImageKeys:  ', newImageKeys);
      const updateResult = await API.graphql({
        query: updateItems,
        variables: {
          input: {
            id: id,
            businessId: user?.businessId,
            imageKeys: newImageKeys,
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      });
      console.log('updateResult   ', updateResult);
    }
    setIsFirstRequest(true);
    //setSearchText(highLightString);
    setSearchText(null);
  }

  const handleRefresh = () => {
    console.log('handleRefresh');
    setSearchResultsMeta((searchResultsInternal) => {
      return {
        ...searchResultsInternal,
        refreshing: true,
      };
    });
    setIsFirstRequest(true);
  };

  const handleLoadMore = () => {
    if (!searchResultsMeta.endReached) {
      setIsFirstRequest(false);
      setPageNumber((pageNumberInternal) => {
        return pageNumberInternal + 1;
      });
      console.log('loading more, end not reached');
    } else {
      console.log('end reached');
    }
  };

  const renderSeparator = () => {
    return <View style={styles.searchListSeparatpr} />;
  };
  function updateSelection(item) {
    let existingSelections = [...selections.items];
    existingSelections[item.ShoppingCartArrayIndex] = item;
    let newSelections = {items: existingSelections};
    setSelections(newSelections);
    AsyncStorage.setItem(business.id + CART_SUFFIX, newSelections);
    Hub.dispatch('cart', {bizId: business.id});
  }
  function onSelect(localItem) {
    setDetailItem(localItem);
    if (localItem) {
      AsyncStorage.setItem(
        business.id + ':detailItem',
        JSON.stringify(localItem),
      );
    }
    setSelected(localItem?.details[0]?.id);
    transitionState(USER_STATE.DETAIL);
  }
  return (
    <KeyboardAwareScrollView
      contentContainerStyle={[styles.KeyboardAwareScrollViewStyle]}
      ref={ref}
      scrollEnabled
      showsVerticalScrollIndicator={true}
      automaticallyAdjustContentInsets={false}
      resetScrollToCoords={{x: 0, y: 0}}
      keyboardDismissMode="on-drag"
      keyboardShouldPersistTaps="always">
      <View style={{zIndex: 1}}>
        <MyStatusBar
          title={business ? business?.profile?.title : ''}
          navigation={navigation}
          rightComponent="cart"
          transitionState={transitionState}
          selections={selections}
          logoURL={business?.profile?.logo}
          setIsFirstRequest={setIsFirstRequest}
          setSearchText={setSearchText}
          setSearchResults={setSearchResults}
          setPageState={setPageState}
          initialImages={initialImages}
          pageState={pageState}
          tag={tag}
          updateTag={updateTag}
          userState={userState}
          showSearchBar={showSearchBar}
          business={business}
          setRange={setRange}
          range={range}
          theme={theme}
          fuse={fuse}
          page="search"
          categories={itemCategories}
          searchText={searchText}
        />
      </View>
      {curWebView ? (
        <MyLinks
          business={business}
          curWebView={curWebView}
          setCurWebView={setCurWebView}
        />
      ) : (
        <View style={styles.container}>
          <RetailImageView
            images={images}
            visible={isImageViewVisible}
            setVisible={setIsImageViewVisible}
            businessId={user?.isPrivileged ? user?.businessId : business?.id}
            business={business}
            imageIndex={imageIndex}
            showDelete={
              (userState === USER_STATE.SEARCH || USER_STATE.LANDING) &&
              user &&
              user?.isPrivileged &&
              user?.businessId === business?.id
            }
            deleteItem={deleteItem}
            showCart={userState === USER_STATE.SEARCH || USER_STATE.LANDING}
            getSelections={getSelections}
            updateSelection={updateSelection}
            user={user}
            theme={theme}
          />
          {loading && !searchResultsMeta.refreshing && false ? (
            <View
              style={{
                paddingVertical: 2,
                borderTopWidth: 0,
                position: 'relative',
                zIndex: 3000,
              }}>
              <ActivityIndicator animating size="large" color="grey" />
            </View>
          ) : null}
          {pageState.errorMessage ? (
            <Text
              style={[
                styles.errorMessage,
                {
                  top: 0,
                  flexWrap: 'wrap',
                  position: 'relative',
                  alignSelf: 'center',
                },
              ]}>
              {pageState.errorMessage}
            </Text>
          ) : null}
          {/**
      {pageState.errorMessage
        ? showMessage({
            message: pageState.errorMessage,
            //type: 'red',
          })
        : null}

      {!pageState.errorMessage ? (
        <View>
          <Text style={styles.errorMessage}>{pageState.errorMessage}</Text>
        </View>
      ) : null}
      {pageState.searchResultMessage ? (
        <View>
          <Text style={styles.infoMessage}>
            {pageState.searchResultMessage}
          </Text>
        </View>
      ) : null}
       */}
          {pageState.searchResultMessage ? (
            <View>
              <Text style={styles.infoMessage}>
                {pageState.searchResultMessage}
              </Text>
            </View>
          ) : null}
          {userState === USER_STATE.LANDING ? (
            <>
              {business?.profile.categories && (
                <MyCarousal
                  title="Our Product Categories"
                  getImageUrl={(item) =>
                    getCategoryURL(business.id, item.name + '.jpg')
                  }
                  onPress={(item, index) => {
                    updateTag('');
                    setSearchText(item.name);
                    transitionState(USER_STATE.SEARCH);
                  }}
                  data={business?.profile.categories}
                  getItemText={(item) => item.name}
                  getItemHeading={(item) => null}
                  loop={isMobile ? false : true}
                  showArrows={true}
                  getItemDescription={(item) => item.description}
                  showPagination={true}
                />
              )}
              {business?.profile.highlights && (
                <MyCarousal
                  title={business?.profile.highlightsText}
                  getImageUrl={(item) =>
                    getFullPageURL(business.id, item.details[0].imageKey)
                  }
                  onPress={(item) => onSelect(item)}
                  data={highlights.items}
                  getItemText={(item) => item.details[0].title}
                  getItemHeading={(item) => null}
                  sliderWidth={theme.screenWidth - 96}
                  showArrows={true}
                  loop={false}
                  round={true}
                  getItemDescription={(item) => null}
                  showPagination={true}
                />
              )}
              {business?.profile.dealOfTheDay && (
                <MyCarousal
                  title={business?.profile.dealOfTheDayText}
                  getImageUrl={(item) =>
                    getFullPageURL(business.id, item.details[0].imageKey)
                  }
                  onPress={(item) => onSelect(item)}
                  data={dealsOfTheDay.items}
                  sliderWidth={theme.screenWidth - 96}
                  getItemText={(item) => item.details[0].title}
                  getItemHeading={(item) => null}
                  showArrows={true}
                  loop={false}
                  getItemDescription={(item) => null}
                  showPagination={true}
                />
              )}
            </>
          ) : null}
          {userState === USER_STATE.SEARCH && searchResults.items.length > 0 ? (
            <FlatList
              style={[
                styles.listContainer,
                {borderTopWidth: 0, borderBottomWidth: 0},
              ]}
              data={searchResults.items}
              renderItem={({item, index}) => (
                <Item
                  item={item}
                  index={index}
                  selected={selected === item?.details[0]?.id}
                  addSelectionToPickup={addSelectionToPickup}
                  onSelect={onSelect}
                />
              )}
              bounces={false}
              keyExtractor={(item) =>
                item.details[0].id + ':' + item.details[0].imageKey
              }
              numColumns={numColumns}
              ItemSeparatorComponent={renderSeparator}
              onRefresh={handleRefresh}
              refreshing={searchResultsMeta.refreshing}
            />
          ) : null}
          {userState === USER_STATE.SEARCH &&
          searchResults.items.length === maxResults &&
          !searchResultsMeta.endReached ? (
            <TouchableHighlight
              onPress={() => {
                handleLoadMore();
              }}>
              <Text
                style={{
                  alignSelf: 'center',
                  alignItems: 'center',
                  marginBottom: 10,
                }}>
                {!searchResultsMeta.endReached
                  ? 'Click to Fetch More Results...'
                  : null}
              </Text>
            </TouchableHighlight>
          ) : userState === USER_STATE.SEARCH &&
            searchResults.items.length > 0 &&
            searchResultsMeta.endReached ? (
            <Text
              style={{
                alignSelf: 'center',
                alignItems: 'center',
                marginBottom: 10,
              }}>
              No more Results remaining
            </Text>
          ) : null}
          {userState === USER_STATE.CART ? (
            <ShoppingCart
              setImageIndex={setImageIndex}
              setImages={setImages}
              searchResults={searchResults}
              selections={selections}
              setSelections={setSelections}
              transitionState={transitionState}
              setIsImageViewVisible={setIsImageViewVisible}
              removeSelectionFromPickup={removeSelectionFromPickup}
              setUserState={setUserState}
              updateSelection={updateSelection}
              business={business}
              renderSeparator={renderSeparator}
              setDetailItem={setDetailItem}
            />
          ) : null}
          {userState === USER_STATE.CHECKOUT ||
          userState === USER_STATE.PAYMENT_SUCCESS ? (
            <CheckOut
              user={user}
              business={business}
              visible={
                userState === USER_STATE.CHECKOUT ||
                userState === USER_STATE.PAYMENT_SUCCESS
              }
              transitionState={transitionState}
              selections={selections}
              setSelections={setSelections}
              setPageState={setPageState}
              navigation={navigation}
              searchResults={searchResults}
              setCurWebView={setCurWebView}
              theme={theme}
              userState={userState}
              updateSelection={updateSelection}
            />
          ) : null}
          {userState === USER_STATE.DETAIL && detailItem ? (
            <ItemDetail
              business={business}
              user={user}
              detailItem={detailItem}
              theme={theme}
              selections={selections}
              transitionState={transitionState}
              setIsImageViewVisible={setIsImageViewVisible}
              addSelectionToPickup={addSelectionToPickup}
              setImages={setImages}
              setImageIndex={setImageIndex}
              searchResults={searchResults}
              updateSelection={updateSelection}
              setEditItem={setEditItem}
            />
          ) : null}
          {userState === USER_STATE.EDIT ? (
            <EditItem
              transitionState={transitionState}
              setIsImageViewVisible={setIsImageViewVisible}
              setImages={setImages}
              setImageIndex={setImageIndex}
              id={editItem}
            />
          ) : null}
          {userState === USER_STATE.REVIEW && false && (
            <>
              <Reviews navigation={navigation} />
              <TouchableOpacity
                style={{
                  borderWidth: 1,
                  borderColor: 'rgba(0,0,0,0.2)',
                  position: 'absolute',
                  top: theme.screenHeight / 4,
                  right: 0,
                  backgroundColor: theme.colors.primary,
                  transform: [{rotate: '90deg'}],
                }}
                onPress={() => transitionState(USER_STATE.REVIEW)}>
                <Text
                  style={[
                    styles.infoMessage,
                    {
                      backgroundColor: theme?.colors?.primary,
                      color: theme?.color?.secondary,
                      position: 'absolute',
                      top: 0,
                      left: isMobile ? 100 : 150,
                    },
                  ]}>
                  *Reviews*
                </Text>
              </TouchableOpacity>
            </>
          )}
        </View>
      )}
      <Footer setCurWebView={setCurWebView} />
    </KeyboardAwareScrollView>
  );
}
export default SearchScreen;
