/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:d24ac97f-c1c6-4f81-b17e-c0782b5e0b26",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_3hvomuh81",
    "aws_user_pools_web_client_id": "6lh9ev2uts1vubaotf6blgsgo",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_user_files_s3_bucket": "ezibiz5cbb706595b945d0a2bab2bc1ac9aac6155559-dev",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://q7q3dthopzhrhpjlqvhygaf46q.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-nkbqh5qbhrhy3puj33jc433eu4",
    "aws_cloud_logic_custom": [
        {
            "name": "payments",
            "endpoint": "https://jktmcg929k.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "shippingPreCheck",
            "endpoint": "https://7tli9btc40.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_mobile_analytics_app_id": "678d3eb7dd814154a4e7c2f9c0068940",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;
