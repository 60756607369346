import {
  Image,
  View,
  Text,
  Linking,
  StyleSheet,
  TouchableHighlight,
} from 'react-native';
import * as React from 'react';
import {useContext} from 'react';
import {MyContext, isDeskTopBrowser} from '../../styles/styles';
import {cloudFrontURL, CommonProfile} from '../../utilities/profile';
import Icon from 'react-native-vector-icons/FontAwesome';
import {isMobile} from 'react-device-detect';

export const Footer = ({setCurWebView}) => {
  const context = useContext(MyContext);
  const theme = context?.theme;
  const user = context?.user;
  const business = context?.business;
  const localStyles = StyleSheet.create({
    infoMessage: {
      padding: 5,
      fontSize: 12,
      marginLeft: 10,
      color: theme?.colors?.primary,
      alignContent: 'flex-start',
      flexWrap: 'wrap',
    },
    infoHeadingHighLight: {
      padding: 5,
      fontSize: 22,
      marginLeft: 10,
      color: theme?.colors?.primary,
      fontStyle: 'italic',
    },
  });
  return (
    <View
      style={{
        flexDirection: 'column',
        backgroundColor: theme?.colors?.footer,
        marginTop: 5,
      }}>
      <Text
        style={[
          {
            padding: 15,
            fontSize: theme?.fonts.ml,
            textAlign: 'center',
            backgroundColor: theme?.colors?.primaryLight,
            color: theme?.colors?.whiteMessage,
            width: theme?.screenWidth,
          },
        ]}>
        {business?.name}
      </Text>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'flex-start',
          marginTop: 25,
          flexWrap: 'wrap',
        }}>
        <>
          {!isMobile && business?.profile?.logo && (
            <Image
              resizeMode="contain"
              style={{
                flex: 1,
                height: isDeskTopBrowser
                  ? theme?.screenWidth / 10
                  : theme?.screenWidth / 3,
              }}
              source={cloudFrontURL + business?.profile?.logo}
            />
          )}
          <View
            style={{
              flex: 1,
              flexDirection: 'column',
            }}>
            <Text style={localStyles.infoHeadingHighLight}>Contact Us</Text>
            <Text
              style={localStyles.infoMessage}
              onPress={() =>
                Linking.openURL(
                  'tel:' + business?.profile?.contactInfo?.mobileNumber,
                )
              }>
              <Icon
                raised
                name="phone"
                type="font-awesome"
                color={theme?.colors?.primary}
                size={25}
                Component={TouchableHighlight}
                activeOpacity={0.6}
                underlayColor={theme?.colors?.iconTouchHighlight}
              />
              <Text style={localStyles.infoMessage}>
                {business?.profile?.contactInfo?.mobileNumber}
              </Text>
            </Text>
            <Text
              style={localStyles.infoMessage}
              onPress={() =>
                Linking.openURL('sms:' + business?.profile?.contactInfo?.sms)
              }>
              <Icon
                raised
                name="comments"
                type="font-awesome"
                color={theme?.colors?.primary}
                size={25}
                Component={TouchableHighlight}
                activeOpacity={0.6}
                underlayColor={theme?.colors?.iconTouchHighlight}
              />
              <Text style={localStyles.infoMessage}>
                {business?.profile?.contactInfo?.sms}
              </Text>
            </Text>
            <Text
              style={localStyles.infoMessage}
              onPress={() =>
                Linking.openURL('https://api.whatsapp.com/send?phone=' + business?.profile?.contactInfo?.whatsApp)
              }>
              <Icon
                raised
                name="whatsapp"
                type="font-awesome"
                color={theme?.colors?.primary}
                size={25}
                Component={TouchableHighlight}
                activeOpacity={0.6}
                underlayColor={theme?.colors?.iconTouchHighlight}
              />
              <Text style={localStyles.infoMessage}>
                {business?.profile?.contactInfo?.sms}
              </Text>
            </Text>
            <Text
              style={localStyles.infoMessage}
              onPress={() =>
                Linking.openURL(
                  'mailto:' + business?.profile?.contactInfo?.email,
                )
              }>
              <Icon
                raised
                name="envelope-o"
                type="font-awesome"
                color={theme?.colors?.primary}
                size={25}
                Component={TouchableHighlight}
                activeOpacity={0.6}
                underlayColor={theme?.colors?.iconTouchHighlight}
              />
              <Text style={localStyles.infoMessage}>
                {business?.profile?.contactInfo?.email}
              </Text>
            </Text>
            <View
              style={{
                padding: 5,
                marginLeft: 10,
                alignItems: 'center',
                flexDirection: 'row',
              }}>
              <Icon
                raised
                name="address-card-o"
                type="font-awesome"
                color={theme?.colors?.primary}
                size={25}
                Component={TouchableHighlight}
                activeOpacity={0.6}
                underlayColor={theme?.colors?.iconTouchHighlight}
              />
              <Text style={localStyles.infoMessage}>
                {business?.profile?.contactInfo?.address}
              </Text>
            </View>
          </View>
          <View
            style={{
              flex: 1,
              flexDirection: 'column',
            }}>
            <Text style={localStyles.infoHeadingHighLight}>Useful Links</Text>
            <Text style={localStyles.infoMessage}>
              {false && (
                <Icon
                  raised
                  name="link"
                  type="font-awesome"
                  color={theme?.colors?.primary}
                  size={25}
                  Component={TouchableHighlight}
                  activeOpacity={0.6}
                  underlayColor={theme?.colors?.iconTouchHighlight}
                />
              )}
              {false && (
                <Text
                  style={localStyles.infoMessage}
                  onPress={() => setCurWebView('faq')}>
                  FAQs
                </Text>
              )}
            </Text>
            <Text style={localStyles.infoMessage}>
              <Icon
                raised
                name="link"
                type="font-awesome"
                color={theme?.colors?.primary}
                size={25}
                Component={TouchableHighlight}
                activeOpacity={0.6}
                underlayColor={theme?.colors?.iconTouchHighlight}
              />
              <Text
                style={localStyles.infoMessage}
                onPress={() => setCurWebView('tc')}>
                Terms and Conditions
              </Text>
            </Text>
            <Text style={localStyles.infoMessage}>
              <Icon
                raised
                name="link"
                type="font-awesome"
                color={theme?.colors?.primary}
                size={25}
                Component={TouchableHighlight}
                activeOpacity={0.6}
                underlayColor={theme?.colors?.iconTouchHighlight}
              />
              <Text
                style={localStyles.infoMessage}
                onPress={() => setCurWebView('pp')}>
                Privacy Policy
              </Text>
            </Text>
            <Text style={localStyles.infoMessage}>
              <Icon
                raised
                name="link"
                type="font-awesome"
                color={theme?.colors?.primary}
                size={25}
                Component={TouchableHighlight}
                activeOpacity={0.6}
                underlayColor={theme?.colors?.iconTouchHighlight}
              />
              <Text
                style={localStyles.infoMessage}
                onPress={() => setCurWebView('rc')}>
                Refund &amp; Cancelation Policy
              </Text>
            </Text>
          </View>
        </>
      </View>
      {isMobile && business?.profile?.logo && (
        <Image
          resizeMode="contain"
          style={{
            flex: 1,
            height: isDeskTopBrowser
              ? theme?.screenWidth / 10
              : theme?.screenWidth / 3,
          }}
          source={cloudFrontURL + business?.profile?.logo}
        />
      )}
      <View style={{flexDirection: 'row', alignSelf: 'flex-end'}}>
        <Text
          style={[
            {
              fontSize: theme?.fonts.s,
              textAlign: 'right',
              alignSelf: 'center',
              backgroundColor: theme?.colors?.transparent,
              color: theme?.colors?.whiteMessage,
              width: theme?.screenWidth,
            },
          ]}>
          Powered by ShopMyShopz
        </Text>
        <Image
          style={{width: 16, height: 16, alignSelf: 'center', margin: 5}}
          resizeMode="contain"
          source={{uri: cloudFrontURL + CommonProfile.profileImage}}
        />
      </View>
    </View>
  );
};
