import {Analytics, API} from 'aws-amplify';
import React, {useRef, useState, useEffect, useContext} from 'react';
import {Modal, TouchableHighlight, View, StyleSheet} from 'react-native';
import {Picker} from '@react-native-picker/picker';
import {CheckBox, Text} from 'react-native-elements';
import uuid from 'react-native-uuid';
import { Storage } from 'aws-amplify';
import { jsPDF } from 'jspdf';
import {createOrderWithTxToken, updateOrder} from '../../graphql/mutations';
import {
  CART_SUFFIX,
  Status,
  USER_EVENTS,
  USER_STATE,
  LOCAL,
  StatusMap,
  ACTIVE_CART_ORDER,
  SELECT_YOUR_COUNTRY,
  cleanPhoneNumber,
  PHONE_NUMBER_LENGTH,
} from '../../utilities/Constants';
import {MyStorage as AsyncStorage} from '../../utilities/MyStorage';
import {maxInputFieldWidth, screenHeight, MyContext} from '../../styles/styles';
import {
  cloudFrontURL,
  INTERNATIONAL_PAYMENT_PAGE,
  PAYMENTS_CALLBACK_URL,
  PAYMENT_PAGE,
} from '../../utilities/profile';
import {Hub} from 'aws-amplify';
import {
  MyButton,
  ActionButton,
  InputTextArea,
  InputText,
  MyText,
  MyAcceptence,
  MyCountryPicker,
  InputPhone,
} from '../ui//elements';
import {styles} from '../../styles/styles';
import {WebView} from 'react-native-web-webview';
import base64 from 'react-native-base64';
import I18n from '../../I18n/I18n';
import {CustomAuth} from '../auth/CustomAuth';
import {OrderSummary} from '../OrderSummary/OrderSummary';
import {isMobile} from 'react-device-detect';
import {phoneNumberWithoutPrefix} from '../../utilities/RetailClient';
import { LimitExceededException } from '@aws-sdk/client-cloudwatch-logs';

export const CheckOut = ({
  setPageState,
  setSelections,
  transitionState,
  navigation,
  selections,
  updateSelection,
  visible,
  setCurWebView,
  userState,
}) => {
  const context = useContext(MyContext);
  const user = context.user;
  const business = context.business;
  const theme = context.theme;
  const countryDetail = context.countryDetail;
  const [order, setOrder] = useState(initialOrder);
  const [submitPayment, setSubmitPayment] = useState(false);
  const [options, setOptions] = useState();

  const [modalVisible, setModalVisible] = useState(false);
  const [guestCheckOut, setGuestCheckOut] = useState(false);
  const [guestPhoneConfirmed, setGuestPhoneConfirmed] = useState(false);
  const webView = useRef();
  const webViewIntl = useRef();
  const SELECT_YOUR_STATE = !business?.profile?.stateDropdown
    ? 'Enter your State'
    : 'Select your State';
  const SELECT_YOUR_CITY = !business?.profile?.cityDropdown
    ? 'Enter your City'
    : 'Select your City';
  const handleWebViewMessage = (event) => {
    const {data} = event.nativeEvent;
    if (data && data === 'success') {
      handlePaymentSuccess();
      Analytics.record({
        name: USER_EVENTS.PAYMENT_SUCCESS,
        attributes: {userId: user.userId},
        immediate: true
      });
    } else if (data && data === 'abandon') {
      setSubmitPayment(false);
      Analytics.record({
        name: USER_EVENTS.PAYMENT_ABORTED,
        attributes: {userId: user.userId},
        immediate: true
      });
      console.log(JSON.stringify(data));
      setPageState((prevState) => {
        return {
          ...prevState,
          errorMessage: I18n.t('REQ_FAILED_PAYMENT'),
        };
      });
      setTimeout((_) => {
        setPageState((prevState) => {
          return {
            ...prevState,
            errorMessage: '',
          };
        });
      }, 15000);
    }else if (data && data === 'failure') {
      setSubmitPayment(false);
      Analytics.record({
        name: USER_EVENTS.PAYMENT_FAILURE,
        attributes: {userId: user.userId},
        immediate: true
      });
      console.log(JSON.stringify(data));
      setPageState((prevState) => {
        return {
          ...prevState,
          errorMessage: I18n.t('REQ_FAILED'),
        };
      });
      setTimeout((_) => {
        setPageState((prevState) => {
          return {
            ...prevState,
            errorMessage: '',
          };
        });
      }, 15000);
    }
  };
  useEffect(() => {
    setModalVisible(false);
    let storedOrderString=AsyncStorage.getItem(business.id + ACTIVE_CART_ORDER);
    if (!storedOrderString ||  JSON.parse(storedOrderString).userNotSet) {
       setNewOrder(getSelectedItemsSummary().itemArray);
    } else {
      let storedOrder = JSON.parse(storedOrderString);
      if (storedOrder) {
        if (getSelectedItemsSummary()?.itemArray) {
          storedOrder.items = JSON.stringify(
            getSelectedItemsSummary().itemArray,
          );
        }
        setOrder({...storedOrder, country: countryDetail?.country});
      }
    }
  }, [initialOrder, navigation, user, business]);

  useEffect(() =>{
    if(business?.profile.useCustomShipping)setShipping(order);
  },[order?.state,order?.city,order?.country]);

  useEffect(() =>{
    setShipping(order);
  },[order?.delivery]);

  useEffect(() =>{
    if(order?.zipCode?.length>4)setShipping(order);
  },[order?.zipCode]);

  const initialOrder = {
    id: null,
    businessId: null,
    mobileNumber: '',
    items: [],
    price: 0.0,
    weight: 0.0,
    lengthx: 0.0,
    breadth: 0.0,
    height: 0.0,
    status: '',
    termAndConditionsAcceptence: true,
    state: business?.profile.stateDropdown ? SELECT_YOUR_STATE : null,
    city: business?.profile.cityDropdown ? SELECT_YOUR_CITY : null,
    country: business?.profile.countryDropdown ? SELECT_YOUR_COUNTRY : null,
    delivery: true,
    deliveryCharges: 0,
    address: '',
    name: '',
    firstName: '',
    lastName: '',
    cod: 0,
    displayDimensions: '',
    ingredients: '',
    usageInstructions: '',
    benefits: '',
    phoneNumberPrefix: '',
  };

  function getSelectedItemsSummary() {
    let itemArray = [];
    let lengthx = 0.0;
    let breadth = 0.0;
    let height = 0.0;
    let weight = 0.0;

    if (selections?.items) {
      [...selections?.items].map((item, i) => {
        itemArray.push({
          title: item?.details[item?.selectedIndex]?.title,
          description: item?.details[item?.selectedIndex]?.description,
          id: item?.details[item?.selectedIndex]?.id,
          price: countryDetail.isInternational?(item?.details[item?.selectedIndex]?.internationalPrice):(item?.details[item?.selectedIndex]?.price),
          quantity: item?.selectedQuantity,
          exemptFromShippingChanrges: item?.details[item?.selectedIndex]?.exemptFromShippingChanrges,
          imageKey: item?.details[item?.selectedIndex]?.imageKey,
          currency: countryDetail.currency,
        });

        lengthx += item?.details[item?.selectedIndex].lengthx;
        breadth += item?.details[item?.selectedIndex].breadth;
        height += item?.details[item?.selectedIndex].height;
        weight +=
          item?.details[item?.selectedIndex].weight * item?.selectedQuantity;
      });
    }
    return {
      itemArray: itemArray,
      lengthx: lengthx,
      breadth: breadth,
      height: height,
      weight:
        (weight *
          (business.profile?.cartonMarkUp
            ? parseFloat(business.profile?.cartonMarkUp)
            : 1)) /
        1000,
    };
  }

  async function setShipping(newOrder) {
    let res = getSelectedItemsSummary();
    if(business?.profile?.useCustomShipping)calculatePrice(newOrder);
    else{
      let queryParams = {
        weight: res.weight,
        pickupPostcode: business.profile.contactInfo.zipCode,
        zipCode: newOrder.zipCode,
        cod: newOrder?.cod
          ? newOrder?.cod
          : business?.profile.cod
          ? business?.profile.cod
          : 0,
        mode: business?.profile?.shippingMode,
        bizId: business?.id,
      };
      console.log(JSON.stringify(queryParams));
      API.get('shippingPreCheck', '/courier', {
        queryStringParameters: queryParams,
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })
        .then((response) => {
          let shippingDetails = response.shippingDetails?.rate?JSON.stringify(response.shippingDetails):null;
          setOrder((prevOrder) => {
            return {
              ...prevOrder,
              "shippingDetails": JSON.stringify(shippingDetails)
            };
          });        
          calculatePrice({...newOrder,shippingDetails: shippingDetails});
        })
        .catch((error) => {
          let newPriceDetail = order?.priceDetail;
          let temp = JSON.parse(newPriceDetail);
          temp.shippingHandling = null;
          newPriceDetail = JSON.stringify(temp);
          setOrder((prevOrder) => {
            return {
              ...prevOrder,
              priceDetail: newPriceDetail,
              shippingDetails: null,
            };
          });
          console.log(error.response);
        });
    }
  }

  const saveInvoice = async(item) =>{
    return;
    let da = JSON.parse(item.deliveryAddress);
    let text =
      '<!DOCTYPE html><body>' +
      '<table width="100%" style="text-align: center"s>' +
      '<tr><th/><th>' +
      business.name +
      '</th></tr>' +
      '<tr><th/><th>Invoice' +
      '</th></tr>' +
      '<tr><th>Shipping Address:</th><th>Sold By:</th><th>Invoice Details:</th></tr><tr><td>' +
      da.addressLine +
      '<br/>' +
      da.city +
      '<br/>' +
      da.state +
      '-' +
      da.zipCode +
      '<br/>' +
      da.country +
      '</td><td>' +
      business.name +
      '<br/>' +
      business.profile.contactInfo.address +
      '<br/> Phone: ' +
      business.profile.contactInfo.mobileNumber +
      '<br/>Email: ' +
      business.profile.contactInfo.email +
      '</td><td>' +
      'Invoice Number:<br/>' +
      item.id.substring(0, 7) +
      '<br/>Invoice Date:<br/>' +
      item.orderSortDate +
      '<br/>Order Number:<br/>' +
      item.id.substring(0, 7) +
      '<br/>Order Date:' +
      item.orderSortDate +
      '</td></tr></table>' +
      '<table width="100%" style="text-align: center">' +
      '<tr> <th>S. No.</th> <th>Product Name</th> <th>Qty</th> <th>Unit Price</th> <th>Total</th></tr>';
    let j = 0;
    JSON.parse(item.items[0]).forEach((e) => {
      text =
        text +
        ' <tr> <td>' +
        ++j +
        '</td><td>' +
        e.title +
        '</td><td>' +
        e.quantity +
        '</td><td>' +
        (e.price) +
        '</td><td>' +
        ((e.price) * Number(e.quantity)) +
        '</td></tr>';
    });
    text =
      text +
      '<tr/>' +
      '<tr><td/><td>' +
      'Shipping and Handling: </td>' +
      '<td/><td/><td>' +
      JSON.parse(item.priceDetail).shippingHandling +
      '</td></tr>';
    JSON.parse(item.priceDetail).discount > 0 ? (
      text =
      text +
      '<tr/>' +
      '<tr><td/><td>' +
      'Discount: </td>' +
      '<td/><td/><td>' +
      JSON.parse(item.priceDetail).discount +
      '</td></tr>'
    ) : '';
    text =
      text +
      '<tr><td/><td>' +
      'NET TOTAL (In Value): </td>' +
      '<td/><td/><td>' +
      countryDetail.currency +
      '  ' +
      JSON.parse(item.priceDetail).totalPrice +
      '</td></tr>';
    text = text + '</table>';
    text = text + '</body></html>';
    var doc = new jsPDF({
      orientation: 'p',
      unit: 'pt',
      format: 'a4',
    });
    doc.setFont('times');
    doc.setFontSize(5);
    doc.html(text, {
      callback: function (doc) {
        //doc.save('HnH-' + item.orderSortDate);
        let file = doc.output('arraybuffer');
        storeToS3(file, item);
      },
      margin: 10,
      width: 575,
      windowWidth: 595,
    });
  };
  async function storeToS3(file, item){
    await Storage.put(
      user?.businessId + '/invoices/'+ item.id,
      file,
      { level: 'private', useAccelerateEndpoint: true },
    ).then((result) => {
      console.log('Stored invoice in S3');
    });
  }
  async function placeOrder(payCash) {
    let delivery = order?.delivery;
    let err=null;
    try {
      if (!order.termAndConditionsAcceptence) {
          err= I18n.t('ACCEPT_TC');
      }
      else if (
        delivery &&
        (!order.state ||
          order.state === SELECT_YOUR_STATE ||
          !order.city ||
          order.city === SELECT_YOUR_CITY ||
          !order.country ||
          order.country === SELECT_YOUR_COUNTRY)
      ) {
        err= I18n.t('CITY_STATE');
      }
      else if (delivery && (!order.zipCode)) {
          err = I18n.t('INVALID_ZIP')
      }
      else if (
        delivery &&
        !(
          JSON.parse(order.priceDetail)?.shippingHandling ||
          JSON.parse(order.priceDetail)?.shippingHandling === 0
        )
      ) {
         err= I18n.t('INVALID_ZIP');
      }
      else if (!order.firstName) {
        err = I18n.t('REQ_FIRST_NAME');
      }
      else if (!order.country) {
        err = I18n.t('REQ_COUNTRY');
      }
      else if (!order.lastName) {
        err = I18n.t('REQ_LAST_NAME');
      }else if (order.country===SELECT_YOUR_COUNTRY) {
        err= I18n.t('INVALID_COUNTRY');
      }
      else if (!order.deliveryContactEmail) {
        err= I18n.t('REQ_EMAIL');
      }
      else if (delivery && !order.address) {
        err = I18n.t('REQ_ADDR');
      }else if (JSON.parse(order.priceDetail)?.totalPrice===0) {
        err = I18n.t('REQ_QTY');
      }else{
        business?.profile?.shipping?.allowedCountries.map(
          (element) => {
            if(element['country']===order.country && element['zipLength']!==order.zipCode.length){
               err = I18n.t('INVALID_ZIP');
            }
          }
        );        
      }
      if(err){
        throw err;
      }
      let res = getSelectedItemsSummary();
      let itemArray = res.itemArray;
      let json = JSON.stringify(itemArray);
      let orderId = order.id ? order.id : uuid.v4();
      if (!order.shippingDetails) {
        order.shippingDetails = JSON.stringify({
          invoiceURL: 'https://' + business.domain + '?orderId=' + orderId,
          version: context.version,
          versionFromCache: context.versionFromCache,
        });
      }
      let input = {
        id: orderId,
        businessId: business?.id,
        items: json,
        price: JSON.parse(order.priceDetail)?.totalPrice,
        shippingDetails: order.shippingDetails,
        priceDetail: order.priceDetail,
        status: delivery
          ? StatusMap.PENDING_PAYMENT
          : StatusMap.PICKUP_REQUESTED,
        note: order.note,
        useCustomShipping: order.useCustomShipping,
        termAndConditionsAcceptence: order.termAndConditionsAcceptence,
        delivery: delivery,
        isAdminOrder: order.isAdminOrder,
        adminNote: order.adminNote,
        mobileNumber: user?.mobileNumber,
        deliveryContact: JSON.stringify({
          deliveryContactNumber: order.phoneNumberPrefix+cleanPhoneNumber(order.deliveryContactNumber),
          deliveryContactEmail: order.deliveryContactEmail,
          lastName: order.lastName,
          firstName: order.firstName,
          name: order.firstName + (order.firstName ? ' ' : '') + order.lastName,
        }),
        deliveryAddress: JSON.stringify({
          addressLine: order.address,
          city: order.city,
          state: order.state,
          country: order.country,
          country_code: order.country==="United States"?"US":"IN",
          zipCode: order.zipCode,
        }),
        lengthx: res.lengthx,
        breadth: res.breadth,
        height: res.height,
        weight: res.weight,
        displayDimensions: order.displayDimensions,
        cod: order.cod,
        businessName: business.name,
        businessEmail: business?.profile?.contactInfo?.email,
        pickupPostcode: business?.profile?.contactInfo?.zipCode,
        orderSortDate: new Date().toISOString().split('T')[0],
        isCashPayment: payCash,
      };

      if (payCash) {
        if (true){ //!order.id
          await API.graphql({
            query: createOrderWithTxToken,
            variables: {
              input: input,
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          })
            .then((result) => {
              let dbOrder = result.data.createOrderWithTxToken;
              //updateLocalOrder('id', dbOrder.id);
              updateLocalOrder('status', dbOrder.status);
              saveInvoice(result.data.createOrderWithTxToken);
              handlePaymentSuccess();
            })
            .catch((e) => {
              handleFailure(e);
            });
        } else {
          await API.graphql({
            query: updateOrder,
            variables: {
              input: input,
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          })
            .then((result) => {
              saveInvoice(result.data.updateOrder);
              handlePaymentSuccess();
            })
            .catch((e) => {
              handleFailure(e);
            });
        }
      } else {
        if (true){ //!order.id
          await API.graphql({
            query: createOrderWithTxToken,
            variables: {
              input: input,
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          })
            .then((result) => {
              let dbOrder = result.data.createOrderWithTxToken;
              //updateLocalOrder('id', dbOrder.id);
              updateLocalOrder('status', dbOrder.status);
              saveInvoice(result.data.createOrderWithTxToken);
              handleSuccess(result, dbOrder);
            })
            .catch((e) => {
              handleFailure(e);
            });
        } else {
          await API.graphql({
            query: updateOrder,
            variables: {
              input: input,
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          })
            .then((result) => {
              let dbOrder = result.data.updateOrder;
              saveInvoice(result.data.updateOrder);
              handleSuccess(result, dbOrder);
            })
            .catch((e) => {
              handleFailure(e);
            });
        }
      }
    } catch (e) {
      console.log('Error:', e);
      setPageState((prevState) => {
        return {
          ...prevState,
          errorMessage: e,
        };
      });
    } finally{
      window.scrollTo(0,0);
    }

    function handleFailure(e) {
      Analytics.record({
        name: USER_EVENTS.PLACE_ORDER_FAILURE,
        attributes: {userId: user.userId},
        immediate: true
      });
      setPageState((prevState) => {
        return {
          ...prevState,
          errorMessage: I18n.t('REQ_FAILED'),
        };
      });
      setTimeout(() => {
        setPageState((prevState) => {
          return {
            ...prevState,
            errorMessage: '',
          };
        });
      }, 15000);
    }

    function handleSuccess(result, dbOrder) {
      console.log('Order Result:', result, ' orig order ', order);
      let deliveryContact = JSON.parse(dbOrder.deliveryContact);
      var optionsLocal = {
        description: result.data?.createOrderWithTxToken?.items
          ? JSON.parse(result.data.createOrderWithTxToken.items)[0].title
          : 'description',
        image: cloudFrontURL + business?.profile?.logo,
        currency: dbOrder.currency,
        key: business.profile.razorPayKey,
        amount: countryDetail.isInternational?dbOrder.price:(dbOrder.price * 100),
        name: business.name,
        order_id: JSON.parse(dbOrder?.externalOrder)?.id,
        prefill: {
          email: deliveryContact?.deliveryContactEmail,
          contact: deliveryContact?.deliveryContactNumber,
          name:
            deliveryContact?.firstName +
            (deliveryContact?.firstName ? ' ' : '') +
            deliveryContact?.lastName,
        },
        callback_url: PAYMENTS_CALLBACK_URL + '?bizId=' + business.id,
        notes: {
          address: business.profile.contactInfo.address,
        },
        theme: {color: 'green'},
        escape: false,
        modal: {
          ondismiss: () => {
            window.parent.window.parent.postMessage('failure');
          },
        },
      };
      //console.log('optionsLocal: ' + JSON.stringify(optionsLocal));
      let optionsString = base64.encode(JSON.stringify(optionsLocal));
      //console.log(optionsString);
      setOptions(optionsString);
      window.scrollTo(0, 0);
      setSubmitPayment(true);
      Analytics.record({
        name: USER_EVENTS.PAYMENT_STARTED,
        attributes: {userId: user.userId},
        immediate: true
      });
    }
  }
  const handlePaymentSuccess = () => {
    setSubmitPayment(false);
    setSelections({items: []});
    transitionState(USER_STATE.PAYMENT_SUCCESS);
    AsyncStorage.setItem(business.id + CART_SUFFIX, {
      items: [],
    });
    setNewOrder([]);
    Hub.dispatch('cart', {bizId: business.id});

    setPageState((prevState) => {
      return {
        ...prevState,
        errorMessage: order?.delivery
          ? I18n.t('ORDER_PLACED')
          : I18n.t('PICKUP_SENT'),
      };
    });
    setTimeout((_) => {
      setPageState((prevState) => {
        return {
          ...prevState,
          errorMessage: '',
        };
      });
    }, 30000);
    Analytics.record({
      name: USER_EVENTS.PLACE_ORDER,
      attributes: {userId: user.userId},
      immediate: true
    });
  };

  function cleanSelections(){
    if (selections?.items) {
      [...selections?.items].map((item, i) => {
        if(!item?.selectedQuantity){
          item.selectedQuantity = 1;
          updateSelection(i, item);
        }
      });
    }
  }

  function calculatePrice( newOrder) {
      cleanSelections();
      let newShipping = newOrder?.shippingDetails
        ? JSON.parse(newOrder.shippingDetails)?.rate
        : 0;
      let priceDetail = newOrder?.priceDetail
        ? JSON.parse(newOrder.priceDetail)
        : {};
      if (newShipping || newShipping === 0) {
        priceDetail.shippingHandling = newShipping;
      }
      priceDetail.totalPrice = 0;
      priceDetail.itemsTotal = 0;
      if (selections?.items) {
        [...selections?.items].map((item, i) => {
          priceDetail.itemsTotal =
            priceDetail.itemsTotal +
            ((item?.details[item?.selectedIndex]?.price
              ? countryDetail.isInternational?item.details[item?.selectedIndex]?.internationalPrice:item.details[item?.selectedIndex]?.price 
              : 0) *
              item?.selectedQuantity) /
              (item?.details[item?.selectedIndex]?.unit
                ? item?.details[item?.selectedIndex]?.unit
                : 1);
          priceDetail.itemsCount = i + 1;
        });
      }
      if (business?.profile?.useCustomShipping) {
        priceDetail.shippingHandling = calculateDeliveryCharges( priceDetail.itemsTotal, newOrder);
      }

      let discount = user?parseInt(user.discount):0;
      priceDetail.discount = Math.round((priceDetail.itemsTotal*discount)/100);
      priceDetail.totalPrice =
        priceDetail.totalPrice +
        (priceDetail?.shippingHandling
          ? priceDetail.shippingHandling
          : 0) +
        priceDetail.itemsTotal-priceDetail.discount;
      priceDetail.totalPrice = Math.round(priceDetail.totalPrice);

      priceDetail.tax = 0;
      if(!countryDetail.isInternational){     
        let zone = business.profile.shipping.zones[newOrder?.state];
        priceDetail.ST=0;
        priceDetail.CT=0;
        priceDetail.GST=0;
        if (zone === LOCAL) {
          priceDetail.ST = priceDetail.totalPrice * (business.profile.ST / 100);
          priceDetail.CT = priceDetail.totalPrice * (business.profile.CT / 100);
          priceDetail.tax = priceDetail.ST + priceDetail.CT;
        } else {
          priceDetail.GST =
            priceDetail.totalPrice * (business.profile.GST / 100);
          priceDetail.tax = priceDetail.GST;
        }
      }
      //priceDetail.totalPrice = priceDetail.totalPrice+priceDetail.tax;
      priceDetail.currency=countryDetail.currency;
    setOrder((prevOrder) =>{
      return {
        ...prevOrder,
        priceDetail: JSON.stringify(priceDetail),
      }
    });
  }
  function calculateDeliveryCharges(price, newOrder) {
    if(price===0)return 0;
    let deliveryCharges = 0;
    let weight = getSelectedItemsSummary().weight * 1000;
    if (countryDetail.isInternational) {
      business.profile.shipping.internationalSlabs.map((slab) => {
        if(weight >= slab.minWeight && weight <= slab.maxWeight){
          if(slab.type==='ABS')deliveryCharges=slab.shippingCharges;
          else if (slab.type==='INC'){
            deliveryCharges=slab.baseShippingCharges+slab.incShippingCharges*(Math.ceil((weight-slab.minWeight)/slab.step))
          }
        }
      });
      deliveryCharges=Math.ceil(deliveryCharges/countryDetail.exchangeRate);
    }
    else if (business?.profile?.shipping?.type === 'WTD') {
        let zone = business.profile.shipping.zones[newOrder.city];
      if (!zone) {
        zone = business.profile.shipping.zones[newOrder.state];
      }
      if (!zone) {
        zone = 'OutStation';
      }
      business.profile.shipping.slabs.map((slab) => {
        let runningWeight = slab.start;
        deliveryCharges = slab.base;
        let perIncrChatge = slab.perIncrementCost[zone];
        let weightIncrement = slab.increment;
        while (
          weight > runningWeight &&
          (slab.end ? runningWeight < slab.end : true)
        ) {
          deliveryCharges = deliveryCharges + perIncrChatge;
          runningWeight = runningWeight + weightIncrement;
        }
      });
    } else if (business?.profile?.shipping?.type === 'FRE') {
      let exemptFromShippingChanrges = false;
      let res = getSelectedItemsSummary();
      let itemArray = res.itemArray;
      itemArray.forEach((item)=>{
        if(item.exemptFromShippingChanrges)exemptFromShippingChanrges=true
      })
      deliveryCharges =
        price > business?.profile?.shipping?.shippingFreeThreshold
          ? newOrder?.delivery
            ? 0
            : -100
          : newOrder?.delivery
          ? (exemptFromShippingChanrges? 0:business?.profile?.shipping?.deliveryCharges)
          : 0;
    }
    return deliveryCharges; //Local
  }
  function updateLocalOrder(key, value) {
    setOrder((prevOrder) => {
      AsyncStorage.setItem(
        business.id + ACTIVE_CART_ORDER,
        JSON.stringify({
          ...prevOrder,
          [key]: value,
        }),
      );
      return {
        ...prevOrder,
        [key]: value,
      };
    });    
    /*
    if (
      ((key === 'zipCode' && value.length === 6 && order.state && order.city) ||
        (order.zipCode?.length === 6 &&
          order.state &&
          order.state !== SELECT_YOUR_STATE &&
          key === 'city') ||
        (order.zipCode?.length === 6 &&
          order.city &&
          order.city !== SELECT_YOUR_CITY &&
          key === 'state')) &&
      value &&
      value !== SELECT_YOUR_STATE &&
      value !== SELECT_YOUR_CITY
    ) {
      getCourier(key === 'zipCode' ? value : order.zipCode);
    }
    */
    setPageState((prevState) => {
      return {
        ...prevState,
        errorMessage: '',
      };
    });
  }
  function setNewOrder(selectedItems) {
    setOrder((prevOrder) => {
      let newOrder = {
        ...initialOrder,
        userNotSet: user===null,
        businessId: business?.id,
        mobileNumber: user?.mobileNumber,
        deliveryContactNumber: user?.mobileNumber?.substring(user?.mobileNumber.length-PHONE_NUMBER_LENGTH,user?.mobileNumber.length),
        phoneNumberPrefix: user?.mobileNumber?.substring(0,user?.mobileNumber.length-PHONE_NUMBER_LENGTH),
        name: user?.name,
        deliveryContactEmail: user?.email,
        firstName: user?.firstName,
        lastName: user?.lastName,
        address: user?.address,
        delivery: true,
        city: user?.city,
        state: user?.state,
        country: user?.country,
        zipCode: user?.zipCode,
        cod: business?.profile.cod,
        useCustomShipping: business?.profile?.useCustomShipping,
        isAdminOrder: user?.isPrivileged,
      };
      AsyncStorage.setItem(
        business.id + ACTIVE_CART_ORDER,
        JSON.stringify(newOrder)
      );
      return {
        ...newOrder,
        items: JSON.stringify(selectedItems)
      };
    });
  }
  const localStyles = StyleSheet.create({
    infoMessage: {
      padding: 5,
      fontSize: 12,
      marginLeft: 10,
      color: theme?.colors?.info,
    },
    box: {
      marginTop: 20,
      alignSelf: 'center',
      width: '100%',
      alignItems: 'center',
    },
    paymentBox: {
      marginTop: 20,
      alignSelf: 'center',
      width: isMobile ? theme?.screenWidth : theme?.screenWidth / 2,
      alignItems: 'flex-start',
    },
    checkBoxStyle: {
      fontSize: 12,
      color: theme?.colors?.info,
      padding: 0,
      borderBottomColor: 'transparent',
      borderTopColor: 'transparent',
      backgroundColor: 'transparent',
      fontWeight: 'normal',
    },
  });
  
  return visible ? (
    submitPayment ? countryDetail.isInternational?(
      <View style={localStyles.paymentBox}>
        <WebView
          source={{
            uri: INTERNATIONAL_PAYMENT_PAGE + options,
          }}
          ref={webViewIntl}
          style={{
            height: theme?.screenHeight,
            width: isMobile ? theme?.screenWidth : theme?.screenWidth / 3,
            alignSelf: 'center',
          }}
          originWhitelist={['*']}
          mixedContentMode={'compatibility'}
          javaScriptEnabled
          onMessage={handleWebViewMessage}
        />
      </View>
    ):(
      <View style={localStyles.paymentBox}>
        <WebView
          source={{
            uri: PAYMENT_PAGE + options,
          }}
          ref={webView}
          style={{
            height: theme?.screenHeight,
            width: isMobile ? theme?.screenWidth : theme?.screenWidth / 3,
            alignSelf: 'center',
          }}
          originWhitelist={['*']}
          mixedContentMode={'compatibility'}
          javaScriptEnabled
          onMessage={handleWebViewMessage}
        />
      </View>
    ) : (
      <View style={localStyles.box}>
        <View
          style={{
            flexDirection: 'row',
            flexWrap: 'wrap-reverse',
          }}>
          {userState !== USER_STATE.PAYMENT_SUCCESS && (
            <View style={{}}>
              {(user && (user?.isCustomer || user?.isPrivileged)) ||
              (guestCheckOut && guestPhoneConfirmed) ? (
                <View style={{flexDirection: 'column'}}>
                  <InputTextArea
                    name="note"
                    placeholder="Enter order instructions"
                    value={order?.note}
                    onChangeText={updateLocalOrder}
                    autoCompleteType="off"
                    textContentType="none"
                    label="Shipping Instructions"
                    width={maxInputFieldWidth - 20}
                  />
                  <View style={{flexDirection: 'row'}}>
                    {business?.profile?.pickUp ? (
                      <CheckBox
                        textStyle={localStyles.checkBoxStyle}
                        containerStyle={{padding: 0, margin: 0}}
                        checkedColor="grey"
                        title="PickUp"
                        checkedIcon="dot-circle-o"
                        uncheckedIcon="circle-o"
                        checked={!order?.delivery}
                        onPress={(value) => {
                          updateLocalOrder('delivery', false);
                        }}
                      />
                    ) : null}
                    {business?.profile?.delivery ? (
                      <CheckBox
                        title="Delivery"
                        textStyle={localStyles.checkBoxStyle}
                        containerStyle={{padding: 0, margin: 0}}
                        checkedColor="grey"
                        checkedIcon="dot-circle-o"
                        uncheckedIcon="circle-o"
                        checked={order?.delivery}
                        onPress={(value) => {
                          updateLocalOrder('delivery', true);
                        }}
                      />
                    ) : null}
                  </View>
                  <View style={{flexDirection: 'colum'}}>
                    {user?.isPrivileged ? (
                      <>
                        <CheckBox
                          textStyle={localStyles.checkBoxStyle}
                          containerStyle={{padding: 0, margin: 0}}
                          checkedColor="grey"
                          title="Admin Order"
                          checkedIcon="dot-circle-o"
                          uncheckedIcon="circle-o"
                          checked={user?.isPrivileged}
                        />
                        <InputText
                          name="adminNote"
                          placeholder="Admin Note"
                          onChangeText={updateLocalOrder}
                          value={order?.adminNote}
                          label="Admin Note"
                        />
                      </>
                    ) : null}
                  </View>
                  <View
                    style={[styles.orderplacement, {alignItems: 'flex-start', maxWidth: maxInputFieldWidth}]}>
                    <Text style={[styles.infoMessage, {marginLeft: 30}]}>
                      {user?.address ? 'Verify' : 'Enter'} your information
                      below
                    </Text>
                    <InputText
                      name="firstName"
                      placeholder="Enter your First Name"
                      autoComplete={'name-given'}
                      textContentType={'givenName'}
                      onChangeText={updateLocalOrder}
                      value={order?.firstName}
                      label="*First Name"
                    />
                    <InputText
                      name="lastName"
                      placeholder="Enter your Last Name"
                      autoComplete={'name-family'}
                      textContentType={'familyName'}
                      onChangeText={updateLocalOrder}
                      value={order?.lastName}
                      label="*Last Name"
                    />
                    <InputPhone
                      name="deliveryContactNumber"
                      placeholder="10 digit number"
                      autoCompleteType={'username'}
                      autoComplete={'username'}
                      textContentType={'username'}                     
                      onChangeText={updateLocalOrder}
                      value={order?.deliveryContactNumber}
                      label="*Delivery Contact Phone Number"                      
                      selectedValue={order?.phoneNumberPrefix}
                      business={business}
                    />
                    <InputText
                      name="deliveryContactEmail"
                      placeholder="Enter Delivery Contact Email"
                      textContentType= {'emailAddress'}
                      autoComplete= {'email'}
                      onChangeText={updateLocalOrder}
                      value={order?.deliveryContactEmail}
                      label="*Delivery Contact Email.(By providing email you agree to receive emails related to your order)"
                    />
                  </View>
                  {!business?.profile?.pickUp ||
                  (business?.profile?.delivery && order?.delivery) ? (
                    <View
                      style={[
                        styles.orderplacement,
                        {alignItems: 'flex-start'},
                      ]}>
                      <InputTextArea
                        autoCompleteType="street-address"
                        name="address"
                        placeholder="Enter your street address"
                        autoComplete={'street-address'}
                        textContentType={'streetAddressLine1'}
                        numberOfLines={2}
                        onChangeText={updateLocalOrder}
                        value={order?.address}
                        label="*Shipping Address"
                        width={maxInputFieldWidth - 20}
                      />
                      {business?.profile?.cityDropdown &&
                      business?.profile?.stateDropdown ? (
                        <>
                          <Picker
                            selectedValue={order?.state}
                            style={{height: 48, margin: 10, marginTop: 0}}
                            onValueChange={(itemValue, itemIndex) => {
                              updateLocalOrder('state', itemValue);
                            }}>
                            {business?.profile?.shipping.states.map(
                              (element) => {
                                return (
                                  <Picker.Item
                                    label={element}
                                    value={element}
                                    key={element}
                                  />
                                );
                              },
                            )}
                          </Picker>
                          <Picker
                            selectedValue={order?.city}
                            style={{margin: 10, marginTop: 0, height: 48}}
                            onValueChange={(itemValue, itemIndex) => {
                              updateLocalOrder('city', itemValue);
                            }}>
                            {business?.profile?.shipping?.cities[
                              order?.state
                            ]?.map((name) => {
                              return (
                                <Picker.Item
                                  label={name}
                                  value={name}
                                  key={name}
                                />
                              );
                            })}
                          </Picker>
                        </>
                      ) : (
                        <>
                          <InputText
                            name="city"
                            placeholder={SELECT_YOUR_CITY}
                            autoComplete={'postal-address-locality'}
                            textContentType={'addressCity'}
                            onChangeText={updateLocalOrder}
                            value={order?.city}
                            length={6}
                            width={200}
                            label="*City"
                          />
                          <InputText
                            name="state"
                            autoComplete={'postal-address-region'}
                            textContentType={'addressState'}
                            placeholder={SELECT_YOUR_STATE}
                            onChangeText={updateLocalOrder}
                            value={order?.state}
                            length={6}
                            width={200}
                            label="*State"
                          />
                        </>
                      )}
                      <View
                        style={{flexDirection: 'row', alignItems: 'center'}}>
                        <InputText
                          name="zipCode"
                          placeholder="Pin Code"
                          onChangeText={updateLocalOrder}
                          autoComplete={'ppostal-code'}
                          textContentType={'postalCode'}
                          value={order?.zipCode}
                          country = {order?.country}
                          length={6}
                          width={100}
                          label="*Pin Code"
                        />
                        <Text
                          style={{fontSize: theme?.fomts?.sm, paddingLeft: 14}}>
                          Enter correct &nbsp;
                          {!countryDetail.isInternational
                            ? 'PIN '
                            : 'Zip '}
                          code for delivery
                        </Text>
                      </View>
                      {business?.profile?.countryDropDown && (
                      <View
                        style={{flexDirection: 'row', alignItems: 'center'}}>
                        <InputText
                          name="country"
                          placeholder="Country"
                          value={order?.country}
                          length={6}
                          width={150}
                          editable={false}
                          label="*Country"
                        />
                        <Text
                          style={{fontSize: theme?.fomts?.sm, paddingLeft: 14, width:180}}>Update your profile under My Account to change the Country</Text>
                      </View>
                      )}
                    </View>
                  ) : null}
                </View>
              ) : null}
            </View>
          )}
          <View
            style={{
              width: isMobile
                ? theme?.screenWidth - 20
                : theme?.screenWidth / 4,
              marginLeft: 10,
            }}>
            {order?.items && JSON.parse(order.items).length>0 &&(
            <OrderSummary
              order={order}
              theme={theme}
              placeOrder={placeOrder}
              setCurWebView={setCurWebView}
              guestCheckOut={guestCheckOut}
              guestPhoneConfirmed={guestPhoneConfirmed}
              userState={userState}
            />
            )}
            <MyButton
              title="Continue Shopping"
              onPress={() => {
                transitionState(USER_STATE.LANDING);
              }}
            />
          </View>
        </View>
        {userState !== USER_STATE.PAYMENT_SUCCESS && (
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'flex-end',
              flexWrap: 'wrap',
              justifyContent: 'flex-start',
            }}>
            {!guestPhoneConfirmed && !user?.isCustomer ? (
              <>
                {!guestCheckOut && (
                  <ActionButton
                    title="Guest Checkout"
                    onPress={() => setGuestCheckOut(true)}
                  />
                )}
                {guestCheckOut ? (
                  <CustomAuth
                    guestCheckOut={guestCheckOut}
                    setGuestPhoneConfirmed={setGuestPhoneConfirmed}
                    updateLocalOrder={updateLocalOrder}
                  />
                ) : null}
                <ActionButton
                  title="Login to order"
                  onPress={() => {
                    setGuestCheckOut(false);
                    navigation.navigate('Auth', {
                      dest: 'Search',
                      returnState: USER_STATE.CHECKOUT,
                    });
                  }}
                />
              </>
            ) : (
              <>
                {order?.isAdminOrder ? (
                  <ActionButton
                    title="Submit Admin Order"
                    onPress={() => placeOrder(true)}
                  />
                ) : (
                  <>
                    <ActionButton
                      title="Proceed to Payment"
                      onPress={() => placeOrder(false)}
                    />
                    {!order?.delivery ? (
                      <ActionButton
                        title="Pay Cash at PickUp"
                        onPress={() => placeOrder(true)}
                      />
                    ) : null}
                    <MyButton
                      title="View Cart"
                      onPress={() => {
                        transitionState(USER_STATE.CART);
                      }}
                    />
                    <MyAcceptence setCurWebView={setCurWebView} />
                  </>
                )}
              </>
            )}
          </View>
        )}
      </View>
    )
  ) : null;
};
