/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getReview = /* GraphQL */ `
  query GetReview($businessId: ID!, $id: ID!) {
    getReview(businessId: $businessId, id: $id) {
      id
      businessId
      review
      title
      rating
      created
      likeCount
      disLikeCount
      username
      pictureURL
      videoUrl
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listReviews = /* GraphQL */ `
  query ListReviews(
    $businessId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listReviews(
      businessId: $businessId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        businessId
        review
        title
        rating
        created
        likeCount
        disLikeCount
        username
        pictureURL
        videoUrl
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getItems = /* GraphQL */ `
  query GetItems($businessId: ID!, $id: ID!) {
    getItems(businessId: $businessId, id: $id) {
      id
      businessId
      title
      description
      tags
      category
      lengthx
      breadth
      height
      weight
      price
      internationalPrice
      imageKeys
      created
      modified
      quantity
      promotions
      media
      labels
      displayDimensions
      benefits
      ingredients
      subCategory
      usageInstructions
      parent
      unit
      unitMeasure
      allowedSellingUnits
      exemptFromShippingChanrges
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listItems = /* GraphQL */ `
  query ListItems(
    $businessId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelItemsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listItems(
      businessId: $businessId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        businessId
        title
        description
        tags
        category
        lengthx
        breadth
        height
        weight
        price
        internationalPrice
        imageKeys
        created
        modified
        quantity
        promotions
        media
        labels
        displayDimensions
        benefits
        ingredients
        subCategory
        usageInstructions
        parent
        unit
        unitMeasure
        allowedSellingUnits
        exemptFromShippingChanrges
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($businessId: ID!, $id: ID!) {
    getOrder(businessId: $businessId, id: $id) {
      id
      businessId
      items
      mobileNumber
      businessEmail
      price
      note
      status
      termAndConditionsAcceptence
      delivery
      useCustomShipping
      pickupPostcode
      businessName
      orderSortDate
      createdAt
      updatedAt
      externalOrder
      preVerifiedCourierId
      lengthx
      breadth
      height
      weight
      shippingDetails
      priceDetail
      cod
      displayDimensions
      isCashPayment
      isAdminOrder
      adminNote
      deliveryContact
      deliveryAddress
      returnOrder
      paymentId
      externalOrderId
      owner
      __typename
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $businessId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrders(
      businessId: $businessId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        businessId
        items
        mobileNumber
        businessEmail
        price
        note
        status
        termAndConditionsAcceptence
        delivery
        useCustomShipping
        pickupPostcode
        businessName
        orderSortDate
        createdAt
        updatedAt
        externalOrder
        preVerifiedCourierId
        lengthx
        breadth
        height
        weight
        shippingDetails
        priceDetail
        cod
        displayDimensions
        isCashPayment
        isAdminOrder
        adminNote
        deliveryContact
        deliveryAddress
        returnOrder
        paymentId
        externalOrderId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBusiness = /* GraphQL */ `
  query GetBusiness($id: ID!) {
    getBusiness(id: $id) {
      id
      rank
      domain
      name
      location
      profile
      tags
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listBusinesses = /* GraphQL */ `
  query ListBusinesses(
    $id: ID
    $filter: ModelBusinessFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBusinesses(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        rank
        domain
        name
        location
        profile
        tags
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFeedback = /* GraphQL */ `
  query GetFeedback($id: ID!) {
    getFeedback(id: $id) {
      id
      isFeedback
      canDoBetter
      doingGood
      isBizLead
      isSupportRequest
      name
      username
      contactInfo
      note
      issue
      businessEmail
      businessId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listFeedbacks = /* GraphQL */ `
  query ListFeedbacks(
    $id: ID
    $filter: ModelFeedbackFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFeedbacks(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        isFeedback
        canDoBetter
        doingGood
        isBizLead
        isSupportRequest
        name
        username
        contactInfo
        note
        issue
        businessEmail
        businessId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
