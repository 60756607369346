import {Analytics, Auth} from 'aws-amplify';
import React, {Fragment, useState, useContext} from 'react';
import {Text, View, ActivityIndicator} from 'react-native';
import {cleanPhoneNumber, PHONE_NUMBER_LENGTH, USER_EVENTS} from '../../utilities/Constants';
import {
  constructUser,
} from '../../utilities/RetailClient';
import {styles, maxInputFieldWidth, MyContext} from '../../styles/styles';
import {
  InputPhone,
  ActionButton,
  InputText,
} from '../../components/ui/elements';
import {MyStorage as AsyncStorage} from './../../utilities/MyStorage';
import I18n from './../../I18n/I18n';
/*Auth.configure({
  authenticationFlowType: 'CUSTOM_AUTH',
});*/
export const CustomAuth = (props) => {
  const {business, theme} = useContext(MyContext);
  const [state, setState] = useState({
    mobileNumber: '',
    authCode: '',
    stage: 0,
    error: '',
    challengeResponse: '',
    user: null,
    challengeUser: null,
    phoneNumberPrefix: '',
  });

  const [showActivityIndicator, setShowActivityIndicator] = useState(false);
  const onChangeText = (key, value) => {
    setState((prevState) => {
      return {
        ...prevState,
        [key]: value?value.trim():value,
        error: '',
      };
    });
  };
  const customSignIn = async () => {
    const {mobileNumber: username, phoneNumberPrefix} = state;
    if (phoneNumberPrefix?.length <2) {
      setState((prevState) => {
        return {
          ...prevState,
          error: I18n.t('INVALID_COUNTRY_CODE'),
        };
      });
      return;
    }
    if (cleanPhoneNumber(username)?.length !==PHONE_NUMBER_LENGTH) {
      setState((prevState) => {
        return {
          ...prevState,
          error: I18n.t('INVALID_PHONE'),
        };
      });
      return;
    }
    setShowActivityIndicator(true);
    await Auth.signIn(phoneNumberPrefix+cleanPhoneNumber(username))
      .then((challengeUser) => {
        if (challengeUser.challengeName === 'CUSTOM_CHALLENGE') {
          setState((cur) => {
            return {
              ...cur,
              challengeUser: challengeUser,
              stage: 1,
            };
          });
        } else {
          console.log(challengeUser);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.code === 'UserNotFoundException') {
          console.log('UserNotFoundException', JSON.stringify(err));
          customSignUp();
        } else if (err.code === 'UsernameExistsException') {
          console.log('UsernameExistsException', JSON.stringify(err));
          customSignIn();
        } else {
          console.log('unknown error during sign in', JSON.stringify(err));
          setState((prevState) => {
            return {
              ...prevState,
              error: I18n.t('REQ_FAILED'),
            };
          });
        }
      })
      .finally(() => {
        setShowActivityIndicator(false);
      });
  };
  const customSignUp = async () => {
    if (state.phoneNumberPrefix?.length <2) {
      setState((prevState) => {
        return {
          ...prevState,
          error: I18n.t('INVALID_COUNTRY_CODE'),
        };
      });      
      return;
    }
    setShowActivityIndicator(true);
    await Auth.signUp({
      username: state.phoneNumberPrefix+cleanPhoneNumber(state.mobileNumber),
      password: 'Aab' + Math.random(8),
    })
      .then(() => {
        customSignIn();
      })
      .catch(() => {
        console.log('unknown error during sign in');
        setState((prevState) => {
          return {
            ...prevState,
            error: I18n.t('REQ_FAILED'),
          };
        });
      })
      .finally(() => {
        setShowActivityIndicator(false);
      });
  };

  const verifyChallenge = async () => {
    setShowActivityIndicator(true);
    const {challengeUser, authCode} = state;
    Auth.sendCustomChallengeAnswer(challengeUser, authCode)
      .then((userInternal) => {
        console.log(userInternal);
        props.setGuestPhoneConfirmed(true);
        props.updateLocalOrder(
          'mobileNumber',
          state.phoneNumberPrefix+userInternal.username,
        );
        let userObject = constructUser(userInternal);
        setState((cur) => {
          return {
            ...cur,
            user: userObject,
            challengeUser: null,
          };
        });
        AsyncStorage.setItem('user', userObject);
        Analytics.record(USER_EVENTS.SIGN_IN);
        // Auth.currentAuthenticatedUser().then((user) => { });
      })
      .catch((err) => {
        console.log('error signing up...', err);
        Analytics.record(USER_EVENTS.SIGN_UP_FAILURE);
        setState((cur) => {
          return {
            ...cur,
            error: I18n.t('INVALID_VERIFY_CODE'),
          };
        });
      })
      .finally(() => {
        setShowActivityIndicator(false);
      });
  };
  return (
    <View
      style={{
        maxWidth: maxInputFieldWidth,
        alignSelf: 'center',
        alignItems: 'center',
      }}>
      {showActivityIndicator && (
        <ActivityIndicator animating size="large" color="grey" />
      )}
      {state.stage === Number(0) && (
        <Fragment>
          <Text style={styles.errorMsg}>{state.error}</Text>
          <InputPhone
            placeholder="10 digit number"
            name="mobileNumber"
            value={state.mobileNumber}
            onChangeText={onChangeText}
            label="*Mobile Number"
            autoCompleteType={'username'}
            autoComplete={'username'}
            textContentType={'username'}
            selectedValue={state?.phoneNumberPrefix}
            business={business}
          />
          <ActionButton
            style={styles.bottomMessageHighlight}
            title="CheckOut as Guest"
            onPress={customSignIn}
          />
        </Fragment>
      )}
      {state.stage === Number(1) && (
        <Fragment>
          <Text style={styles.errorMsg}>{state.error}</Text>
          <InputText
            placeholder="Please enter Confirmation Code"
            value={state.authCode}
            name="authCode"
            onChangeText={onChangeText}
            autoComplete="sms-otp"
            textContentType="oneTimeCode"
            label="*Confirmation Code"
          />
          <ActionButton title="Verify Phone" onPress={verifyChallenge} />
        </Fragment>
      )}
    </View>
  );
};
