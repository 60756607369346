import React, {useContext} from 'react';
import {View, StyleSheet} from 'react-native';
import {Text} from 'react-native-elements';

import {ActionButton, MyAcceptence, MySeparator} from '../ui//elements';
import {isMobile} from 'react-device-detect';
import {MyContext} from '../../styles/styles';
import {OrderItemPrice} from '../orderItem/OrderItemPrice';
import {USER_STATE} from '../../utilities/Constants';

export const OrderSummary = ({
  setCurWebView,
  order,
  placeOrder,
  guestCheckOut,
  guestPhoneConfirmed,
  userState,
}) => {
  const context = useContext(MyContext);
  const user = context.user;
  const business = context.business;
  const theme = context.theme;
  const localStyles = StyleSheet.create({
    infoMessage: {
      padding: 5,
      fontSize: theme?.fonts.m,
      margin: 10,
      color: theme?.colors?.info,
    },
    infoMessageBold: {
      padding: 5,
      fontSize: theme?.fonts.ml,
      fontWeight: 'normal',
      fontStyle: 'italic',
      margin: 10,
      color: theme?.colors?.info,
    },
    infoMessageHighLight: {
      padding: 5,
      fontSize: theme?.fonts.l,
      margin: 10,
      color: theme?.colors?.primary,
    },
    box: {
      borderColor: 'lighgrey',
      borderWidth: 1,
      borderRadius: 10,
      marginBottom: 10,
      padding: 10,
    },
  });

  return (
    <View style={localStyles.box}>
      {((user && (user?.isCustomer || user?.isPrivileged)) ||
        (guestCheckOut && guestPhoneConfirmed)) &&
      userState !== USER_STATE.PAYMENT_SUCCESS ? (
        <>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            {order?.isAdminOrder ? (
              <ActionButton
                title="Submit Admin Order"
                onPress={() => placeOrder(true)}
              />
            ) : (
              <ActionButton
                title="Proceed to Payment"
                onPress={() => placeOrder(false)}
              />
            )}
            {!order?.delivery && !order?.isAdminOrder ? (
              <ActionButton
                title="Pay Cash at PickUp"
                onPress={() => placeOrder(true)}
              />
            ) : null}
          </View>

          <MyAcceptence setCurWebView={setCurWebView} />
          <MySeparator />
        </>
      ) : null}

      <View style={{flexDirection: 'row', justifyContent: 'center'}}>
        <Text style={[localStyles.infoMessageHighLight]}>Order Summary</Text>
      </View>
      {order && <OrderItemPrice item={order} />}
      {!business?.profile?.useCustomShipping ? (
        <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
          <Text style={localStyles.infoMessage}>
            {order?.delivery
              ? order?.priceDetail &&
                JSON.parse(order?.priceDetail)?.shippingHandling
                ? ''
                : 'Shipping and Handling will be calculated and added after you enter valid pin code'
              : ''}
          </Text>
        </View>
      ) : (
        <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
          <Text style={localStyles.infoMessageBold}>
            {business?.profile?.shipping?.type === 'FRE' && order?.country === 'India'
              ? order?.delivery
                ? 'For orders valued at ' +
                  business?.profile?.shipping?.shippingFreeThreshold +
                  '/- or more, enjoy free shipping.\nFor orders below  ' +
                  business?.profile?.shipping?.shippingFreeThreshold +
                  '/- , a shipping charge of ' +
                  business?.profile?.shipping?.deliveryCharges +
                  '/- will apply.\n'
                : 'For pickup orders exceeding ' +
                  business?.profile?.shipping?.shippingFreeThreshold +
                  '/-, avail a special discount of ' +
                  business?.profile?.shipping?.deliveryCharges +
                  '/-'
              : 'For seamless international orders, we highly recommend purchasing our Economy Packs. These specially curated packages are designed to minimize shipping costs for international deliveries.'}
          </Text>
        </View>
      )}
    </View>
  );
};
