import {gql} from 'apollo-boost';

export const orderItemSearch = gql`
  query listItems(
    $parent: String
    $id: ID
    $businessId: ID
    $nextToken: String
  ) {
    listItems(
      filter: {
        or: [
          {parent: {eq: $parent}}
          {id: {eq: $id}}
        ]
        and: [{businessId: {eq: $businessId}}]
      }
      limit: 200
      nextToken: $nextToken
    ) {
      items {
        id
        title
        price
        internationalPrice
        weight
        displayDimensions
        exemptFromShippingChanrges
        lengthx
        category
        benefits
        unit
        allowedSellingUnits
        unitMeasure
        ingredients
        usageInstructions
        subCategory
        parent
        breadth
        height
        imageKeys
        description
        tags
      }
      nextToken
    }
  }
`;
export const orderItemList = gql`
  query listItems(
    $parent: String
    $id: ID
    $businessId: ID
    $nextToken: String
  ) {
    listItems(
      filter: {
        or: [
          {parent: {eq: $parent}}
          {id: {eq: $id}}
        ]
        and: [{businessId: {eq: $businessId}}]
      }
      limit: 200
      nextToken: $nextToken
    ) {
      items {
        id
        title
        price
        internationalPrice
        weight
        displayDimensions
        exemptFromShippingChanrges
        lengthx
        category
        benefits
        unit
        allowedSellingUnits
        unitMeasure
        ingredients
        usageInstructions
        subCategory
        parent
        breadth
        height
        imageKeys
        description
        tags
      }
      nextToken
    }
  }
`;
export const apolloSearch = gql`
  query listItems(
    $tag: String
    $id: ID
    $businessId: ID
    $nextToken: String
  ) {
    listItems(
      filter: {
        or: [
          {tags: {eq: $tag}}
          {title: {eq: $tag}}
          {category: {eq: $tag}}
          {benefits: {eq: $tag}}
          {ingredients: {eq: $tag}}
          {subCategory: {eq: $tag}}
          {id: {eq: $id}}
          {imageKeys: {eq: $tag}}
        ]
        and: [{businessId: {eq: $businessId}}]
      }
      limit: 200
      nextToken: $nextToken
    ) {
      items {
        id
        title
        internationalPrice
        price
        weight
        displayDimensions
        exemptFromShippingChanrges
        lengthx
        category
        benefits
        unit
        allowedSellingUnits
        unitMeasure
        ingredients
        usageInstructions
        subCategory
        parent
        breadth
        height
        imageKeys
        description
        tags
      }
      nextToken
    }
  }
`;
export const listItems = gql`
  query listItems(
    $businessId: ID
  ) {
    listItems(
      businessId: $businessId
      limit: 200
      nextToken: null
    ) {
      items {
        id
        title
        price
        internationalPrice
        weight
        displayDimensions
        exemptFromShippingChanrges
        category
        benefits
        unit
        allowedSellingUnits
        unitMeasure
        ingredients
        usageInstructions
        subCategory
        parent
        lengthx
        breadth
        height
        quantity
        imageKeys
        description
        tags
      }
      nextToken
    }
  }
`;
export const ignorePriceRange = gql`
  query listItems(
    $tag: String
    $id: ID
    $businessId: ID
    $nextToken: String
  ) {
    listItems(
      sort: {field: subCategory, direction: asc}
      filter: {
        or: [
          {tags: {eq: $tag}}
          {title: {eq: $tag}}
          {category: {eq: $tag}}
          {benefits: {eq: $tag}}
          {ingredients: {eq: $tag}}
          {subCategory: {eq: $tag}}
          {id: {eq: $id}}
          {imageKeys: {eq: $tag}}
        ]
        and: [{businessId: {eq: $businessId}}]
      }
      limit: 200
      nextToken: $nextToken
    ) {
      items {
        id
        title
        price
        internationalPrice
        weight
        displayDimensions
        exemptFromShippingChanrges
        category
        benefits
        unit
        allowedSellingUnits
        unitMeasure
        ingredients
        usageInstructions
        subCategory
        parent
        lengthx
        breadth
        height
        quantity
        imageKeys
        description
        tags
      }
      nextToken
    }
  }
`;
export const allPriceRange = gql`
  query listItems(
    $tag: String
    $id: ID
    $businessId: ID
    $nextToken: String
  ) {
    listItems(
      sort: {field: price, direction: asc}
      filter: {
        or: [
          {tags: {eq: $tag}}
          {title: {eq: $tag}}
          {id: {eq: $id}}
          {category: {eq: $tag}}
          {benefits: {eq: $tag}}
          {ingredients: {eq: $tag}}
          {subCategory: {eq: $tag}}
          {imageKeys: {eq: $tag}}
        ]
        and: [{businessId: {eq: $businessId}}]
      }
      limit: 200
      nextToken: $nextToken
    ) {
      items {
        id
        title
        price
        internationalPrice
        weight
        displayDimensions
        exemptFromShippingChanrges
        category
        benefits
        unit
        allowedSellingUnits
        unitMeasure
        ingredients
        usageInstructions
        subCategory
        parent
        lengthx
        breadth
        height
        quantity
        imageKeys
        description
        tags
      }
      nextToken
    }
  }
`;
export const catchAll = gql`
  query listItems(
    $tag: String
    $id: ID
    $businessId: ID
    $nextToken: String
    $range: [Float]
  ) {
    listItems(
      sort: {field: price, direction: asc}
      filter: {
        or: [
          {tags: {eq: $tag}}
          {title: {eq: $tag}}
          {category: {eq: $tag}}
          {benefits: {eq: $tag}}
          {ingredients: {eq: $tag}}
          {subCategory: {eq: $tag}}
          {id: {eq: $id}}
          {imageKeys: {eq: $tag}}
        ]
        and: [{businessId: {eq: $businessId}}, {price: {range: $range}}]
      }
      limit: 200
      nextToken: $nextToken
    ) {
      items {
        id
        title
        price
        internationalPrice
        weight
        displayDimensions
        exemptFromShippingChanrges
        category
        benefits
        unit
        allowedSellingUnits
        unitMeasure
        ingredients
        usageInstructions
        subCategory
        parent
        lengthx
        breadth
        height
        quantity
        imageKeys
        description
        tags
      }
      nextToken
    }
  }
`;
export const noFilter = gql`
  query listItems($businessId: ID, $nextToken: String) {
    listItems(
      sort: {field: price, direction: asc}
      filter: {and: [{businessId: {eq: $businessId}}]}
      limit: 200
      nextToken: $nextToken
    ) {
      items {
        id
        title
        price
        internationalPrice
        weight
        displayDimensions
        exemptFromShippingChanrges
        category
        benefits
        unit
        allowedSellingUnits
        unitMeasure
        ingredients
        usageInstructions
        subCategory
        parent
        lengthx
        breadth
        height
        quantity
        imageKeys
        description
        tags
      }
      nextToken
    }
  }
`;
