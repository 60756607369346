import React from 'react';
import {TouchableHighlight} from 'react-native';
import {styles} from '../../styles/styles';
import {Icon} from 'react-native-elements';
import {MyContext} from '../../styles/styles';


export const MyIcon = ({
  name,
  type,
  onPress,
  reverse,
  raised,
  color,
  size,
  containerStyle,
}) => {
  const theme = React.useContext(MyContext)?.theme;
  return (
    <Icon
      raised={raised ? raised : false}
      reverse={reverse ? reverse : false}
      containerStyle={containerStyle ? containerStyle : styles.hdrcloseButton}
      name={name}
      type={type}
      color={color ? color : theme?.colors?.secondaryVariant}
      size={size ? size : 25}
      z-index={250}
      hitSlop={{top: 25, bottom: 25, left: 25, right: 25}}
      Component={TouchableHighlight}
      activeOpacity={0.6}
      underlayColor={theme?.colors?.iconTouchHighlight}
      onPress={onPress}
    />
  );
};
