import React from 'react';
import {useState, useContext, useEffect} from 'react';
import {Text, View} from 'react-native';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import {styles, MyContext} from '../../styles/styles';
import {CommonProfile} from './../../utilities/profile';
import {ForgotPassword} from './ForgotPassword';
import {Profile} from './Profile';
import {SignIn} from './SignIn';
import {SignUp} from './SignUp';
import {MyStatusBar} from '../ui/MyStatusBar';
import {MyButton, MyAcceptence, MyLinks} from '../ui/elements';
import {Footer} from '../footer/Footer';
import {useScrollToTop} from '@react-navigation/native';
import {
  HISTORY_PREFIX,
  USER_STATE,
  USER_STATE_PREFIX,
} from '../../utilities/Constants';
import {MyStorage as AsyncStorage} from '../../utilities/MyStorage';
import {updateHistory} from '../../utilities/RetailClient';

export const Auth = ({route, navigation}) => {
  const [userState, setUserState] = useState(
    AsyncStorage.getItem(USER_STATE_PREFIX + route.name)
      ? AsyncStorage.getItem(USER_STATE_PREFIX + route.name)
      : USER_STATE.PROFILE,
  );

  const context = useContext(MyContext);
  const [state, setStateInternal] = useState({
    showSignUp: false,
    formType: 'showSignIn',
  });
  const [searchedOrderId, setSearchedOrderId] = useState();
  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', (e) => {
      setCurWebView(null);
    });

    context?.user ? setState('showProfile') : null;
    setStateInternal((prevState) => {
      return {
        ...prevState,
        formType: context?.user ? 'showProfile' : 'showSignIn',
      };
    });
  }, [context.user, navigation]);

  useEffect(() => {
    if (route?.params?.userState) {
      transitionState(route.params.userState, route.params.skipHistory);
    } else if (route?.params?.orderId) {
      transitionState(USER_STATE.ORDER_DETAIL, true);
      setSearchedOrderId(route?.params?.orderId);
    }
  }, [route]);

  const showSignIn = state.formType === 'showSignIn';
  const showSignUp = state.formType === 'showSignUp';
  const showForgotPassword = state.formType === 'showForgotPassword';
  const showProfile = state.formType === 'showProfile';
  const [curWebView, setCurWebViewInternal] = useState();
  function setCurWebView(wv) {
    setCurWebViewInternal(wv);
    window.scrollTo(0, 0);
  }
  const ref = React.useRef(null);
  useScrollToTop(ref);

  const setState = (key, value) => {
    setStateInternal((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  const toggleAuthType = (formType) => {
    window.scrollTo(0,0);
    setState('formType', formType);
  };

  function transitionState(newState, skipHistory) {
    console.log('setting state ', state);
    switch (newState) {
      case USER_STATE.PROFILE:
        setUserState(USER_STATE.PROFILE);
        break;
      case USER_STATE.ORDERS:
        setUserState(USER_STATE.ORDERS);
        break;
      case USER_STATE.ORDER_DETAIL:
        setUserState(USER_STATE.ORDER_DETAIL);
        break;
    }
    let curRoute = route.name;
    updateHistory(route.name, route.name, newState, skipHistory);
  }
  return (
    <>
      <KeyboardAwareScrollView
        contentContainerStyle={[styles.KeyboardAwareScrollViewStyle]}
        scrollEnabled
        showsVerticalScrollIndicator={true}
        automaticallyAdjustContentInsets={false}
        resetScrollToCoords={{x: 0, y: 0}}
        keyboardDismissMode="on-drag"
        keyboardShouldPersistTaps="always">
        {curWebView ? (
          <MyLinks
            business={context.business}
            curWebView={curWebView}
            setCurWebView={setCurWebView}
          />
        ) : (
          <>
            <View style={{flex: 1}}>
              {showSignIn && (
                <>
                  <SignIn
                    toggleAuthType={toggleAuthType}
                    dest={route?.params?.dest}
                    navigation={navigation}
                    returnState={route.params.returnState}
                  />
                  <View style={{alignSelf: 'center'}}>
                    <MyAcceptence setCurWebView={setCurWebView} />
                  </View>
                </>
              )}
              {showSignUp && (
                <>
                  <SignUp toggleAuthType={toggleAuthType} />
                  <View style={{alignSelf: 'center'}}>
                    <MyAcceptence setCurWebView={setCurWebView} />
                  </View>
                </>
              )}
              {showProfile && (
                <Profile
                  toggleAuthType={toggleAuthType}
                  dest={route.params.dest}
                  navigation={navigation}
                  userState={userState}
                  transitionState={transitionState}
                  searchedOrderId={searchedOrderId}
                />
              )}
              {showForgotPassword && (
                <ForgotPassword toggleAuthType={toggleAuthType} />
              )}

              {!showProfile ? (
                <View>
                  {showSignUp || showForgotPassword ? (
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}>
                      <Text style={styles.bottomMessage}>
                        Already signed up?
                      </Text>
                      <MyButton
                        onPress={() => toggleAuthType('showSignIn')}
                        title="Sign In"
                      />
                    </View>
                  ) : (
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}>
                      <Text style={styles.bottomMessage}>Need an account?</Text>
                      <MyButton
                        onPress={() => toggleAuthType('showSignUp')}
                        title="Sign Up"
                      />
                    </View>
                  )}
                </View>
              ) : null}
            </View>
          </>
        )}
        <Footer setCurWebView={setCurWebView} />
      </KeyboardAwareScrollView>
    </>
  );
};
