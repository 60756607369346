import React from 'react';
import Dropzone from 'react-dropzone';
export const FileUploader = ({setImage}) => {
  return (
    <Dropzone onDrop={(acceptedFiles) => setImage(acceptedFiles)}>
      {({getRootProps, getInputProps}) => (
        <section>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <p>Click here to select file</p>
          </div>
        </section>
      )}
    </Dropzone>
  );
};
