import {MYSTORAGE_KEY_PREFIX, CART_SUFFIX} from '../utilities/Constants';

let dataMemory = {};
const jsonValues = ['user', 'business'];
let debug = false;
/** @class */
export class MyStorage {
  static syncPromise = null;

  /**
   * This is used to set a specific item in storage
   */
  static setItem(key, value) {
    window.localStorage.setItem(
      MYSTORAGE_KEY_PREFIX + key,
      jsonValues.includes(key) || key.endsWith(CART_SUFFIX)
        ? JSON.stringify(value)
        : value,
    );
    dataMemory[key] = value;
    if (debug) {
      console.log('stored', key, dataMemory[key]);
    }
    return dataMemory[key];
  }

  /**
   * This is used to get a specific key from storage
   */
  static getItem(key) {
    //        let value = Object.prototype.hasOwnProperty.call(dataMemory, key) ? dataMemory[key] : undefined;
    if (!dataMemory[key]) {
      dataMemory[key] = this.getFromStorage(MYSTORAGE_KEY_PREFIX + key);
    }
    if (debug) {
      console.log('getting', key, dataMemory[key]);
    }
    return dataMemory[key];
  }

  /**
   * This is used to remove an item from storage
   */
  static removeItem(key) {
    if (debug) {
      console.log('removing', key);
    }
    if (key.endsWith(CART_SUFFIX)) {
      if (debug) {
        console.log('removing Cart', key);
      }
      let numKeys = window.localStorage.length;
      for (var i = 0; i < numKeys; i++) {
        let localKey = window.localStorage.key(i);
        if (localKey.endsWith(CART_SUFFIX)) {
          window.localStorage.removeItem(key);
          delete dataMemory[key];
        }
      }
    } else {
      window.localStorage.removeItem(MYSTORAGE_KEY_PREFIX + key);
      delete dataMemory[key];
    }
  }

  /**
   * This is used to clear the storage
   */
  static clear() {
    if (debug) {
      console.log('clearing All');
    }
    dataMemory = {};
    window.localStorage.clear();
    return dataMemory;
  }

  /**
   * Will sync the MyStorage data from AsyncStorage to storageWindow MyStorage
   */
  static sync() {
    if (!MyStorage.syncPromise) {
      MyStorage.syncPromise = new Promise((res, rej) => {
        let numKeys = window.localStorage.length;
        for (var i = 0; i < numKeys; i++) {
          let localKey = window.localStorage.key(i);
          this.getFromStorage(localKey);
        }
        res();
      });
    }
    return MyStorage.syncPromise;
  }

  static getFromStorage(localKey) {
    if (localKey.startsWith(MYSTORAGE_KEY_PREFIX)) {
      let localValue = window.localStorage.getItem(localKey);
      const memoryKey = localKey.replace(MYSTORAGE_KEY_PREFIX, '');
      dataMemory[memoryKey] =
        jsonValues.includes(memoryKey) || localKey.endsWith(CART_SUFFIX)
          ? JSON.parse(localValue)
          : localValue;
      return dataMemory[memoryKey];
    }
  }
}
