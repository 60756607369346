export default {
  HOME_noteTitle: 'Note Title',
  HOME_pleaseTypeYourNote: 'Please type your note below',
  HOME_startTakingNotes: 'Start taking notes',
  HOME_save: 'Save',
  HOME_characters: 'chacters',
  ABOUT_us: 'About Us',
  ABOUT_theApp: 'About the app',
  ABOUT_theCreators: 'About the Creators',
  ABOUT_theAppDesc: 'About the app',
  ABOUT_theCreatorsDesc: 'About the Creators',
  ORDER_PLACED:
    "Your order has been placed. Please check your email for details(check your spam folder if you don't see email)",
  PICKUP_SENT:
    "Your order pickup request has been sent. Order will be ready for pick up in one hour. Please check your email for details (check your spam folder if you don't see email)",
  CITY_STATE: 'Please select your city and state and try again',
  REQ_FAILED: 'Your request failed. Please try again',
  ACCEPT_TC: 'Please acceept terms and conditions and try again',
  DELIVERY_PREP: 'Your order is being prepared for delivery',
  REQ_FAILED_PAYMENT: 'Payment was aborted. Please try payment again',
  INVALID_ZIP: 'Please enter valid Pin code',
  INVALID_PIN: 'Please enter valid Pin code',
  REQ_FIRST_NAME: 'Please enter your First Name',
  REQ_COUNTRY: 'Please enter Country for Shipping Address',
  REQ_LAST_NAME: 'Please enter your Last Name',
  REQ_EMAIL: 'Please enter your email',
  REQ_ADDR: 'Please enter your address',
  REQ_MISSING: 'Please enter all required data',
  INVALID_VERIFY_CODE: 'Invalid verification code. Please try agaiin...',
  INVALID_COUNTRY_CODE: 'Plese select country code',
  INVALID_COUNTRY: 'Please select country',
  MISMATCH_PASSWORD: 'Re-enter passwords. Passwords don\'t match',
  INVALID_PHONE: 'Please enter 10 digit mobile number',
  INVALID_EMAIL: 'Please enter valid email address',
  NO_RESULTS: 'No matches found. Please try again',
  PWD_CHD: 'Password Changed Successfully! Please Login again',
  REQ_QTY: 'Please add at least one item with quantity 1 or more to your cart'
};
