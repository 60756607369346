import {Analytics, Auth} from 'aws-amplify';
import React, {Fragment, useContext, useState} from 'react';
import {Text, View, ActivityIndicator} from 'react-native';
import {cleanPhoneNumber, PHONE_NUMBER_LENGTH, SELECT_YOUR_COUNTRY, USER_EVENTS} from '../../utilities/Constants';
import {styles, maxInputFieldWidth, MyContext} from '../../styles/styles';
import {
  InputPhone,
  InputSecret,
  ActionButton,
  InputText,
  InputTextArea,
  MyCountryPicker,
} from '../../components/ui/elements';
import I18n from '../../I18n/I18n';

export const SignUp = (props) => {
  const [userProfile, setUserProfileInternal] = useState({
    username: '',
    password: '',
    confirmPassword: '',
    phone_number: '',
    authCode: '',
    stage: 0,
    error: '',
    hidePassword: true,
    address: '',
    family_name: '',
    given_name: '',
    phoneNumberPrefix: '',
    showActivityIndicator: false,
    country: '',
  });
  const {business, theme} = useContext(MyContext);
  const setUserProfile = (key, value) => {
    setUserProfileInternal((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  const onChangeText = (key, value) => {
    if(value && !(key ==='city' || key ==='address'))value= value.trim();
    setUserProfile(key, value);
    setUserProfile('error', '');
  };
  const signUp = async () => {
    const {
      username,
      password,
      confirmPassword,
      family_name,
      given_name,
      address,
      email,
      city,
      state,
      zipCode,
      phoneNumberPrefix,
      phone_number,
      country,
    } = userProfile;
    try {
      let err= null;
      if (
        business?.profile?.delivery &&
        !(given_name && address && email && city && state && zipCode && country)
      ) {
          err= I18n.t('REQ_MISSING');
      }else if (phoneNumberPrefix?.length <2) {
        err = I18n.t('INVALID_COUNTRY_CODE');
      }else if (confirmPassword!==password) {
        err = I18n.t('MISMATCH_PASSWORD');
      }else if (cleanPhoneNumber(phone_number)?.length !==PHONE_NUMBER_LENGTH) {
        err= I18n.t('INVALID_PHONE');
      }else if (country===SELECT_YOUR_COUNTRY) {
        err= I18n.t('INVALID_COUNTRY');
      }else{
        business?.profile?.shipping?.allowedCountries.map(
          (element) => {
             if(element['country']===country && element['zipLength']!==zipCode?.length){
              err = I18n.t('INVALID_ZIP');
             }
          }
        );
      }
      if(err){
      setUserProfile('error', err);
      }else{
        setUserProfile('showActivityIndicator', true);
        await Auth.signUp({
          username: phoneNumberPrefix+cleanPhoneNumber( phone_number),
          password: password,
          attributes: {
            name: given_name + given_name ? '' : ' ' + family_name,
            family_name: family_name,
            given_name: given_name,
            address: address,
            email: email,
            'custom:city': city,
            'custom:state': state,
            'custom:zipCode': zipCode,
            'custom:country': country,
            nickname: 'REG',
          },
        });
        setUserProfile('showActivityIndicator', false);
        console.log('successful sign up..');
        setUserProfile('stage', 1);
      }
      }catch (err) {
        setUserProfile('showActivityIndicator', false);
        console.log('error signing up...', err);
        if (err.code === 'UsernameExistsException') {
          setUserProfile(
            'error',
            'User already exists. "Try Sign In" link below.  If you forgot your password, use "Forgot you password" to recreate password ',
          );
        } else {
          setUserProfile(
            'error',
            'Please check Mobile Number and password. Password must be at least 8 characters and must include at least two character one of which is uppercase and one number',
          );
        }
    }finally{
      window.scrollTo(0,0);
    }
  };
  const confirmSignUp = async () => {
    setUserProfile('showActivityIndicator', true);
    const {username, authCode, phoneNumberPrefix} = userProfile;
    try {
      await Auth.confirmSignUp(phoneNumberPrefix+cleanPhoneNumber(username), authCode);
      setUserProfile('showActivityIndicator', false);
      props.toggleAuthType('showSignIn');
      Analytics.record(USER_EVENTS.SIGN_UP);
    } catch (err) {
      setUserProfile('showActivityIndicator', false);
      console.log('error signing up...', err);
      Analytics.record(USER_EVENTS.SIGN_UP_FAILURE);
      setUserProfile(
        'error',
        'Invalid confirmation code. Please try agaiin...',
      );
    }
  };
  return (
    <View
      style={{
        maxWidth: maxInputFieldWidth,
        alignSelf: 'center',
        alignItems: 'center',
      }}>
      {userProfile.showActivityIndicator && (
        <ActivityIndicator animating size="large" color="grey" />
      )}
      {userProfile.stage === Number(0) && (
        <Fragment>
          <Text style={styles.errorMsg}>{userProfile.error}</Text>
          <InputPhone
            placeholder="10 digit number"
            name="phone_number"
            value={userProfile.phone_number}
            autoCompleteType={'username'}
            autoComplete={'username'}
            textContentType={'username'}
            onChangeText={onChangeText}
            selectedValue={userProfile?.phoneNumberPrefix}
            business={business}
            label="*Mobile Number. (By providing your mobile number, you give us your consent to receive SMS messages from our business during registration and forgot password process)"
          />
          <InputSecret
            placeholder="Password"
            name="password"
            value={userProfile.password}
            onChangeText={onChangeText}
            label="*Password (Password must be minimum 8 charctaters and have a lower case, an upppercase and a numeric character)"
          />
          <InputSecret
            placeholder="Confirm Password"
            name="confirmPassword"
            value={userProfile.confirmPassword}
            onChangeText={onChangeText}
            label="*Confirm Password"
          />
          <InputText
            placeholder="Please enter your first name"
            name="given_name"
            autoComplete={'name-given'}
            textContentType={'givenName'}
            value={userProfile.given_name}
            onChangeText={onChangeText}
            label={(business?.profile?.delivery ? '*' : '') + 'First Name'}
          />
          <InputText
            placeholder="Please enter your last name"
            name="family_name"
            autoComplete={'name-family'}
            textContentType={'familyName'}
            value={userProfile.family_name}
            onChangeText={onChangeText}
            label={(business?.profile?.delivery ? '*' : '') + 'Last Name'}
          />
          <InputText
            placeholder="Please enter your Email"
            name="email"
            textContentType= {'emailAddress'}
            autoComplete= {'email'}
            value={userProfile.email}
            onChangeText={onChangeText}
            label={
              (business?.profile?.delivery ? '*' : '') +
              'Email. (By providing email you agree to receive emails related \n to your orders. You can remove email at any time to stop \n receiving email)'
            }  
          />
          <InputTextArea
            placeholder="Please Enter your street address for delivery"
            name="address"
            value={userProfile.address}
            autoComplete={'street-address'}
            textContentType={'streetAddressLine1'}
            onChangeText={onChangeText}
            label={
              (business?.profile?.delivery ? '*' : '') + 'Shipping Address'
            }
            width={maxInputFieldWidth - 20}
          />
          <InputText
            placeholder="Please enter your City"
            name="city"
            autoComplete={'postal-address-locality'}
            textContentType={'addressCity'}
            value={userProfile.city}
            onChangeText={onChangeText}
            label={(business?.profile?.delivery ? '*' : '') + 'City'}
          />
          <InputText
            placeholder="Please enter your State"
            name="state"
            autoComplete={'postal-address-region'}
            textContentType={'addressState'}
            value={userProfile.state}
            onChangeText={onChangeText}
            label={(business?.profile?.delivery ? '*' : '') + 'State'}
          />
          <InputText
            placeholder="Please enter your Pin Code"
            name="zipCode"
            autoComplete={'ppostal-code'}
            textContentType={'postalCode'}
            value={userProfile.zipCode}
            onChangeText={onChangeText}
            country = {userProfile?.country}
            label={(business?.profile?.delivery ? '*' : '') + 'Pin Code'}
          />
          <MyCountryPicker
            selectedValue={userProfile?.country}
            returnedAttribute="country"
            business={business}
            onChangeText={onChangeText}
            label="*Country"
          />
          <ActionButton
            style={styles.bottomMessageHighlight}
            title="Sign Up"
            onPress={signUp}
          />
        </Fragment>
      )}
      {userProfile.stage === Number(1) && (
        <Fragment>
          <InputText
            placeholder="Please enter Confirmation Code"
            value={userProfile.authCode}
            name="authCode"
            onChangeText={onChangeText}
            autoComplete="sms-otp"
            textContentType="oneTimeCode"
            label="*Confirmation Code"
          />
          <ActionButton title="Confirm Sign Up" onPress={confirmSignUp} />
        </Fragment>
      )}
    </View>
  );
};
